<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div v-if="!isLoading">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(submitAndClose)" autocomplete="off">
            <div class="row mb-2 m-0 p-0">
              <div class="col-md-3 mb-1">
                <base-button @click.prevent="tabIndex = 0" :class="tabIndex === 0 ? 'active' : ''" block outline
                             type="primary"
                ><i class="fa-solid fa-file-invoice"></i>Serviços
                </base-button>
              </div>
              <div class="col-md-3 mb-1">
                <base-button @click.prevent="tabIndex = 1" :class="tabIndex === 1 ? 'active' : ''" block outline
                             type="primary"
                ><i class="fa-solid fa-user"></i>Tomador
                </base-button>
              </div>
              <div class="col-md-3">
                <base-button @click.prevent="tabIndex = 2" :class="tabIndex === 2 ? 'active' : ''" block outline
                             type="primary"
                ><i class="fa-solid fa-id-card"></i>Emissor
                </base-button>
              </div>
              <div class="col-md-3">
                <base-button @click.prevent="tabIndex = 3" :class="tabIndex === 3 ? 'active' : ''" block outline
                             type="primary"
                ><i class="fa-solid fa-message"></i> Observações
                </base-button>
              </div>
            </div>

            <!-- Aba Serviço -->
            <div class="container" v-show="tabIndex === 0">
              <!-- Município de Incidência do ISS -->
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Município de Incidência do ISS</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info text-uppercase">
                                {{ form_data.construction.default_address.state }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong> {{ form_data.construction.default_address.city }} </strong>
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                <strong>
                                  {{ form_data.construction.default_address.address }},
                                  {{ form_data.construction.default_address.number }}
                                  <span v-if="form_data.construction.default_address.complement">
                                    ,{{ form_data.construction.default_address.complement }}
                                  </span>
                                  |
                                  {{ form_data.construction.default_address.district }}
                                  ({{ form_data.construction.default_address.postal_code }})
                                </strong>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="form_data.company_plant_issuer.nfse_send_regime_metadata && specialTaxRules" class="col-md-12 mb-3">
                      <label class="col-form-label form-control-label">
                        <span class="text-danger">&nbsp;* </span>
                        Regime Especial de Tributação
                      </label>
                      <base-input>
                        <puzl-select
                          v-model="form_data.tax_regime_metadata"
                          :items="specialTaxRules"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          :loading="loadingSpecialTaxRules"
                          :disabled="loadingSpecialTaxRules"/>
                      </base-input>
                    </div>
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <!-- Quando NÃO encontrar impostos -->
                          <div v-if="!form_data.current_taxes_found">
                            <div class="checklist-item checklist-item-danger">
                              <div class="col-md-12">
                                <div class="checklist-info">
                                  Não foi encontrado "ISSQN" compatível com "Município da Obra" e "Regime do Emissor!"
                                </div>
                              </div>
                            </div>
                            <div class="checklist-item checklist-item-danger">
                              <label class="col-md-5 pb-1 pb-0 col-form-label form-control-label"> Informar impostos
                                manualmente </label>
                              <div class="col-md-7 pt-1">
                                <base-switch
                                  v-model="form_data.show_taxes"
                                  type="success"
                                  offText="Não"
                                  onText="Sim"
                                ></base-switch>
                              </div>
                            </div>
                          </div>
                          <!-- Quando encontrar impostos -->
                          <div v-if="form_data.current_taxes_found">
                            <div class="checklist-item checklist-item-danger">
                              <div class="col-md-6">
                                <div class="checklist-info">Dedução</div>
                              </div>
                              <div class="col-md-6 text-right">
                                {{ $helper.strToNum(form_data.current_taxes.issqn.deduct) | number }}%
                              </div>
                            </div>
                            <div class="checklist-item checklist-item-danger">
                              <div class="col-md-6">
                                <div class="checklist-info">Base de cálculo</div>
                              </div>
                              <div class="col-md-6 text-right">{{ form_data.current_taxes.issqn.base | number }}%</div>
                            </div>
                            <div class="checklist-item checklist-item-danger">
                              <div class="col-md-6">
                                <div class="checklist-info">Alíquota total</div>
                              </div>
                              <div class="col-md-6 text-right">
                                {{ formatPercentage(form_data.current_taxes.issqn.total_rate) }}%
                              </div>
                            </div>
                            <div class="checklist-item checklist-item-danger">
                              <div class="col-md-6">
                                <div class="checklist-info">Alíquota retida</div>
                              </div>
                              <div class="col-md-6 text-right">
                                {{ formatPercentage(form_data.current_taxes.issqn.retained_rate) }}%
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Valores -->
              <div class="card-header border-header-success-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Valores</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <!-- Valor Total -->
                          <div class="checklist-item checklist-item-success">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong>Valor total</strong>
                              </div>
                            </div>
                            <div class="col-md-6 text-right">
                              <strong>{{ form_data.invoice.total | currency }}</strong>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- MCC Real e MCC Teórico -->
                <div class="container">
                  <ul class="list-group list-group-flush" data-toggle="checklist">
                    <li class="list-group-item px-0">
                      <!-- MCC Real -->
                      <div class="checklist-item checklist-item-danger">
                        <div class="col-md-6">
                          <div class="checklist-info">MCC (real)</div>
                        </div>
                        <div class="col-md-6 text-right">
                          {{ form_data.invoice.mcc_value | currency }} ({{
                            $helper.percentageFrom(form_data.invoice.total, form_data.invoice.mcc_value)
                          }}%)
                        </div>
                      </div>
                      <!-- MCC Teórico -->
                      <div class="checklist-item checklist-item-danger">
                        <div class="col-md-6">
                          <div class="checklist-info">MCC (teórico)</div>
                        </div>
                        <div class="col-md-6 text-right">
                          {{ form_data.invoice.mcc_theorical_value | currency }} ({{
                            $helper.percentageFrom(form_data.invoice.total, form_data.invoice.mcc_theorical_value)
                          }}%)
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="form_data.show_taxes">
                <!-- Regras de Dedução do issqn -->
                <div v-show="false">
                  <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                    <h5 class="h4 mb-0" slot="title">Regras de Dedução</h5>
                  </div>

                  <!-- Campo Deduzir custo de MCC do traço -->
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Deduzir custo de MCC do
                      traço </label>
                    <div class="col-md-8 pt-1">
                      <base-switch
                        v-model="form_data.deduction_rules_nfse.deduct_mcc_cost"
                        type="success"
                        offText="Não"
                        onText="Sim"
                      ></base-switch>
                    </div>
                  </div>

                  <!-- Campo Considerar reuso -->
                  <div class="form-group row m-0 p-0 mb-1" v-if="form_data.deduction_rules_nfse.deduct_mcc_cost">
                    <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label">
                      Considerar reuso
                      <span v-if="form_data.deduction_rules_nfse.consider_reuse" class="badge mr-1 ml-1"
                            style="background-color: #d3d3d3"
                      >(MCC Real)</span
                      >
                      <span v-if="!form_data.deduction_rules_nfse.consider_reuse" class="badge mr-1 ml-1"
                            style="background-color: #d3d3d3"
                      >(MCC Teórico)</span
                      >
                    </label>
                    <div class="col-md-8 pt-1">
                      <base-switch
                        v-model="form_data.deduction_rules_nfse.consider_reuse"
                        type="success"
                        offText="Não"
                        onText="Sim"
                      >
                      </base-switch>
                    </div>
                  </div>

                  <!-- Campo Condição -->
                  <div class="form-group row m-0 p-0 mb-1" v-if="form_data.deduction_rules_nfse.deduct_mcc_cost">
                    <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Condição </label>
                    <div class="col-md-8 pt-1">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          filterable
                          placeholder="Selecione"
                          v-model="form_data.deduction_rules_nfse.condition.id"
                        >
                          <el-option
                            v-for="condition in form_data.deduction_rules_nfse.condition.options"
                            :key="condition.id"
                            :label="condition.text"
                            :value="condition.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>

                <!-- Grupo de Campos - ISS -->
                <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                  <h5 class="h4 mb-0" slot="title">ISS</h5>
                </div>

                <!-- Campo Dedução -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Dedução </label>
                  <div class="col-md-4 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="current_invoice.deductions_value.toFixed(2)"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-4 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        :value="$helper.percentageFrom(current_invoice.total, current_invoice.deductions_value)"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <!-- Campo Base de cálculo -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Base de cálculo </label>
                  <div class="col-md-4 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="(current_invoice.total-current_invoice.deductions_value).toFixed(2)"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-4 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        :value="(100-$helper.percentageFrom(current_invoice.total, current_invoice.deductions_value)).toFixed(2)"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <!-- Campo ISS Retido -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> ISS retido </label>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.iss_nfse.retained_rate_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                  <div class="col pl-0">
                    <small v-show="current_invoice.iss_retain !== +form_data.iss_nfse.retained_rate_flag"
                           style="font-size: 0.7rem;margin-top: -5px" class="font-weight-bold text-orange"><i
                      class="fa-solid fa-triangle-exclamation"></i>
                      Retenção de ISS deve ser igual ao definido na fatura
                    </small>
                  </div>
                </div>

                <!-- Campo Alíquota ISS retida -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Alíquota retida </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.iss_nfse.retained_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col" style="margin-top: 5px">
                    <small
                      v-show="form_data.iss_nfse.retained_rate_flag && ($helper.strToNum(form_data.iss_nfse.retained_rate) < 1)"
                      style="font-size: 0.7rem"
                      class="font-weight-bold text-orange"
                    >
                      <i class="fa-solid fa-triangle-exclamation"></i>
                      A Alíquota deve ser superior a 0
                    </small>
                  </div>
                </div>

                <!-- Campo Valor ISS -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Valor ISS </label>
                  <div class="col-md-8 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.iss_nfse.iss_value"
                        v-money="money"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <!-- Grupo de Campos - Federais -->
                <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                  <h5 class="h4 mb-0" slot="title">Retenção de tributos federais</h5>
                </div>

                <!-- Campo cofins -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> COFINS </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.cofins_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model="form_data.federal_taxes_nfse.cofins_value"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.federal_taxes_nfse.cofins_retained_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                </div>

                <!-- Campo csll -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CSLL </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.csll_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model="form_data.federal_taxes_nfse.csll_value"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.federal_taxes_nfse.csll_retained_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                </div>

                <!-- Campo inss -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> INSS </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.inss_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model="form_data.federal_taxes_nfse.inss_value"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.federal_taxes_nfse.inss_retained_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                </div>

                <!-- Campo ir -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> IR </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.ir_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model="form_data.federal_taxes_nfse.ir_value"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.federal_taxes_nfse.ir_retained_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                </div>

                <!-- Campo pis -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> PIS </label>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.pis_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="form_data.federal_taxes_nfse.pis_value"
                        v-money="money"
                        type="text"
                        class="form-control form-control-sm"
                        :disabled="true"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-2 pt-1">
                    <base-switch
                      v-model="form_data.federal_taxes_nfse.pis_retained_flag"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      :disabled="true"
                    ></base-switch>
                  </div>
                </div>
              </div>
            </div>

            <!-- Aba Tomador (Cliente) -->
            <div class="container" v-show="tabIndex === 1">
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Tomador / Cliente</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="col-md-10 container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info text-uppercase">
                                {{ form_data.entity.entity_name }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong> {{ form_data.entity.document }}</strong>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-2 mt-2 mr-0 text-right">
                      <base-button
                        class="text-uppercase"
                        size="sm"
                        type="warning"
                        @click.prevent="handleEditEntity(form_data.entity.uuid)"
                      >
                        <i class="fas fa-edit"></i> Editar
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Campo E-mail -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> E-mail Principal </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.email" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Outros E-mails -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Outros E-mails </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      spellcheck="false"
                      :value="form_data.entity.entityContacts.map(email => email.email).join(', ')"
                      type="text"
                      rows="4"
                      class="form-control form-control-sm"
                      :disabled="true"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>

              <!-- Campo CNO -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CNO </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.contract_proposal_cno" type="text" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo CÓD. DE OBRA DA PREFEITURA -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CÓD. DE OBRA DA PREFEITURA </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.contract_proposal_city_hall_construction_code" type="text"
                           class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo ART/Nº DE CONTROLE DO ENCAPSULAMENTO -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> ART/Nº DE CONTROLE DO
                  ENCAPSULAMENTO </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.contract_proposal_art_code"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Regime Tributário -->
              <div class="form-group row m-0 p-0 mb-1"
                   v-if="entity_document_is_cnpj && form_data.entity.legal_entity && form_data.entity.legal_entity[0]">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Regime Tributário </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.entity.legal_entity[0].tax_regime"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Insc. Municipal-->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Insc. municipal </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="formDataEntityMunicipalRegistration"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Insc. Estadual (Tomador)-->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Insc. estadual </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="formDataEntityContributingNumber"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Insc. Estadual (Contrato)-->
              <div v-if="form_data.contract_proposal_state_registration" class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Insc. estadual (Contrato) </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.contract_proposal_state_registration"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Grupo de Campos - Endereço -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Endereço</h5>
              </div>

              <!-- Campo UF -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> UF </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.state" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Cidade -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Cidade </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.city" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Logradouro -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Logradouro </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.address" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Numero -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Número </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.number" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Complemento -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Complemento </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.complement" type="text"
                           class="form-control form-control-sm" :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Bairro -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Bairro </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.entity.address.district" type="text" class="form-control form-control-sm"
                           :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Cep -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Cep </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.entity.address.postal_code"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <!-- Aba Emissor -->
            <div class="container" v-if="form_data.company_plant_issuer" v-show="tabIndex === 2">
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Emissor</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info text-uppercase">
                                {{ form_data.company_plant_issuer.business_name }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                {{ form_data.company_plant_issuer.ein }} - {{
                                  form_data.company_plant_issuer.tax_regime
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                {{ form_data.company_plant_issuer.state }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                <strong>
                                  {{ form_data.company_plant_issuer.city }}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div v-if="form_data.company_plant_issuer.nfse_broadcast_environment === 0"
                               class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                <strong> Ambiente de Homologação </strong>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Grupo de Campos - Servico -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Serviço</h5>
              </div>

              <!-- Campo Data de Competência -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Data de Competência </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker
                      v-model="form_data.competency_date"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Código do Serviço -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Código do Serviço </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.company_plant_issuer.municipal_service_code"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Descrição do Serviço -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Descrição do Serviço </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      spellcheck="false"
                      v-model="form_data.company_plant_issuer.municipal_service_description"
                      type="text"
                      rows="4"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>

              <!-- Campo Item da Lista -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Item da lista de serviços </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="form_data.company_plant_issuer.service_list_item"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo CNAE -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CNAE </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="form_data.company_plant_issuer.cnae" type="text"
                           class="form-control form-control-sm" :disabled="true"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Natureza da Operação -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Natureza da operação </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" class="form-control form-control-sm" :disabled="true"/>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="" v-show="tabIndex === 3">
              <div class="row p-3">
                <div class="col-md-12">
                  <mention
                    style="font-size: 2rem !important;"
                    v-if="modal.create"
                    @input="setPreview()"
                    :default_comment="default_mention"
                    :items="$_mentions"
                    v-model.lazy="observation"
                  />
                </div>

                <div class="col-md-12 pt-3 text-center">
                  <label>
                    <h4 class="text-uppercase font-weight-bold" style="font-size: 0.75rem">
                      PRÉ-VISUALIZAÇÃO
                      <img
                        v-if="loading_preview"
                        width="22px"
                        src="/img/loading.gif"
                      />
                    </h4>
                  </label>
                  <base-input>
                    <textarea rows="7" readonly :value="preview_observation" class="form-control"/>
                  </base-input>
                  <h5 class="text-right font-weight-400" style="text-transform: none">
                    Caracteres: {{ preview_observation ? preview_observation.length : 0 }} de 1000.
                  </h5>
                </div>
              </div>
            </div>
            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"> Cancelar</base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="!form_data.show_taxes || loading_preview || (current_invoice.iss_retain !== +form_data.iss_nfse.retained_rate_flag) ||
                           (form_data.iss_nfse.retained_rate_flag && ($helper.strToNum(form_data.iss_nfse.retained_rate) < 1))"
                :loading="isLoadingNfse">
                Emitir
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <modal-edit-entity @updatedEntity="loadEntityDetailsAfterUpdate" ref="ModalEditEntity"></modal-edit-entity>
  </div>
</template>

<script>
import {Mentionable} from "vue-mention";
import Mention from "@/components/Inputs/Mention";
import {strToNum, onlyNumbers, isCnpjDocument} from "../../../../../helpers";
import {debounce} from "@/plugins";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import {v4 as uuidv4} from 'uuid';
import {formatErrorValidation} from "@/plugins";

export default {
  name: "ModalNfseCreate",
  components: {
    Mentionable,
    Mention,
    PuzlSelect,
    InputDateTimePicker,
    ModalEditEntity,
  },
  computed: {
    ...mapGetters({
      $_mentions: "nfse/getListMentions",
      $_general_settings: "generalSetting/show",
    }),
    /**
     * Retorna o número de inscrição estadual.
     * @returns {string}
     */
    formDataEntityContributingNumber() {
      if (this.form_data.entity.legal_entity && this.form_data.entity.legal_entity[0]) {
        return this.form_data.entity.legal_entity[0].contributing_number;
      }
      if (this.form_data.entity.individual_entity && this.form_data.entity.individual_entity[0]) {
        return this.form_data.entity.individual_entity[0].contributing_number;
      }
      return '';
    },
    /**
     * Retorna o número de inscrição municipal.
     * @returns {string}
     */
    formDataEntityMunicipalRegistration() {
      if (this.form_data.entity.legal_entity && this.form_data.entity.legal_entity[0]) {
        return this.form_data.entity.legal_entity[0].municipal_registration;
      }
      if (this.form_data.entity.individual_entity && this.form_data.entity.individual_entity[0]) {
        return this.form_data.entity.individual_entity[0].municipal_registration;
      }
      return '';
    },
  },
  data() {
    return {
      default_mention: null,
      mentionRefToService: undefined,
      observation: "",
      observationData: "",
      text: "",
      isLoadingNfse: false,
      preview_observation: "",
      loading_preview: false,
      loadingSpecialTaxRules: false,
      modal: {
        title: "Nota fiscal (NFS-e)",
        create: false,
      },
      isLoading: true,
      tabIndex: 0,
      // Dados para alteração do invoice após emissão
      current_invoice: null,
      current_invoice_nfse: {
        status: "Pendente",
        nfse_external_id: 0,
        nfse_number: 0,
        status_reason: "",
        link_pdf: "",
        link_xml: "",
        authorization_date: null,
        cancellation_date: null,
        rps_number: null,
        rps_series: null,
        contract_proposal_cno: null,
        contract_proposal_city_hall_construction_code: null,
        contract_proposal_art_code: null,
        contract_proposal_state_registration: null,
      },
      // Dados do Formulário
      form_data: {
        competency_date: null,
        operation_source: null,
        tax_regime_metadata: null,
        contract_proposal_cno: null,
        contract_proposal_city_hall_construction_code: null,
        contract_proposal_art_code: null,
        contract_proposal_state_registration: null,
        company_plant_issuer: null,
        construction: null,
        current_taxes: {},
        current_taxes_types: ["issqn", "cofins", "csll", "inss", "ir", "pis"],
        current_taxes_default: {
          base: "0,00",
          condition_id: 1,
          condition: "",
          consider_reuse: false,
          deduct: "0,00",
          deduct_mcc_cost: false,
          retained_rate: "0,00",
          total_rate: "0,00",
          full_coverage: false,
          allow_issqn_retention_of_legal_person: 0,
          allow_issqn_retention_of_natural_person: 0,
          allow_issuance_without_customer_municipal_registration: 0,
        },
        current_taxes_found: false,
        show_taxes: false,
        entity: null,
        entity_document_is_cnpj: false,
        invoice: {
          total: 0,
          mcc_value: 0,
          mcc_theorical_value: 0,
        },
        retained_rates_between_entity_and_issuer: {},
        deduction_rules_nfse: {
          deduct_mcc_cost: false,
          consider_reuse: false,
          condition: {
            id: 1,
            options: [
              {
                id: 1,
                text: "Adotar sempre o MCC",
              },
              {
                id: 2,
                text: "Adotar sempre maior dedução",
              },
              {
                id: 3,
                text: "Adotar sempre menor dedução",
              },
            ],
          },
        },
        iss_nfse: {
          deduct: "0,00",
          deduct_perc: "0,00",
          base: "0,00",
          base_perc: "0,00",
          retained_rate: "0,00",
          retained_rate_flag: false,
          iss_value: "0,00",
        },
        federal_taxes_nfse: {
          cofins_rate: 0,
          cofins_value: 0,
          cofins_retained_flag: false,
          csll_rate: 0,
          csll_value: 0,
          csll_retained_flag: false,
          inss_rate: 0,
          inss_value: 0,
          inss_retained_flag: false,
          ir_rate: 0,
          ir_value: 0,
          ir_retained_flag: false,
          pis_rate: 0,
          pis_value: 0,
          pis_retained_flag: false,
        },
      },
      // Dados da NFS-e para emissão
      nfse_to_issue: {
        broadcast_environment: "Homologacao",
        sent_by_email: false,
        customer: {
          legal_entity: "",
          name: "",
          email: "",
          ein: "",
          phone: "",
          address: {
            country: "",
            state: "",
            city: "",
            address: "",
            address_number: "",
            complement: "",
            district: "",
            postal_code: "",
          },
        },
        service: {
          description: "",
          iss_withheld_at_source: false,
          iss_rate: 0,
          cofins_value: 0,
          csll_value: 0,
          inss_value: 0,
          ir_value: 0,
          pis_value: 0,
          ibge_code: null,
          service_delivery_state: null,
        },
        total_value: 0,
        deductions_value: 0,
        discounts_value: 0,
        comments: "",
        construction_address: {
          city: "",
          state: "",
          address: "",
          address_numer: "",
          district: "",
          postal_code: ""
        },
        tax_regime_metadata: "",
        replace_comma_with_dot: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      specialTaxRules: [],
    };
  },
  methods: {
    setPreview: debounce(function () {
      this.loading_preview = true;
      this.$store
        .dispatch("nfse/getObservationByMention", {
          mention: this.observation,
          invoice_id: this.current_invoice.id,
        })
        .then((response) => {
          this.preview_observation = response.data;
          this.loading_preview = false;
        });
    }, 500),
    closeModal() {
      this.modal.create = false;
      this.$emit("close");
    },
    cleanModal() {
      this.form_data.competency_date = null,
      this.form_data.current_taxes_found = false;
      this.form_data.show_taxes = false;
      this.form_data.invoice = {
        total: 0,
        mcc_value: 0,
        mcc_theorical_value: 0,
      };
      this.form_data.retained_rates_between_entity_and_issuer = {};
      this.form_data.deduction_rules_nfse.deduct_mcc_cost = false;
      this.form_data.deduction_rules_nfse.consider_reuse = false;
      this.form_data.iss_nfse = {
        deduct: "0,00",
        deduct_perc: "0,00",
        base: "0,00",
        base_perc: "0,00",
        retained_rate: "0,00",
        retained_rate_flag: false,
        iss_value: "0,00",
      };
      this.form_data.federal_taxes_nfse = {
        cofins_rate: 0,
        cofins_value: 0,
        cofins_retained_flag: false,
        csll_rate: 0,
        csll_value: 0,
        csll_retained_flag: false,
        inss_rate: 0,
        inss_value: 0,
        inss_retained_flag: false,
        ir_rate: 0,
        ir_value: 0,
        ir_retained_flag: false,
        pis_rate: 0,
        pis_value: 0,
        pis_retained_flag: false,
      };
      this.nfse_to_issue = this.initNfseToIssue();
    },
    async handleCreateModal(item) {
      let loader = this.$loading.show();
      try {
        this.cleanModal();
        await this.loadFormData(item);

        // Verificar erros que impedem a emissão da NFS-e
        const errors = this.nfseDataCheckErrors();
        if (errors) {
          this.$swal.fire({
            icon: 'error',
            title: 'Operação bloqueada!',
            text: errors,
          });
          return;
        }
        this.tabIndex = 0;
        this.modal.create = true;
        this.isLoading = false;
        this.isLoadingNfse = false;
      } finally {
        loader.hide();
      }
    },
    nfseDataCheckErrors() {
      let errors = "";

      // Verificar Chave para Emissão da NFS-e
      errors += !this.form_data.company_plant_issuer.nfse_ms_company_id ? "Necessário configurar a chave para emissão da NFS-e. " : "";

      // Verificar necessidade de Incrição Municipal
      const needsMunicipalRegistration = this.entity_document_is_cnpj
        && !this.form_data.company_plant_issuer.nfse_without_municipal_registration
        && !this.form_data.entity.legal_entity[0].municipal_registration;
      if (needsMunicipalRegistration) {
        errors += "Incrição Municipal do tomador é obrigatório. ";
      }

      // Não permitir emissão sendo tomador = emissor
      if (onlyNumbers(this.form_data.entity.document) === onlyNumbers(this.form_data.company_plant_issuer.ein)) {
        errors += "CNPJ do Tomador não pode ser igual a do Emissor. ";
      }

      // Bloquear emissão quando serviço prestado dentro da cidade
      const isConstructionCityEqualIssuerCity = this.isConstructionCityEqualIssuerCity();
      if (isConstructionCityEqualIssuerCity && this.form_data.company_plant_issuer.nfse_block_service_within_the_city) {
        errors += "Emissão bloqueada quando município de prestador e obra são iguais. ";
      }

      // Bloquear emissão quando serviço prestado fora da cidade
      if (!isConstructionCityEqualIssuerCity && this.form_data.company_plant_issuer.nfse_block_service_outside_the_city) {
        errors += "Emissão bloqueada quando município de prestador e obra são diferentes. ";
      }

      // Não permitir emissão com valor da fatura R$ 0,00
      errors += !this.form_data.invoice.total ? "Não é permitido emissão com valor R$ 0,00 " : "";

      return errors;
    },
    async loadFormData(item) {
      // Inicio de carregamento
      this.$Progress.start();

      // Dados do invoice para atualizar posteriormente
      this.current_invoice = item;
      this.form_data.competency_date = this.$helper.parseDate(new Date(), 'YYYY-MM-DD HH:mm:ss');

      // Dados do Emissor e Cliente
      await Promise.all([
        this.getCompanyPlantIssuer(item.company_plant_issuer.id),
        this.getEntity(item.entity.uuid)
      ]);
      this.default_mention = this.form_data.company_plant_issuer.observation_nfse;
      this.entity_document_is_cnpj = isCnpjDocument(this.form_data.entity.document);

      // Dados do invoice
      this.form_data.invoice.total = item.total;
      this.form_data.invoice.mcc_value = item.mcc_value;
      this.form_data.invoice.mcc_theorical_value = item.mcc_theorical_value;

      // Dados da Obra
      this.form_data.construction = item.contract_proposal.construction;
      this.form_data.contract_proposal_cno = item.contract_proposal.cno;
      this.form_data.contract_proposal_city_hall_construction_code = item.contract_proposal.city_hall_construction_code;
      this.form_data.contract_proposal_art_code = item.contract_proposal.art_code;
      this.form_data.contract_proposal_state_registration = item.contract_proposal.state_registration;

      await Promise.all([
        this.getAllCurrentTaxes(this.form_data.construction.default_address.city, this.form_data.company_plant_issuer.tax_regime),
        this.getIbgeCodeByStateAndCity(this.form_data.construction.default_address.state, this.form_data.construction.default_address.city),
        this.getSpecialTaxRules(),
        this.$store.dispatch("nfse/getMentions"),
      ]);

      // Retenção de ISS
      this.form_data.iss_nfse.retained_rate_flag = !!this.current_invoice.iss_retain;

      // Natureza de Operação
      if (this.isConstructionCityEqualIssuerCity()) {
        this.form_data.operation_source = this.form_data.company_plant_issuer.nfse_operation_source_construction_city_equal_issuer;
        this.taxRegimesPuzlDefault = this.form_data.company_plant_issuer.nfse_operation_source_const_city_equal_issuer_puzl_default
      } else {
        this.form_data.operation_source = this.form_data.company_plant_issuer.nfse_operation_source_construction_city_diff_issuer;
        this.taxRegimesPuzlDefault = this.form_data.company_plant_issuer.nfse_operation_source_const_city_diff_issuer_puzl_default
      }
      // Natureza de Operação - Regra Especial
      const hasDeduction = strToNum(this.current_invoice.deductions_value) > 0;
      if (this.form_data.company_plant_issuer.is_nfse_oper_const_city_issuer_special) {
        // Município IGUAL
        if (this.isConstructionCityEqualIssuerCity()) {
          // PJ - Tomador Pessoa Jurídica
          if (this.entity_document_is_cnpj) {
            // COM RETENÇÃO DO ISS
            if (this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_le_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_le_iss_ret_no_ded;
            }

            // SEM RETENÇÃO DO ISS
            if (!this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_le_no_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_le_no_iss_ret_no_ded;
            }
          }

          // PF - Tomador Pessoa Física
          if (!this.entity_document_is_cnpj) {
            // COM RETENÇÃO DO ISS
            if (this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_ip_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_ip_iss_ret_no_ded;
            }

            // SEM RETENÇÃO DO ISS
            if (!this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_ip_no_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_eq_issuer_ip_no_iss_ret_no_ded;
            }
          }
        }

        // Município DIFERENTE
        if (!this.isConstructionCityEqualIssuerCity()) {
          // PJ - Tomador Pessoa Jurídica
          if (this.entity_document_is_cnpj) {
            // COM RETENÇÃO DO ISS
            if (this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_le_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_le_iss_ret_no_ded;
            }

            // SEM RETENÇÃO DO ISS
            if (!this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_le_no_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_le_no_iss_ret_no_ded;
            }
          }

          // PF - Tomador Pessoa Física
          if (!this.entity_document_is_cnpj) {
            // COM RETENÇÃO DO ISS
            if (this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_ip_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_ip_iss_ret_no_ded;
            }

            // SEM RETENÇÃO DO ISS
            if (!this.form_data.iss_nfse.retained_rate_flag) {
              this.form_data.operation_source = hasDeduction
                ? this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_ip_no_iss_ret_with_ded
                : this.form_data.company_plant_issuer.nfse_oper_const_city_diff_issuer_ip_no_iss_ret_no_ded;
            }
          }
        }
      }

      // Regras de Dedução da NFS-e
      this.form_data.deduction_rules_nfse.deduct_mcc_cost = this.form_data.current_taxes.issqn.deduct_mcc_cost;
      this.form_data.deduction_rules_nfse.consider_reuse = this.form_data.current_taxes.issqn.consider_reuse;
      this.form_data.deduction_rules_nfse.condition.id = this.form_data.current_taxes.issqn.condition_id;

      // ISS
      this.form_data.iss_nfse.deduct_perc = this.$helper.percentageFrom(this.current_invoice.total, this.current_invoice.deductions_value);
      this.form_data.iss_nfse.retained_rate = this.current_invoice.iss_rate.toFixed(2);

      // Impostos Federais. Retido?
      this.form_data.federal_taxes_nfse.cofins_retained_flag = this.current_invoice.cofins_rate > 0;
      this.form_data.federal_taxes_nfse.csll_retained_flag = this.current_invoice.csll_rate > 0;
      this.form_data.federal_taxes_nfse.inss_retained_flag = this.current_invoice.inss_rate > 0;
      this.form_data.federal_taxes_nfse.ir_retained_flag = this.current_invoice.ir_rate > 0;
      this.form_data.federal_taxes_nfse.pis_retained_flag = this.current_invoice.pis_rate > 0;

      // Impostos Federais. Alíquota!
      this.form_data.federal_taxes_nfse.cofins_rate = this.current_invoice.cofins_rate.toFixed(2);
      this.form_data.federal_taxes_nfse.csll_rate = this.current_invoice.csll_rate.toFixed(2);
      this.form_data.federal_taxes_nfse.inss_rate = this.current_invoice.inss_rate.toFixed(2);
      this.form_data.federal_taxes_nfse.ir_rate = this.current_invoice.ir_rate.toFixed(2);
      this.form_data.federal_taxes_nfse.pis_rate = this.current_invoice.pis_rate.toFixed(2);

      // Impostos Federais. Cálculo!
      this.form_data.federal_taxes_nfse.cofins_value = this.current_invoice.cofins_value.toFixed(2);
      this.form_data.federal_taxes_nfse.csll_value = this.current_invoice.csll_value.toFixed(2);
      this.form_data.federal_taxes_nfse.inss_value = this.current_invoice.inss_value.toFixed(2);
      this.form_data.federal_taxes_nfse.ir_value = this.current_invoice.ir_value.toFixed(2);
      this.form_data.federal_taxes_nfse.pis_value = this.current_invoice.pis_value.toFixed(2);

      // Calcular Valores
      this.calcValuesFormData();

      // Exibir Impostos se Localizado ISSQN
      this.form_data.show_taxes = this.form_data.current_taxes_found;

      // Carregar regime especial de tributação em metadados conforme configuração em emissor
      this.form_data.tax_regime_metadata = this.getTaxRegimeMetadata();

      // Fim do carregamento
      this.$Progress.finish();
    },
    async getEntity(uuid) {
      await this.$store
        .dispatch("entity/show", uuid)
        .then((response) => {
          this.form_data.entity = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    async getCompanyPlantIssuer(id) {
      await this.$store
        .dispatch("companyPlantIssuer/show", id)
        .then((response) => {
          this.form_data.company_plant_issuer = response.data;
          this.form_data.company_plant_issuer.municipal_service_description = null
          this.getDescriptionByMention()
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    async getDescriptionByMention() {
      this.loading_preview = true
      await this.$store
        .dispatch('generalSetting/show')
        .then((response) => {
          this.mentionRefToService = response.data.hide_nfe_report
            ? "@CONCRETO | @SERVIÇO | @ADICIONAL | @AVULSO"
            : "@CONCRETO_COM_NFE | @SERVIÇO | @ADICIONAL | @AVULSO"
        });
      this.$store.dispatch("nfse/getObservationByMention", {
        mention: this.mentionRefToService,
        invoice_id: this.current_invoice.id,
        is_nfse_service_description: true
      }).then(response => {
        this.form_data.company_plant_issuer.municipal_service_description = response.data
        this.loading_preview = false
        if (response.data == ' | ') {
          this.form_data.company_plant_issuer.municipal_service_description = null
        }
        if (response.data.substr(-2).trim() === '|') {
          this.form_data.company_plant_issuer.municipal_service_description = response.data.substring(0, response.data.length - 2)
        }
        if (response.data.substr(0, 2).trim() === '|') {
          this.form_data.company_plant_issuer.municipal_service_description = response.data.substring(2, response.data.length)
        }
      })
    },
    async getIbgeCodeByStateAndCity(state, name) {
      let params = {state: state, name: name}
      await this.$store
        .dispatch("city/showByStateAndCity", params)
        .then((response) => {
          this.form_data.construction.default_address.city_ibgecode = response.data.iso;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    async getAllCurrentTaxes(constructionCity, issuerRegime) {
      let params = {
        constructionCity: constructionCity,
        issuerRegime: issuerRegime,
      };
      await this.$store
        .dispatch("deductionRules/getAllCurrentTaxes", params)
        .then((response) => {
          this.form_data.current_taxes_found = response.data.length > 0;
          this.form_data.current_taxes_types.map(function (obj, index) {
            this.form_data.current_taxes[obj] =
              response.data.filter((item) => item.name.toLowerCase() === obj)[0] ?? this.form_data.current_taxes_default;
            const conditionFound = this.form_data.deduction_rules_nfse.condition.options.find(
              (element) => element.text === this.form_data.current_taxes[obj].condition
            );
            this.form_data.current_taxes[obj].condition_id = conditionFound ? conditionFound.id : 1;
          }, this);
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    calcValuesFormData() {
      this.form_data.iss_nfse.deduct_perc = this.$helper.percentageFrom(this.current_invoice.total, this.current_invoice.deductions_value);
      this.form_data.iss_nfse.base_perc = (100 - this.form_data.iss_nfse.deduct_perc).toFixed(2);
      this.form_data.iss_nfse.deduct = this.current_invoice.deductions_value;
      this.form_data.iss_nfse.base = (this.current_invoice.total - this.current_invoice.deductions_value).toFixed(2);
      this.form_data.iss_nfse.iss_value = (strToNum(this.form_data.iss_nfse.base) * (strToNum(this.current_invoice.iss_rate) / 100)).toFixed(2);
    },
    submitAndClose() {
      // Não permitir descrição do serviço em branco
      if (String(this.form_data.company_plant_issuer.municipal_service_description).trim().length === 0) {
        this.$notify({
          type: "danger",
          message: `Descrição do serviço é um campo obrigatório.`,
        });
        return;
      }

      // Não permitir tomador sem INDICADOR INSC. ESTADUAL
      if (isCnpjDocument(this.form_data.entity.document) && (this.form_data.entity.legal_entity.length === 0)) {
        this.$notify({
          type: "danger",
          message: 'Necessário preencher Indicador de Inc. Estadual do Tomador.',
        });
        return;
      }

      this.isLoadingNfse = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });

      // Emitir nfs-e
      this.loadNfseData();
      this.current_invoice.company_plant_id = this.current_invoice.company_plant.id;
      this.current_invoice.company_plant_issuer_id = this.current_invoice.company_plant_issuer.id;
      this.current_invoice.contract_proposal_id = this.current_invoice.contract_proposal.id;
      this.current_invoice.entity_id = this.current_invoice.entity.id;
      this.current_invoice.payment_term_id = this.current_invoice.payment_term.id;
      this.current_invoice.nfse_provider = this.form_data.company_plant_issuer.nfse_provider;
      this.form_data.company_plant_issuer.nfse_provider == 0
        ? this.issueWithEnotasProvider()
        : this.issueWithMigrateProvider();
    },
    issueWithEnotasProvider() {
      let params = {id: this.current_invoice.id, data: this.current_invoice};
      this.$store
        .dispatch("nfse/updateInvoiceNfse", params)
        .then(() => {
          let params = {
            ms_company_id: this.form_data.company_plant_issuer.nfse_ms_company_id,
            data: this.nfse_to_issue
          };
          this.$store
            .dispatch("nfse/createNfse", params)
            .then((response) => {
              // Dados da NFS-e
              this.current_invoice_nfse.nfse_external_id = response.data.nfse_external_id;
              this.current_invoice_nfse.deduction_rules_nfse = this.form_data.deduction_rules_nfse;
              this.current_invoice_nfse.iss_nfse = this.form_data.iss_nfse;
              this.current_invoice_nfse.federal_taxes_nfse = this.form_data.federal_taxes_nfse;
              this.current_invoice_nfse.contract_proposal_cno = this.form_data.contract_proposal_cno;
              this.current_invoice_nfse.contract_proposal_city_hall_construction_code = this.form_data.contract_proposal_city_hall_construction_code;
              this.current_invoice_nfse.contract_proposal_art_code = this.form_data.contract_proposal_art_code;
              this.current_invoice_nfse.contract_proposal_state_registration = this.form_data.contract_proposal_state_registration;
              this.current_invoice_nfse.service_description = this.nfse_to_issue.service.description;
              const specialTaxRuleFound = this.specialTaxRules.find(item => item.code === this.form_data.operation_source);
              this.current_invoice_nfse.operation_source = this.form_data.operation_source;
              this.current_invoice_nfse.operation_source_name = specialTaxRuleFound ? specialTaxRuleFound.name : '';
              this.current_invoice_nfse.operation_source_puzl_default = this.taxRegimesPuzlDefault;

              // Tratar formato number para evitar erros de calculo
              this.formatNumbersOfCurrentInvoiceNfse(this.current_invoice_nfse);

              // Incluir dados da NFS-e no invoice
              this.current_invoice.nfse = this.current_invoice_nfse;
              this.current_invoice.nfse.observation = this.preview_observation;
              let params = {id: this.current_invoice.id, data: this.current_invoice};
              this.$store
                .dispatch("nfse/updateInvoiceNfse", params)
                .then(() => {
                  this.$notify({
                    type: "success",
                    message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
                  });
                  this.modal.create = false;
                  this.isLoadingNfse = false;
                })
                .catch((error) => {
                  this.$notify({type: "danger", message: error.data.message});
                  this.isLoadingNfse = false;
                });
            })
            .catch((error) => {
              const errors = error && error.response && error.response.status === 422
                ? formatErrorValidation(error.response.data.errors)
                : error.data.message;
              this.$notify({type: "danger", message: errors});
              this.isLoadingNfse = false;
            });
        })
        .catch((error) => {
          this.$notify({type: "danger", message: error.data.message});
          this.isLoadingNfse = false;
        });
    },
    issueWithMigrateProvider() {
      let params = {id: this.current_invoice.id, data: this.current_invoice};
      this.$store
        .dispatch("nfse/updateInvoiceNfse", params)
        .then(() => {
          let params = this.loadNfseMigrate();
          this.$store
            .dispatch("nfse/createMigrateNfse", params)
            .then((response) => {
              // Dados da NFS-e
              this.current_invoice_nfse.nfse_external_id = response.data[0];
              this.current_invoice_nfse.deduction_rules_nfse = this.form_data.deduction_rules_nfse;
              this.current_invoice_nfse.iss_nfse = this.form_data.iss_nfse;
              this.current_invoice_nfse.federal_taxes_nfse = this.form_data.federal_taxes_nfse;
              this.current_invoice_nfse.contract_proposal_cno = this.form_data.contract_proposal_cno;
              this.current_invoice_nfse.contract_proposal_city_hall_construction_code = this.form_data.contract_proposal_city_hall_construction_code;
              this.current_invoice_nfse.contract_proposal_art_code = this.form_data.contract_proposal_art_code;
              this.current_invoice_nfse.contract_proposal_state_registration = this.form_data.contract_proposal_state_registration;
              this.current_invoice_nfse.service_description = this.nfse_to_issue.service.description;
              const specialTaxRuleFound = this.specialTaxRules.find(item => item.code === this.form_data.operation_source);
              this.current_invoice_nfse.operation_source = this.form_data.operation_source;
              this.current_invoice_nfse.operation_source_name = specialTaxRuleFound ? specialTaxRuleFound.name : '';
              this.current_invoice_nfse.operation_source_puzl_default = this.taxRegimesPuzlDefault;

              // Tratar formato number para evitar erros de calculo
              this.formatNumbersOfCurrentInvoiceNfse(this.current_invoice_nfse);

              // Incluir dados da NFS-e no invoice
              this.current_invoice.nfse = this.current_invoice_nfse;
              this.current_invoice.nfse.observation = this.preview_observation;
              let params = {id: this.current_invoice.id, data: this.current_invoice};
              this.$store
                .dispatch("nfse/updateInvoiceNfse", params)
                .then(() => {
                  this.$notify({
                    type: "success",
                    message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
                  });
                  this.modal.create = false;
                  this.isLoadingNfse = false;
                })
                .catch((error) => {
                  this.$notify({type: "danger", message: error.data.message});
                  this.isLoadingNfse = false;
                });
            })
            .catch((error) => {
              const errors = error && error.response && error.response.status === 422
                ? formatErrorValidation(error.response.data.data)
                : error.response.data.message;
              this.$notify({type: "danger", message: errors});
              this.isLoadingNfse = false;
            })
        })
        .catch((error) => {
          this.$notify({type: "danger", message: error.data.message});
          this.isLoadingNfse = false;
        });
    },
    loadNfseMigrate() {
      // Tratar campo de Contribuinte de ICMS
      const ICMS_TAX_PAYER = Object.freeze({
        NO: 0,
        YES: 1,
        EXEMPT: 2
      });
      let icmsTaxPayer = ICMS_TAX_PAYER.NO;
      if (this.form_data.entity.legal_entity.length > 0) {
        icmsTaxPayer = this.form_data.entity.legal_entity[0].state_registration;
        if (![ICMS_TAX_PAYER.YES, ICMS_TAX_PAYER.EXEMPT].includes(icmsTaxPayer)) {
          icmsTaxPayer = ICMS_TAX_PAYER.NO;
        }
      }

      // Omitir a dedução do ISS no XML da prefeitura quando o município da obra for igual ao do emissor
      // Mantendo o cálculo correto mas sem incluir no XML
      let issRate = strToNum(this.nfse_to_issue.service.iss_rate);
      let issValue = strToNum(this.form_data.iss_nfse.iss_value);
      let deductionsValue = strToNum(this.nfse_to_issue.deductions_value);
      if (this.isConstructionCityEqualIssuerCity() && this.form_data.company_plant_issuer.nfse_omit_deduction_in_city_hall_xml) {
        issRate = 0;
        issValue = 0;
        deductionsValue = 0;
      }

      return {
        batch: [
          {
            environment: this.nfse_to_issue.broadcast_environment === "Homologacao" ? 0 : 1,
            api_nfse_id: this.nfse_to_issue.nfse_external_id,
            issuer_id: this.form_data.company_plant_issuer.nfse_ms_company_id,
            competency_date: this.nfse_to_issue.competency_date,
            comments: this.nfse_to_issue.comments,
            operation_source: this.nfse_to_issue.operation_source,
            special_tax_rule: this.nfse_to_issue.tax_regime_metadata ?? 0,
            nfse_customer: {
              business_name: this.nfse_to_issue.customer.name,
              alias_name: this.nfse_to_issue.customer.name,
              ein: this.nfse_to_issue.customer.ein,
              icms_taxpayer: icmsTaxPayer,
              state_registration: this.nfse_to_issue.customer.state_registration,
              municipal_registration: this.nfse_to_issue.customer.municipal_registration,
              phone: this.nfse_to_issue.customer.phone,
              email: this.nfse_to_issue.customer.email,
              nfse_customer_address: {
                zip_code: this.nfse_to_issue.customer.address.postal_code,
                street: this.nfse_to_issue.customer.address.address,
                street_number: this.nfse_to_issue.customer.address.address_number,
                complement: this.nfse_to_issue.customer.address.complement,
                district: this.nfse_to_issue.customer.address.district,
                city: this.nfse_to_issue.customer.address.city,
                state: this.nfse_to_issue.customer.address.state,
                ibge_code: String(this.form_data.entity.address.ibge_code),
              },
            },
            nfse_service: {
              description: this.nfse_to_issue.service.description,
              total: this.nfse_to_issue.total_value,
              deductions: deductionsValue,
              iss_rate: issRate,
              iss_retained: this.nfse_to_issue.service.iss_withheld_at_source,
              iss_value: issValue,
              cofins_rate: strToNum(this.form_data.federal_taxes_nfse.cofins_rate),
              cofins_retained: this.form_data.federal_taxes_nfse.cofins_retained_flag,
              cofins_value: strToNum(this.nfse_to_issue.service.cofins_value),
              csll_rate: strToNum(this.form_data.federal_taxes_nfse.csll_rate),
              csll_retained: this.form_data.federal_taxes_nfse.csll_retained_flag,
              csll_value: strToNum(this.nfse_to_issue.service.csll_value),
              inss_rate: strToNum(this.form_data.federal_taxes_nfse.inss_rate),
              inss_retained: this.form_data.federal_taxes_nfse.inss_retained_flag,
              inss_value: strToNum(this.nfse_to_issue.service.inss_value),
              ir_rate: strToNum(this.form_data.federal_taxes_nfse.ir_rate),
              ir_retained: this.form_data.federal_taxes_nfse.ir_retained_flag,
              ir_value: strToNum(this.nfse_to_issue.service.ir_value),
              pis_rate: strToNum(this.form_data.federal_taxes_nfse.pis_rate),
              pis_retained: this.form_data.federal_taxes_nfse.pis_retained_flag,
              pis_value: strToNum(this.nfse_to_issue.service.pis_value),
              municipal_service_code: this.nfse_to_issue.service.municipal_service_code,
              service_list_item: this.nfse_to_issue.service.service_list_item,
              cnae: this.nfse_to_issue.service.cnae,
            },
            nfse_service_location: {
              zip_code: this.form_data.construction.default_address.postal_code,
              street: this.form_data.construction.default_address.address,
              street_number: this.form_data.construction.default_address.number,
              complement: this.form_data.construction.default_address.complement,
              district: this.form_data.construction.default_address.district,
              city: this.form_data.construction.default_address.city,
              state: this.nfse_to_issue.service.service_delivery_state,
              ibge_code: String(this.nfse_to_issue.service.ibge_code),
              construction_code: this.nfse_to_issue.city_hall_construction_code,
              art_code: this.nfse_to_issue.art_code,
              encapsulation_number: this.nfse_to_issue.art_code,
            },
          },
        ],
      }
    },
    formatNumbersOfCurrentInvoiceNfse(currentInvoiceNfse) {
      // Formatar números de iss_nfse
      currentInvoiceNfse.iss_nfse.base = strToNum(currentInvoiceNfse.iss_nfse.base);
      currentInvoiceNfse.iss_nfse.deduct = strToNum(currentInvoiceNfse.iss_nfse.deduct);
      currentInvoiceNfse.iss_nfse.base_perc = strToNum(currentInvoiceNfse.iss_nfse.base_perc);
      currentInvoiceNfse.iss_nfse.iss_value = strToNum(currentInvoiceNfse.iss_nfse.iss_value);
      currentInvoiceNfse.iss_nfse.deduct_perc = strToNum(currentInvoiceNfse.iss_nfse.deduct_perc);
      currentInvoiceNfse.iss_nfse.retained_rate = strToNum(currentInvoiceNfse.iss_nfse.retained_rate);

      // Formatar números de federal_taxes
      currentInvoiceNfse.federal_taxes_nfse.ir_rate = strToNum(currentInvoiceNfse.federal_taxes_nfse.ir_rate);
      currentInvoiceNfse.federal_taxes_nfse.ir_value = strToNum(currentInvoiceNfse.federal_taxes_nfse.ir_value);
      currentInvoiceNfse.federal_taxes_nfse.pis_rate = strToNum(currentInvoiceNfse.federal_taxes_nfse.pis_rate);
      currentInvoiceNfse.federal_taxes_nfse.pis_value = strToNum(currentInvoiceNfse.federal_taxes_nfse.pis_value);
      currentInvoiceNfse.federal_taxes_nfse.csll_rate = strToNum(currentInvoiceNfse.federal_taxes_nfse.csll_rate);
      currentInvoiceNfse.federal_taxes_nfse.csll_value = strToNum(currentInvoiceNfse.federal_taxes_nfse.csll_value);
      currentInvoiceNfse.federal_taxes_nfse.inss_rate = strToNum(currentInvoiceNfse.federal_taxes_nfse.inss_rate);
      currentInvoiceNfse.federal_taxes_nfse.inss_value = strToNum(currentInvoiceNfse.federal_taxes_nfse.inss_value);
      currentInvoiceNfse.federal_taxes_nfse.cofins_rate = strToNum(currentInvoiceNfse.federal_taxes_nfse.cofins_rate);
      currentInvoiceNfse.federal_taxes_nfse.cofins_value = strToNum(currentInvoiceNfse.federal_taxes_nfse.cofins_value);
    },
    loadNfseData() {
      // Evitar duplicidade
      if (!this.current_invoice.nfse?.nfse_external_id) {
        this.current_invoice.nfse = {
          status: "Negada",
          nfse_external_id: uuidv4(),
          status_reason: 'ms-nfse-puzl timeout'
        }
      }
      this.nfse_to_issue = this.initNfseToIssue();
      this.nfse_to_issue.nfse_external_id = this.current_invoice.nfse.nfse_external_id;
      this.nfse_to_issue.broadcast_environment = this.form_data.company_plant_issuer.nfse_broadcast_environment == "0" ? "Homologacao" : "Producao";
      this.nfse_to_issue.competency_date = this.form_data.competency_date;
      this.nfse_to_issue.sent_by_email = false;
      this.nfse_to_issue.customer.legal_entity = isCnpjDocument(this.form_data.entity.document) ? "J" : "F";
      this.nfse_to_issue.customer.name = this.form_data.entity.entity_name;
      this.nfse_to_issue.customer.email = this.form_data.entity.email;
      this.nfse_to_issue.customer.ein = this.form_data.entity.document;
      this.nfse_to_issue.customer.phone = this.form_data.entity.phone;
      this.nfse_to_issue.customer.address.country = this.form_data.entity.address.country ?? "Brasil";
      this.nfse_to_issue.customer.address.state = this.form_data.entity.address.state;
      this.nfse_to_issue.customer.address.city = this.form_data.entity.address.city;
      this.nfse_to_issue.customer.address.address = this.form_data.entity.address.address;
      this.nfse_to_issue.customer.address.address_number = this.form_data.entity.address.number;
      this.nfse_to_issue.customer.address.complement = this.form_data.entity.address.complement;
      this.nfse_to_issue.customer.address.district = this.form_data.entity.address.district;
      this.nfse_to_issue.customer.address.postal_code = this.form_data.entity.address.postal_code;
      this.nfse_to_issue.service.description = this.form_data.company_plant_issuer.municipal_service_description;
      this.nfse_to_issue.service.iss_withheld_at_source = this.form_data.iss_nfse.retained_rate_flag;
      this.nfse_to_issue.service.iss_rate = strToNum(this.form_data.iss_nfse.retained_rate);
      this.nfse_to_issue.service.cofins_value = strToNum(this.form_data.federal_taxes_nfse.cofins_value);
      this.nfse_to_issue.service.csll_value = strToNum(this.form_data.federal_taxes_nfse.csll_value);
      this.nfse_to_issue.service.inss_value = strToNum(this.form_data.federal_taxes_nfse.inss_value);
      this.nfse_to_issue.service.ir_value = strToNum(this.form_data.federal_taxes_nfse.ir_value);
      this.nfse_to_issue.service.pis_value = strToNum(this.form_data.federal_taxes_nfse.pis_value);
      this.nfse_to_issue.service.municipal_service_code = this.form_data.company_plant_issuer.municipal_service_code;
      this.nfse_to_issue.service.service_list_item = this.form_data.company_plant_issuer.service_list_item;
      this.nfse_to_issue.service.cnae = this.form_data.company_plant_issuer.cnae;
      this.nfse_to_issue.total_value = strToNum(this.form_data.invoice.total);
      this.nfse_to_issue.deductions_value = this.current_invoice.deductions_value;
      this.nfse_to_issue.discounts_value = 0;
      const cno = this.form_data.contract_proposal_cno
        ? 'CNO: ' + this.form_data.contract_proposal_cno + ' | '
        : '';
      this.nfse_to_issue.comments = cno + this.preview_observation;
      this.nfse_to_issue.operation_source = this.form_data.operation_source ?? '';

      // Inscrição estadual
      const {entity, contract_proposal_state_registration} = this.form_data;
      const legalNumber = entity.legal_entity?.[0]?.contributing_number;
      const individualNumber = entity.individual_entity?.[0]?.contributing_number;
      const stateRegistration = contract_proposal_state_registration || legalNumber || individualNumber || '';
      this.nfse_to_issue.customer.state_registration = stateRegistration;

      // Inscrição municipal
      const entityType = this.entity_document_is_cnpj
        ? this.form_data.entity.legal_entity
        : this.form_data.entity.individual_entity;
      this.nfse_to_issue.customer.municipal_registration = entityType?.[0]?.municipal_registration ?? '';

      // Carregar ibge e estado da obra em serviço conforme configuração em emissor
      this.nfse_to_issue.service.ibge_code = null;
      this.nfse_to_issue.service.service_delivery_state = null;
      if (this.form_data.company_plant_issuer.nfse_load_construction_ibge_code) {
        this.nfse_to_issue.service.ibge_code = this.form_data.construction.default_address.city_ibgecode;
        this.nfse_to_issue.service.service_delivery_state = this.form_data.construction.default_address.state;
      }

      // Carregar código da obra conforme configuração em emissor
      this.nfse_to_issue.city_hall_construction_code = null;
      this.nfse_to_issue.art_code = null;
      if (this.form_data.company_plant_issuer.nfse_load_city_hall_construction_code) {
        this.nfse_to_issue.city_hall_construction_code = this.form_data.contract_proposal_city_hall_construction_code;
        this.nfse_to_issue.art_code = this.form_data.contract_proposal_art_code;
      }

      // Carregar local de prestação do serviço conforme configuração em emissor
      this.nfse_to_issue.construction_address = null;
      if (this.form_data.company_plant_issuer.nfse_load_construction_address) {
        this.nfse_to_issue.construction_address = {
          city: this.form_data.construction.default_address.city,
          state: this.form_data.construction.default_address.state,
          address: this.form_data.construction.default_address.address,
          address_numer: this.form_data.construction.default_address.number,
          district: this.form_data.construction.default_address.district,
          postal_code: this.form_data.construction.default_address.postal_code,
        };
      }

      // Quando municipio da obra for igual a municipio do emissor, verificar configuração
      const isConstructionCityEqualIssuerCity = this.isConstructionCityEqualIssuerCity();
      if (isConstructionCityEqualIssuerCity) {
        this.nfse_to_issue.service.iss_rate = this.form_data.company_plant_issuer.nfse_send_iss_rate_when_construction_city_equal_issuer
          ? strToNum(this.form_data.iss_nfse.retained_rate)
          : this.nfse_to_issue.service.iss_rate = 0;
      }

      // Carregar regime especial de tributação em metadados conforme configuração em emissor
      this.nfse_to_issue.tax_regime_metadata = this.form_data.tax_regime_metadata;
      this.nfse_to_issue.replace_comma_with_dot = this.form_data.company_plant_issuer.nfse_replace_comma_with_dot;
      this.nfse_to_issue.load_calc_base_in_metadata = this.form_data.company_plant_issuer.nfse_load_calc_base_in_metadata;
      this.nfse_to_issue.change_tag_regime_to_cst = this.form_data.company_plant_issuer.nfse_change_tag_regime_to_cst;
    },
    /**
     * Obter Regime Tributário para carregar em Metadados da NFS-e
     */
    getTaxRegimeMetadata() {
      // Verificar se deve enviar metadados
      if (!this.form_data.company_plant_issuer.nfse_send_regime_metadata)
        return null;

      // Municipio da obra é igual a municipio do emissor
      const isConstructionCityEqualIssuerCity = this.isConstructionCityEqualIssuerCity();

      // Municipio do tomador é igual a municipio do emissor
      const isEntityCityEqualIssuerCity = this.isEntityCityEqualIssuerCity();

      // Tomador é Pessoa Jurídica
      const entityIsLegal = isCnpjDocument(this.form_data.entity.document);

      // Obter regime de tributação conforme dados preenchidos para a nfs-e
      if (isConstructionCityEqualIssuerCity) {
        if (isEntityCityEqualIssuerCity) {
          return entityIsLegal
            ? this.form_data.company_plant_issuer.nfse_rule1_within_city_iss_legal_regime_metadata
            : this.form_data.company_plant_issuer.nfse_rule1_within_city_iss_individual_regime_metadata;
        } else {
          return entityIsLegal
            ? this.form_data.company_plant_issuer.nfse_rule1_outside_city_iss_legal_regime_metadata
            : this.form_data.company_plant_issuer.nfse_rule1_outside_city_iss_individual_regime_metadata;
        }
      } else {
        if (isEntityCityEqualIssuerCity) {
          return entityIsLegal
            ? this.form_data.company_plant_issuer.nfse_rule2_within_city_iss_legal_regime_metadata
            : this.form_data.company_plant_issuer.nfse_rule2_within_city_iss_individual_regime_metadata;
        } else {
          return entityIsLegal
            ? this.form_data.company_plant_issuer.nfse_rule2_outside_city_iss_legal_regime_metadata
            : this.form_data.company_plant_issuer.nfse_rule2_outside_city_iss_individual_regime_metadata;
        }
      }
    },
    /**
     * Verificar se Cidade da obra e Cidade do emissor são iguais
     */
    isConstructionCityEqualIssuerCity() {
      return (this.form_data.construction.default_address.state === this.form_data.company_plant_issuer.state) &&
        (this.form_data.construction.default_address.city === this.form_data.company_plant_issuer.city);
    },
    /**
     * Verificar se Cidade do tomador e Cidade do emissor são iguais
     */
    isEntityCityEqualIssuerCity() {
      return (this.form_data.entity.address.state === this.form_data.company_plant_issuer.state) &&
        (this.form_data.entity.address.city === this.form_data.company_plant_issuer.city);
    },
    async getSpecialTaxRules() {
      this.specialTaxRules = [];
      if (!this.form_data.company_plant_issuer.nfse_ms_company_id) {
        return;
      }

      this.loadingSpecialTaxRules = true;
      switch (this.form_data.company_plant_issuer.nfse_provider) {
        case 0: // ENOTAS
          if (!this.form_data.company_plant_issuer.ibge_code) {
            this.loadingSpecialTaxRules = false;
            return;
          }
          this.specialTaxRules = await this.$store
            .dispatch("nfse/getTaxRegimesByIbgeCode", this.form_data.company_plant_issuer.ibge_code)
            .then((response) => {
              const regimes = response.data['regimesEspecialTributacao'].map(item => ({
                code: item.codigo,
                name: item.nome
              }));
              this.loadingSpecialTaxRules = false;
              return regimes;
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
              this.loadingSpecialTaxRules = false;
              return [];
            });
          break;

        case 1: // MIGRATE
          let param = this.form_data.company_plant_issuer.nfse_ms_company_id;
          this.specialTaxRules = await this.$store
            .dispatch("nfse/getSpecialTaxRulesByNfseMsCompanyId", param)
            .then((response) => {
              const regimes = response.data.map(item => ({
                code: item.code,
                name: item.description
              }));
              this.loadingSpecialTaxRules = false;
              return regimes;
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
              this.loadingSpecialTaxRules = false;
              return [];
            });
          break;

        default:
          break;
      }
    },
    handleEditEntity(entityUuid) {
      this.$refs.ModalEditEntity.openModal(true, entityUuid);
    },
    loadEntityDetailsAfterUpdate(entity) {
      this.form_data.entity = entity;
    },
    formatPercentage(value) {
      return parseFloat(value).toFixed(2).replace(".", ",");
    },
    /**
     * Inicializa e retorna o objeto padrão para emissão de NFSe.
     * @returns {Object} Objeto com os dados padrão da NFSe.
     */
    initNfseToIssue(){
      return {
        broadcast_environment: "Homologacao",
        sent_by_email: false,
        customer: {
          legal_entity: "",
          name: "",
          email: "",
          ein: "",
          phone: "",
          address: {
            country: "",
            state: "",
            city: "",
            address: "",
            address_number: "",
            complement: "",
            district: "",
            postal_code: "",
          },
        },
        service: {
          description: "",
          iss_withheld_at_source: false,
          iss_rate: 0,
          cofins_value: 0,
          csll_value: 0,
          inss_value: 0,
          ir_value: 0,
          pis_value: 0,
          ibge_code: null,
          service_delivery_state: null,
        },
        total_value: 0,
        deductions_value: 0,
        discounts_value: 0,
        comments: "",
        construction_address: {
          city: "",
          state: "",
          address: "",
          address_numer: "",
          district: "",
          postal_code: ""
        },
        tax_regime_metadata: "",
        replace_comma_with_dot: false,
      };
    }
  },
};
</script>

<style scoped>

</style>
