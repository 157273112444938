<template>
  <div>
    <modal
      size="lg"
      :backgroundColor="'#F2F4F9'"
      :show.sync="modal.create"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/security-shield-green_primary.png" height="24" width="24">
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>

      <div class="system-module-container mt-1">
        <div class="system-module-label">
          <img src="/img/brand/logo.png" alt="Puzl Place" class="mr-2" width="20.8px" height="20px">
          Configuração
        </div>
        <div class="system-module-content">
          <PuzlSelect
            @input="changedType"
            v-model="selected"
            :items="modules"
            :disableBoxShadow="true"
          />
        </div>
      </div>

      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" autocomplete="off">
            <!-- GERAL -->
            <div v-if="selected === 0">
              <div class="col-md-12 label-title mb-1">Geral</div>
              <div class="form-group row m-0 p-o  d-flex align-items-center">
                <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                  Aplicação de imposto geral em margem?
                </label>
                <div class='col-md-5' style="flex-direction: row-reverse;">
                  <template v-if="$_setting.geral_tax_application_in_margin == 1">
                    <input-limit
                      :start_value="$_setting.general_tax_margin_percent"
                      :options="{
                        min: -50,
                        max: 100,
                        decimals: 0
                      }"
                      v-model="$_setting.general_tax_margin_percent"
                      @value="$_setting.general_tax_margin_percent = $event"
                      class="no-box-shadow"
                    >
                      <small>%</small>
                    </input-limit>
                  </template>
                </div>
                <div class="col-md-3 pt-2 d-flex justify-content-center">
                  <ToggleButton
                   :initialValue="$_setting?.geral_tax_application_in_margin ?? false"
                    @update:initialValue="$_setting.geral_tax_application_in_margin = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0  d-flex align-items-center">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Desconsiderar pendência de liberação de crédito
                </label>
                <div class="col-md-3 pt-2 d-flex justify-content-center">
                  <ToggleButton
                   :initialValue="$_setting?.disable_pending_credit_release ?? false"
                    @update:initialValue="$_setting.disable_pending_credit_release = $event"
                  />
                </div>
              </div>

              <div v-if="!$_setting.disable_pending_credit_release" class="form-group row m-0 p-0  d-flex align-items-center">
                <label class="col-md-7 pb-1  col-form-label label-item">
                  Dados da carteira de clientes/fornecedores
                </label>
                <div class="col-md-5 pt-2 d-flex justify-content-center">
                  <PuzlSelect :clearable="false" class="w-100" v-model="$_setting.entity_credit_source" :items="generalSettingEntityCreditSourceItems" />
                </div>
              </div>

              <!-- Configurações de integração -->
              <div class="mt-3">
                <div class="col-md-12 label-title mb-1 mt-4">
                  Configurações de integração
                </div>
                <!-- Rastrin -->
                <div>
                  <!-- Habilitar -->
                  <div class="form-group row m-0 p-o d-flex justify-content-between">
                    <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                      Habilitar integração com Rastrin
                    </label>
                    <div class="col-md-5">
                      <ToggleButton
                        :initialValue="$_setting?.is_active_rastrin_integration ?? false"
                        @update:initialValue="$_setting.is_active_rastrin_integration = $event"
                      />
                    </div>
                  </div>
                  <div v-if="$_setting.is_active_rastrin_integration">
                    <!-- Url -->
                    <div class="form-group row m-0 p-0 mt-2 d-flex justify-content-between">
                      <label class="col-md-7 col-form-label form-control-label">
                        Url
                      </label>
                      <div class="col-md-5 mt-1">
                        <input maxlength="255" type="text" v-model="$_setting.rastrin_integration_url" class="form-control form-control-sm no-box-shadow"/>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mt-2 d-flex justify-content-between">
                      <label class="col-md-5 col-form-label form-control-label">
                        E-mail
                      </label>
                      <div class="col-md-5 mt-1">
                        <input maxlength="255" type="text" v-model="$_setting.rastrin_integration_email" class="form-control form-control-sm no-box-shadow"/>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mt-2 d-flex justify-content-between">
                      <label class="col-md-7 col-form-label form-control-label">
                        Senha
                      </label>
                      <div class="col-md-5 mt-1">
                        <input maxlength="60" type="password" v-model="$_setting.rastrin_integration_password" class="form-control form-control-sm no-box-shadow"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 label-title mb-1 mt-4">
                Nota fiscal de carregamento
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  Ocultar número de NF-e em relatórios?
                </label>
                <div class="col-md-5">
                  <ToggleButton
                    :initialValue="$_setting?.hide_nfe_report ?? false"
                    @update:initialValue="$_setting.hide_nfe_report = $event"
                  />
                </div>
              </div>

              <div class="col-md-12 label-title mb-1 mt-4">
                Nota fiscal de serviço
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  Ocultar valores unitários em descrição?
                </label>
                <div class="col-md-5">
                  <ToggleButton
                    :initialValue="$_setting?.is_hide_nfse_value_in_description ?? false"
                    @update:initialValue="$_setting.is_hide_nfse_value_in_description = $event"
                  />
                </div>
              </div>
            </div>
            <!-- FINANCEIRO -->
            <div v-if="selected === 2">
              <div class="col-md-12 label-title mb-1">
                Faturamento automático
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Padrão em propostas/contratos?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.contract_proposal ?? false"
                    @update:initialValue="$_setting.contract_proposal = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Habilitar faturamento automático?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.automatic_billing ?? false"
                    @update:initialValue="$_setting.automatic_billing = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Faturar com saldo parcial?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.partial_balance ?? false"
                    @update:initialValue="$_setting.partial_balance = $event"
                  />
                </div>
              </div>

              <div class="col-md-12 label-title mb-1 mt-4">
                Faturamento/manual
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Padrão de crédito de antecipação sempre igual a sim?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.partial_balance_manual_billing ?? false"
                    @update:initialValue="$_setting.partial_balance_manual_billing = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir incluir anexo de boleto manual?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.allow_bank_billet_as_attachment ?? false"
                    @update:initialValue="$_setting.allow_bank_billet_as_attachment = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir entregas de meses diferentes na mesma fatura?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.allow_delivery_different_month ?? false"
                    @update:initialValue="$_setting.allow_delivery_different_month = $event"
                  />
                </div>
              </div>

              <div class="col-md-12 label-title mb-1 mt-4">
                Emissões automáticas
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Envio automático de e-mail?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.automatic_email ?? false"
                    @update:initialValue="$_setting.automatic_email = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Emissão automática de Nfse?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.automatic_nfse ?? false"
                    @update:initialValue="$_setting.automatic_nfse = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Emissão automática de boleto?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.automatic_billet ?? false"
                    @update:initialValue="$_setting.automatic_billet = $event"
                  />
                </div>
              </div>
            </div>
            <!-- COMERCIAL -->
            <div v-if="selected === 1">
              <div class="col-md-12 label-title mb-1">
                Propostas/contratos
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir Imprimir propostas com pendências?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.pending_proposals ?? false"
                    @update:initialValue="$_setting.pending_proposals = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permite alterar o endereço da obra?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.construction_address ?? false"
                    @update:initialValue="$_setting.construction_address = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exigir anexo de obra para salvar nova proposta/contrato?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_attachment_required_for_new_proposal_contract ?? false"
                    @update:initialValue="$_setting.is_attachment_required_for_new_proposal_contract = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exigir nível de aplicação?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.demand_level_responsibility ?? false"
                    @update:initialValue="$_setting.demand_level_responsibility = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exigir informe de bomba externa e convencional?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_external_and_conventional_pump_report_required ?? false"
                    @update:initialValue="$_setting.is_external_and_conventional_pump_report_required = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exibir teor de agregados graúdos na inclusão de traços?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.show_coarse_aggregate ?? false"
                    @update:initialValue="$_setting.show_coarse_aggregate = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Cobrar adicional de carregamentos de "retira"
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_charge_extra_loads ?? false"
                    @update:initialValue="$_setting.is_charge_extra_loads = $event"
                  />
                </div>
              </div>
            </div>

            <!-- OPERACIONAL -->
            <div v-if="selected === 4">
              <div class="col-md-12 label-title mb-1">Carregamento</div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir carregamento retroativo?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.allow_backloading ?? false"
                    @update:initialValue="$_setting.allow_backloading = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir emitir a "O.S." de concreto antes de concluir o carregamento?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_concrete_os_issued_before_loading_completion ?? false"
                    @update:initialValue="$_setting.is_concrete_os_issued_before_loading_completion = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permitir emitir corte manual de aditivo e água
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_manual_cut_additive_water ?? false"
                    @update:initialValue="$_setting.is_manual_cut_additive_water = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Ao imprimir NF-e, incluir O.S na segunda página?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.add_os_to_danfe ?? false"
                    @update:initialValue="$_setting.add_os_to_danfe = $event"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Travar carregamento quando não houver anexo na penúltima carga?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.disable_charging_without_attachment ?? false"
                    @update:initialValue="$_setting.disable_charging_without_attachment = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Concluir viagem automaticamente após a conclusão do carregamento?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.finish_travel_after_charging ?? false"
                    @update:initialValue="$_setting.finish_travel_after_charging = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exibir volume acumulado na O.S. de carregamento
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_cumulative_load_volume ?? false"
                    @update:initialValue="$_setting.is_cumulative_load_volume = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Preencher automaticamente motorista no MDF-e
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.show_driver_mdfe ?? false"
                    @update:initialValue="$_setting.show_driver_mdfe = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Bloquear carregamento com volume acima da capacidade do equipamento?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.block_charging_equipment_limit_volumec ?? false"
                    @update:initialValue="$_setting.block_charging_equipment_limit_volumec = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Ignorar desvio de carregamento na água retida da nota?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.ignore_water_diversion ?? false"
                    @update:initialValue="$_setting.ignore_water_diversion = $event"
                  />
                </div>
              </div>

            </div>
            <!-- TÉCNICO -->
            <div v-if="selected === 7">
              <div class="col-md-12 label-title mb-1">Técnico</div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Aprovar "redirecionamento" automaticamente?
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.automatically_approve_redirect ?? false"
                    @update:initialValue="$_setting.automatically_approve_redirect = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exigir que MCC esteja no mapa de ponto de carga para alteração de referência comercial
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_mcc_on_map_for_commercial_pref_change ?? false"
                    @update:initialValue="$_setting.is_mcc_on_map_for_commercial_pref_change = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-o  d-flex align-items-center">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                 Bloquear carregamentos com desvio
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.is_block_loads_with_deviation ?? false"
                    @update:initialValue="$_setting.is_block_loads_with_deviation = $event"
                  />
                </div>
                <div class="form-group row m-0 p-0 mt-3 d-flex align-items-center w-100">
                  <div class='col-md-10' style="flex-direction: row-reverse;">
                    <template v-if="$_setting.is_block_loads_with_deviation == 1">
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Aglomerante
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_binder_percentage_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_binder_percentage_value"
                          @value="$_setting.block_loads_binder_percentage_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Agregado graúdo
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_coarse_aggregate_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_coarse_aggregate_value"
                          @value="$_setting.block_loads_coarse_aggregate_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Agregado miúdo
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_fine_aggregate_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_fine_aggregate_value"
                          @value="$_setting.block_loads_fine_aggregate_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Aditivo
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_additive_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_additive_value"
                          @value="$_setting.block_loads_additive_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Adição
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_addition_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_addition_value"
                          @value="$_setting.block_loads_addition_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                          Água
                        </label>
                        <input-limit
                          :start_value="$_setting.block_loads_water_value"
                          :options="{
                            min: -50,
                            max: 99,
                            decimals: 0
                          }"
                          v-model="$_setting.block_loads_water_value"
                          @value="$_setting.block_loads_water_value = $event"
                          class="no-box-shadow"
                        >
                          <small>%</small>
                        </input-limit>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Prazo de aplicação do concreto
                </label>
                <div class="col-md-3 pt-2">
                  <InputTimePicker
                    minuteInterval="1"
                    v-model="$_setting.concrete_application_period"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Exigir código de moldagem para concluir carregamento
                </label>
                <div class="col-md-3 pt-2">
                  <ToggleButton
                    :initialValue="$_setting?.require_molding_code ?? false"
                    @update:initialValue="$_setting.require_molding_code = $event"/>
                </div>
              </div>
            </div>
            <!-- INTEGRAÇÃO -->
            <div v-if="selected === 3">
              <div class="col-md-12 label-title mb-1">Integração</div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                  Tipo de Integração
                </label>
                <div class="col-md-8">
                  <PuzlSelect
                    v-model="syncHubSelected"
                    :items="syncHubItems"
                    @input="changedSyncHubSelected"
                    :clearable="false"
                  />
                </div>
              </div>
              <div v-if="$_sync_hub && syncHubSelected">
                <div class="form-group row m-0 p-0 mb-2">
                  <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                    Habilitar integração
                  </label>
                  <div class="col-md-8">
                    <ToggleButton
                      :initialValue="$_sync_hub.is_active"
                      @update:initialValue="$_sync_hub.is_active = $event"
                    />
                  </div>
                </div>
                <div v-if="$_sync_hub.is_active">
                  <div class="form-group row m-0 p-0 mb-2">
                    <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                      Ulr de Autenticação
                    </label>
                    <div class="col-md-8">
                      <input type="text" v-model="$_sync_hub.auth_url" class="form-control form-control-sm no-box-shadow"/>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2">
                    <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                      Url base
                    </label>
                    <div class="col-md-8">
                      <input type="text" v-model="$_sync_hub.url" class="form-control form-control-sm no-box-shadow"/>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2">
                    <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                      Usuário
                    </label>
                    <div class="col-md-8">
                      <input type="text" v-model="$_sync_hub.auth_username" class="form-control form-control-sm no-box-shadow"/>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2">
                    <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                      Senha
                    </label>
                    <div class="col-md-8">
                      <input type="text" v-model="$_sync_hub.auth_password" class="form-control form-control-sm no-box-shadow"/>
                    </div>
                  </div>
                  <div v-if="$_sync_hub.sync_hub === SyncHubEnum.keys.SANKHYA">
                    <div class="form-group row m-0 p-0 mb-2">
                      <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                        Token
                      </label>
                      <div class="col-md-8">
                        <input type="text" v-model="$_sync_hub.token" class="form-control form-control-sm no-box-shadow"/>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-2">
                      <label class="col-md-4 pb-1 mt-n1 col-form-label label-item">
                        AppKey
                      </label>
                      <div class="col-md-8">
                        <input type="text" v-model="$_sync_hub.app_key" class="form-control form-control-sm no-box-shadow"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PADRÃO DE EMAIL -->
            <div v-if="selected === 5">
              <div class="form-group row m-0 p-0 col-md-6 mt-n4">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Empresa
                  </div>
                  <base-input autocomplete="off">
                    <puzl-select
                      @input="changedEmailDefaultIssuerGroupId"
                      v-model="email_default_issuer_group_id"
                      :items="$_company_plant_issuer_groups"
                      :clearable="false"
                    />
                  </base-input>
                </div>
              </div>
              <div v-if="emailDefault && emailDefault[current_model]" autocomplete="off">
                <div class="form-group row m-0 p-0 col-md-6">
                  <div class="col-md-12">
                    <div class="pb-0 mb-1 col-form-label form-control-label">
                      Modelo
                    </div>
                    <base-input autocomplete="off">
                      <puzl-select
                        v-model="current_model"
                        :items="models"
                        :clearable=false
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-12">
                    <div class="pb-0 mb-1 col-form-label form-control-label">
                      Assunto (Prefixo)
                    </div>
                    <base-input input-group-classes="input-group">
                      <input
                        type="text"
                        class="form-control form-control-sm col-md-9"
                        v-model="emailDefault[current_model].subject_prefix"
                      />
                      <input
                        placeholder="- EMPRESA | FATURA 123"
                        type="text"
                        class="form-control form-control-sm col-md-3"
                        :disabled="true"
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mt-2">
                  <div class="col-md-12">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Mensagem
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex justify-content-end">
                        <button
                          style="margin: 0;width: 190px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                          class="btn btn-outline-primary dropdown-toggle text-uppercase colorize-btn-img"
                          @click.prevent="setDefaultParams()"
                        >
                          Padrão
                        </button>
                      </div>
                    </div>
                    <base-input v-if="$_mentions[current_model] && $_mentions[current_model].mentions" input-group-classes="input-group">
                      <div class="col-12 col-md-12 m-0 p-0">
                        <mention
                          style="font-size: 2rem !important;"
                          :default_comment="emailDefault[current_model].body"
                          :items="Object.values($_mentions[current_model].mentions)"
                          v-model.lazy="emailDefault[current_model].body"
                          rows="12"
                        />
                      </div>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- RÉGUA DE COBRANÇA -->
            <div v-if="selected === 6">
              <div class="col-md-12 label-title mb-1">Régua de Cobrança</div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  Habilitar régua de cobrança
                </label>
                <div class="col-md-5">
                  <ToggleButton
                    :initialValue="$_setting?.billing_rule ?? false"
                    @update:initialValue="$_setting.billing_rule = $event"
                  />
                </div>
              </div>
              
              <div v-if="$_setting?.billing_rule" class="form-group row m-0 p-0  d-flex align-items-center">
                <label class="col-md-7 pb-1  col-form-label label-item">
                  Para os seguintes métodos de pagamento
                </label>
                <div class="col-md-5 pt-2 d-flex justify-content-center">
                  <PuzlSelect 
                    class="w-100" 
                    v-model="$_setting.billing_rule_payment_method_uuids"
                    :items="$_payment_methods" 
                    :multiple="true"
                    customKey="uuid"
                  />
                </div>
              </div>

              <div v-if="$_setting.billing_rule" class="my-3" style="border-bottom: 1px solid #dee2e6; width: 95%; margin: auto;"/>
              <div v-if="$_setting.billing_rule" class="form-group row m-0 p-o d-flex justify-content-between">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  Lembretes de parcelas a vencer
                </label>
                <div class="col-md-5">
                  <ToggleButton
                    :initialValue="$_setting?.overdue_installment_reminders ?? false"
                    @update:initialValue="$_setting.overdue_installment_reminders = $event"
                  />
                </div>
              </div>
              <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders" class="form-group row m-0 p-0">
                <label class="col-md-7 pb-1 mt-2 col-form-label label-item">
                  Dias antes do vencimento
                </label>
                <div class="col-md-2 mt-2">
                  <template>
                    <input-limit
                      :start_value="$_setting.days_before_due_date"
                      :options="{
                          min: 0,
                          max: 99,
                          decimals: 0
                        }"
                      v-model="$_setting.days_before_due_date"
                      @value="$_setting.days_before_due_date = $event"
                    >
                      <small>DIAS</small>
                    </input-limit>
                  </template>
                </div>
              </div>
              <div v-if="$_setting.billing_rule" class="my-3" style="border-bottom: 1px solid #dee2e6; width: 95%; margin: auto;"/>
              <div v-if="$_setting.billing_rule" class="form-group row m-0 p-o d-flex justify-content-between">
                <label class="col-md-7 pb-1 col-form-label label-item">
                  Lembretes de parcelas vencidas
                </label>
                <div class="col-md-5 mt-2">
                  <ToggleButton
                    :initialValue="$_setting?.overdue_installment_reminders_expired ?? false"
                    @update:initialValue="$_setting.overdue_installment_reminders_expired = $event"
                  />
                </div>
              </div>
              <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o">
                <label class="col-md-10 pb-1 mt-2 col-form-label label-item">
                  RECORRÊNCIA DE COBRANÇA DAS PARCELAS VENCIDAS. ENVIAR EMAIL A CADA:
                </label>
                <div class="col-md-2 mt-2">
                  <template>
                    <input-limit
                      :start_value="$_setting.overdue_billing_recurring"
                      :options="{
                          min: 0,
                          max: 99,
                          decimals: 0
                        }"
                      v-model="$_setting.overdue_billing_recurring"
                      @value="$_setting.overdue_billing_recurring = $event"
                      class="no-box-shadow"
                    >
                      <small>DIAS</small>
                    </input-limit>
                  </template>
                </div>
              </div>
              <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o">
                <label class="col-md-10 pb-1 mt-2 col-form-label label-item">
                  NÃO COBRAR PARCELA VENCIDA APÓS QUANTIDADE DE DIAS CONFIGURADA
                </label>
                <div class="col-md-2">
                  <template>
                    <input-limit
                      :start_value="$_setting.maximum_collection_time"
                      :options="{
                          min: 0,
                          max: 99,
                          decimals: 0
                        }"
                      v-model="$_setting.maximum_collection_time"
                      @value="$_setting.maximum_collection_time = $event"
                      class="no-box-shadow"
                    >
                      <small>DIAS</small>
                    </input-limit>
                  </template>
                </div>
              </div>
              <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o">
                <label class="col-md-10 pb-1 mt-2 col-form-label label-item">
                  NÚMERO DE VEZES QUE CADA PARCELA SERÁ COBRADA (Limite: 4)<br>
                  Serão realizadas "{{ billingRecurrenceLimit }}" cobranças de cada parcela
                  a cada "{{ $_setting.overdue_billing_recurring }}" dias.
                </label>
                <div class="col-md-2">
                  <template>
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="billingRecurrenceLimit"
                      disabled
                      class="no-box-shadow"
                    />
                  </template>
                </div>
              </div>
              <label
                v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired && billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT"
                class="m-0 col-md-12 col-form-label form-control-label text-right text-danger"
              >
                LIMITE DE RECORRÊNCIA EXCEDIDO (MÁX. DE 4)
              </label>
            </div>

            <!-- CONFIGURAÇÕES DE WHATSAPP -->
            <div v-if="selected === 8">
              <div class="col-md-12 label-title mb-1">Whatsapp</div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  HABILITAR API DO WHATSAPP
                </label>
                <div class="col-md-5">
                  <ToggleButton
                    :initialValue="$_setting?.is_enable_whatsapp_api ?? false"
                    @update:initialValue="$_setting.is_enable_whatsapp_api = $event"
                  />
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  TOKEN
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm no-box-shadow">
                    <input type="text" v-model="$_setting.whatsapp_api_token" class="form-control form-control-sm"/>
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                  IDENTIFICAÇÃO DA CONTA DO WHATSAPP BUSINESS
                </label>
                <div class="col-md-5">
                  <input type="text" v-model="$_setting.whatsapp_api_business_id" class="form-control form-control-sm no-box-shadow"/>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <div class="d-flex pr-3" style="gap: 18px;">
                <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal('create')">
                  <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                  <span style="font-weight: 500;">
                    Cancelar
                  </span>
                </div>
                <!-- ![3, 5]. Integração e Padrão de Email -->
                <div
                  v-if="![3, 5].includes(selected)"
                  class="d-flex align-items-center py-1 px-4 button-save"
                  :style="(invalid || ($_setting.billing_rule && $_setting.overdue_installment_reminders_expired && billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT))
                    ? { 'pointer-events': 'none', 'opacity': '0.5' }
                    : {}"
                  style="gap: 5px;"
                  @click="!(invalid || ($_setting.billing_rule && $_setting.overdue_installment_reminders_expired && billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT)) && handleSubmit(update)"
                >
                  <img src="/img/icons/icons8/ios/ok--v1_success.png" alt="save_success" width="16px">
                  <span style="font-weight: 500;">
                    Salvar
                  </span>
                </div>
                <!-- Integração ou Padrão de E-mail -->
                <div
                  v-else
                  class="d-flex align-items-center py-1 px-4 button-save"
                  style="gap: 5px;"
                  @click.prevent="selected == 3
                    ? updateSyncHub(syncHubSelected)
                    : updateEmailDefault()"
                >
                  <img src="/img/icons/icons8/ios/ok--v1_success.png" alt="save_success" width="16px">
                  <span style="font-weight: 500;">
                    Salvar
                  </span>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputLimit from "@/components/Utils/InputLimit.vue";
import PuzlSelect from "@/components/PuzlSelect";
import ToggleButton from '@/components/ToggleButton.vue'
import AppSwitch from '@/components/AppGlobal/AppSwitch/AppSwitch'
const { formatErrorValidation } = require("@/plugins");
import { dialogs, progress } from "../../../helpers";
import sapConfigurationStore from '../../../modules/sap/sap-configuration/store';
import syncHubStore from '../../../modules/sync/sync-hub/store/syncHubStore';
import { SyncHubEnum } from '../../../modules/sync/shared/enums/SyncHubEnum';
import InputTimePicker from "@/components/InputTimePickerV2";
import GeneralSettingEntityCreditSourceEnum from  "../../../modules/configuration/general-setting/enums/GeneralSettingEntityCreditSourceEnum";
import { getTitles } from "../../../helpers";
import Mention from "@/components/Inputs/Mention";

const model_enum = {
  invoice: 0,
  bill: 1,
  contract: 2,
  reminder: 3,
};

export default {
  name: "ModalCreate",
  components: {
    InputLimit,
    PuzlSelect,
    ToggleButton,
    InputTimePicker,
    AppSwitch,
    Mention,
  },
  computed: {
    ...mapGetters({
      $_setting: "generalSetting/show",
      $_user: 'auth/getUser',
      $_mentions: "companyEmailParam/getListMentions",
      $_company_plant_issuer_groups: "companyPlantIssuerGroup/fetch",
      $_payment_methods: "paymentMethod/fetchUsable",
    }),
    billingRecurrenceLimit() {
      const hasValue = this.$_setting.maximum_collection_time > 0 && this.$_setting.overdue_billing_recurring > 0;
      if (!hasValue) {
        return 0;
      }
      return Math.trunc(this.$_setting.maximum_collection_time/this.$_setting.overdue_billing_recurring);
    },
    $_sapConfiguration() {
      return sapConfigurationStore.getItem();
    },
    $_sync_hub() {
      return syncHubStore.getters.getItem();
    },
    syncHubItems() {
      return getTitles(SyncHubEnum).filter(item => item.name.toLowerCase() !== 'puzl');
    },
    modules() {
      // Permissão para acessar régua de cobrança
      const billingRuleModule = this.$hasPrivilege(1) 
        ? [{ id: 6, name: 'Régua de Cobrança' }] 
        : [];

      return [
        { id: 0, name: 'Geral' },
        { id: 1, name: 'Comercial' },
        { id: 2, name: 'Financeiro' },
        { id: 3, name: 'Integração' },
        { id: 4, name: 'Operacional' },
        { id: 5, name: 'Padrão de E-mail' },
        ...billingRuleModule,
        { id: 7, name: 'Técnico' },
        { id: 8, name: 'Whatsapp' },
      ];
    }
  },
  data() {
    return {
      modal: {
        title: 'Configurações gerais',
        create: false,
      },
      loadingStore: false,
      selected: 0,
      BILLING_RECURRENCE_LIMIT: 4,
      generalSettingEntityCreditSourceItems : getTitles(GeneralSettingEntityCreditSourceEnum),
      SyncHubEnum: SyncHubEnum,
      syncHubSelected: null,
      current_model: 0,
      model_enum: model_enum,
      models: [
        {id: model_enum.invoice, name: 'Fatura'},
        {id: model_enum.bill, name: 'Cobrança'},
        {id: model_enum.contract, name: 'Contrato'},
        {id: model_enum.reminder, name: 'Lembrete'},
      ],
      emailDefault: null,
      isLoading: false,
      email_default_issuer_group_id: null,
    };
  },
  mounted() {
    this.$store.dispatch('companyEmailParam/getMentions');
    this.$store.dispatch('companyPlantIssuerGroup/fetchItems', {
      order_by: [{ column: "company_plant_issuer_groups.name", is_desc: false }],
    });
    this.$store.dispatch("paymentMethod/fetchItems");
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.selected = 0;
      this.modal.create = false;
    },
    openModal() {
      this.loadData();
      this.modal.create = true;
    },
    loadData() {
      let loader = this.$loading.show();
      this.$store.dispatch('generalSetting/show').then(() => {
          loader.hide();
      });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      let loader = this.$loading.show();
      this.$store
        .dispatch("generalSetting/update", this.$_setting)
        .then(response => {
            let payload = {
              waID: response.data.whatsapp_api_business_id,
              token: response.data.whatsapp_api_token,
              base_url: window.location.origin
            }
            this.$notify({ type: response.error_type, message: response.message });
            this.$store.dispatch("whatsappApi/setupWhatsappApi", payload)
            .then(response => {
                this.$notify({ type: response.error_type, message: response.message });
            }).catch(error =>
              this.$notify({ type: error.error_type, message: "Não foi possível configurar a api do whatsapp. erro: " + error.message
            }))
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        }).finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
          loader.hide();
          this.closeModal();
        });
    },
    /**
     * Atualiza a configuração da integração com base na seleção do SyncHub.
     *
     * @param {typeof SyncHubEnum.keys[keyof typeof SyncHubEnum.keys]|null} syncHub
     */
    async updateSyncHub(syncHub) {
      if (!syncHub) {
        return;
      }
      const loader = this.$loading.show();
      try {
        await syncHubStore.actions.createOrUpdate({
           ...this.$_sync_hub,
           sync_hub: syncHub
        });
        dialogs.notify();
        this.closeModal();
      } finally {
        loader.hide();
      }
    },
    async changedType(value) {
      if (this.modules[value]?.name !== 'Integração'){
        return;
      }
      const loader = progress.showLoader();
      try {
        this.syncHubSelected = await syncHubStore.actions.findActiveTitle();
        this.changedSyncHubSelected(this.syncHubSelected);
      } finally {
        loader.hide();
      }
    },
    /**
     * Localiza a configuração da integração com base na seleção do SyncHub.
     *
     * @param {typeof SyncHubEnum.keys[keyof typeof SyncHubEnum.keys]|null} syncHub
     */
    async changedSyncHubSelected(syncHub) {
      if (!syncHub) {
        return;
      }
      const loader = progress.showLoader();
      try {
        await syncHubStore.actions.find(syncHub);
      } finally {
        loader.hide();
      }
    },
    setDefaultParams(){
      const keepGoing = (this.current_model >= 0) && this.$_mentions && this.$_mentions[this.current_model];
      if (!keepGoing) {
        return;
      }
      this.emailDefault[this.current_model].body = this.$_mentions[this.current_model].default;
    },
    /**
     * Atualizar Padrão de E-mail
     * @param {boolean} closeModal Fechar Modal. Padrão: True
     */
    updateEmailDefault(closeModal = true) {
      this.isLoading = true;
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$store
        .dispatch('companyEmailParam/storeOrUpdate', this.emailDefault)
        .then(({ error_type, message }) => {
          if (closeModal) {
            this.$notify({type: error_type, message});
            this.closeModal();
          }
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    changedEmailDefaultIssuerGroupId() {
      this.updateEmailDefault(false);
      this.current_model = 0;
      this.$Progress.start();
      let loader = this.$loading.show();
      const params = {filter: {company_plant_issuer_group_id: this.email_default_issuer_group_id}};
      this.$store
        .dispatch("companyEmailParam/fetchItems", params)
        .then((response) => {
          // Adicione modelos ausentes
          this.emailDefault = response.data;
          Object.values(this.model_enum).forEach(modelValue => {
            if (!this.emailDefault.some(item => item.model === modelValue)) {
              this.emailDefault.push({
                id: null,
                company_plant_issuer_group_id: this.email_default_issuer_group_id,
                model: modelValue,
                subject_prefix: "",
                body: "",
              });
            }
          });
          // Ordenar por modelos
          this.emailDefault.sort((a, b) => a.model - b.model);
          this.$notify({type: response.error_type, message: response.message});
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          loader.hide();
        });

      this.emailDefault = {};
    }
  },
};
</script>

<style scoped>
.btn.dropdown-toggle::after {
  content: none;
}
.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 991px) {
  .base-button {
    font-size: 6px;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}

/** labels padrão */
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
