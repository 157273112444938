<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in listed.items" :key="item.id">
        <div class="card main-card mb-3">
          <div class="p-3 px-4">
            <div class="row align-items-center">
              <div class="col-10">
                <section class="top-section d-flex align-items-center mb-1">
                  <AppSwitch v-model="item.status" :asyncClick="() => updateStatus(item)" />
                  <div class="row m-0 ml-2">
                    <div class="row mx-1">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="hover"
                        >
                          <div class="d-flex mb-2" style="gap: 15px;font-weight: 500;color: #2B2D32">
                            <span v-if="item.updated_by_user_name === null">Criação</span>
                            <span v-else>Última Atualização</span>
                          </div>
                          <hr>
                          <div class="mt-2">
                            <span v-if="item.updated_by_user_name === null">
                              {{ item.created_by_user_name }}<br/>
                              {{ date.make(item.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                            </span>
                            <span v-else>
                              {{ item.updated_by_user_name }}<br/>
                              {{ date.make(item.updated_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <AppIcon icon="male-user" width="21" />
                          </base-button>
                        </el-popover>
                      </div>
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="hover"
                        >
                        <div 
                            class="d-flex mb-2" 
                            style="gap: 15px;font-weight: 500;color: #2B2D32"
                        >
                          <span>MEDIADOR DE PAGAMENTO</span>
                        </div>
                        <hr>
                        <ul class="payment-list">
                          <li v-for="name in paymentIntermediaryNames(item.payment_intermediary_ids, paymentIntermediaries)" :key="name">
                            {{ name }}
                          </li>
                        </ul>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <AppIcon icon="escrow-process" width="21" color="primary" />
                        </base-button>
                        </el-popover>
                      </div>
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="hover"
                        >
                        <div>
                          <span>
                            <div 
                              class="d-flex mb-2" 
                              style="gap: 15px;font-weight: 500;color: #2B2D32"
                            >
                              <span>INFORMAÇÕES</span>
                            </div>
                            <hr>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.allow_approve ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Permitir Aprovação
                              </span>
                            </div>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.transaction_code ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Exige Código de Transação
                              </span>
                            </div>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.display_in_anticipation ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Exibir em Antecipação
                              </span>
                            </div>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.is_manual_invoice_receipt ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Recebimento de Fatura
                              </span>
                            </div>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.is_manual_receivable_confirmation ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Confirmação de Recebíveis
                              </span>
                            </div>
                            <div class="d-flex my-1">
                              <img 
                                :src="item.is_manual_supplier_payment ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" 
                                width="16px"
                                style="margin-right: 2px;"
                              >
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Pagamento de Fornecedor
                              </span>
                            </div>
                          </span>
                        </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <AppIcon icon="info-squared" width="21" color="warning"/>
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0 ml-auto">
                    <AppIcon icon="settings" color="primary" width="32" />
                  </div>
                  <a 
                    @click.prevent="onEditItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a 
                    @click.prevent="onRemoveItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" style="display: flex; align-items: center;">
                <h4 class="new-default-black-font m-0">
                  {{ item.name }}
                </h4>
              </div>
              <div class="col-12" style="display: flex; align-items: center;">
                <div v-if="item.payment_type === 0">
                  Débito
                </div>
                <div v-else>
                  Crédito
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="isListing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits } from "vue";
import paymentMethodStore from "../../store/paymentMethodStore.js";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import { AppIcon, AppSwitch } from "../../../../../components/AppGlobal";
import { PaymentMethodListType } from "../../types/PaymentMethodListPagerType.js";
import { usePaymentMethodHook } from "../../hooks/usePaymentMethodHook.js";
import { date } from "../../../../../helpers";
//#endregion

//#region Emits
/** Eventos emitidos */
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
]);
//#endregion

//#region Data
const { paymentIntermediaries } = usePaymentMethodHook();
//#endregion

//#region Computeds
/** Store Getters */
const listed = computed(() => paymentMethodStore.getters.getListed());
const isListing = computed(() => paymentMethodStore.getters.getIsListing());
//#endregion

//#region Methods
/**
 * Atualizar Status por ID
  * @param {PaymentMethodListType} item
  */
  async function updateStatus(item) {
  await paymentMethodStore.actions.updateStatus(!item.status, item.id);    
};

/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Função que mapeia IDs para nomes
 * @param {number} id array de ids
 * @param {object} intermediaries objeto do relacionamento
 * @returns {string}
 */
function paymentIntermediaryNames(id, intermediaries) {
  if (id.length === 0) {
    return ["Nenhum"];
  }
  const names = id.map(id => {
      const intermediary = intermediaries.find(item => item.id === id);
      return intermediary ? intermediary.name : null;
    })
    .filter(name => name !== null);
    return names;
}
//#endregion
</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 116%;
  margin-left: -11px;
}
/* Estiliza a lista para alinhar os itens à esquerda */
.payment-list {
  margin: 0;
  text-align: left; /* Alinha o texto à esquerda */
  padding-left: 12px; /* Ajusta o espaçamento inicial (opcional) */
  list-style: disc; /* Define um marcador do tipo disco (●) */
}
.payment-list li {
  margin-bottom: 5px; /* Espaço entre os itens da lista */
}

</style>