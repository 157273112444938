<template>
  <div>
    <!-- inserir pesquisa aqui -->
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!travels.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper">
        <!-- Start Card Weighing Loop -->
        <div
          v-if="(travels.length || travels) && !loadingSkeleton"
          v-for="(travel, indexTravel) in travels"
          :key="indexTravel"
          class="col-md-6 col-lg-4 col-xlg-4"
        >
          <card>
            <div class="row mb-3">
              <div class="col-8">
                <!-- Title -->
                <div>
                  <h5 :class="'h3 ' + classSituation[travel.status]">
                    {{ textSituation[travel.status] }}
                    <a
                      v-show="travel.automation_code"
                      href=""
                      @click.prevent="copy(travel.automation_code)"
                      title="Copiar código automação"
                      class="ml-2"
                    >
                      <i class="fa-solid fa-clipboard text-muted" style="font-size: 1.4rem"></i>
                    </a>
                  </h5>
                  <div class="mt-2">
                    <a
                      :disabled="loadingSchedule || loadingSkeleton"
                      href="#"
                      @click.prevent="$emit('handleTravelCycle', travel.uuid, Schedule.address_construction)"
                    >
                      <i class="fa fa-clock fa-2x"
                         :class="loadingSchedule || loadingSkeleton ? 'text-light' : 'text-success'"></i>
                    </a>
                    <a
                      class="px-1"
                      href="#"
                      :style="(!travel.isset_manual_dosage && 'opacity: 25%') || (travel.concrete_left_over.length && 'opacity: 25%')"
                      @click.prevent="handleWaterControl(travel.uuid, indexTravel)"
                    >
                      <i class="fa fa-tint text-info fa-2x"></i>
                      <span
                        v-if="!travel.water_control || emptyWaterControl(travel.water_control)"
                        style="position: relative; margin-left: -5px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white"
                      >
                        <i class="fas fa-exclamation"/>
                      </span>
                    </a>
                    <a
                      class="px-1"
                      href="#"
                      :style="!travel.isset_manual_dosage && 'opacity: 25%'"
                      @click.prevent="handleFreshStateTest(travel.uuid, indexTravel)"
                    >
                      <i class="fa fa-flask text-primary fa-2x"></i>
                      <span
                        v-if="travel.has_empty_fresh_test"
                        style="position: relative; margin-left: -5px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white"
                      >
                        <i class="fas fa-exclamation"/>
                      </span>
                    </a>
                    <a class="px-1" href="#" @click.prevent="showAdjustments(travel)">
                      <i class="fa-solid fa-puzzle-piece fa-2x text-indigo"></i>
                    </a>
                    <a
                      v-if="travel.schedule_travel_cycle_attachment_id"
                      @click.prevent="handleModalScheduleTravelCycleAttchment(travel.cycle.id)"
                      href="#"
                      class="ml-1"
                    >
                      <img class="mt-n2" src="/img/icons/attach-green.png" height="32px"/>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <template v-if="travel.status !== 7">
                    <router-link
                      class="dropdown-item"
                      :to="{
                        path: '/operational/schedule/charging',
                        name: 'operational.schedule.charging.index',
                        params: { travel_uuid: travel.uuid },
                      }"
                    >
                      <i class="fa fa-balance-scale text-primary main-icon-menu"></i>
                      <a href="#" class="text-default">Carregamento</a>
                    </router-link>
                    <div v-if="[1, 6].includes(travel.status)" class="dropdown-divider"/>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      v-if="[1, 6].includes(travel.status)"
                      @click.prevent="$emit('handleConcreteLeftover', travel)"
                    >
                      <i class="fa fa-recycle text-success main-icon-menu"></i>
                      <span class="text-right">Sobra de Concreto</span>
                    </a>
                    <div v-if="travel.status === 1" class="dropdown-divider"/>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      v-if="travel.status === 1"
                      @click.prevent="$emit('handleCancelTravel', travel)"
                    >
                      <i class="far fa-times-circle text-danger main-icon-menu"></i>
                      <span class="text-right">Cancelar</span>
                    </a>
                    <div v-if="travel.status === 4" class="dropdown-divider"/>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      v-if="travel.status === 4"
                      @click.prevent="handleReleaseTravel(travel)"
                    >
                      <i class="fas fa-shipping-fast main-icon-menu"></i>
                      <span class="text-right">Liberar viagem</span>
                    </a>
                    <div v-if="travel.xml && travel.xml.pdf_file_path" class="dropdown-divider"/>
                    <a
                      v-if="travel.xml && travel.xml.pdf_file_path"
                      class="dropdown-item"
                      @click.prevent="downloadNfeDanfe(travel.xml)"
                    >
                      <img class="" width="25px" src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"/>
                      <span class="text-right ml-1">Danfe</span>
                    </a>
                    <div v-if="travel.xml && travel.xml.xml_file_path" class="dropdown-divider"/>
                    <a
                      v-if="travel.xml && travel.xml.xml_file_path"
                      class="dropdown-item"
                      @click.prevent="downloadNfeXml(travel.xml)"
                    >
                      <img class="" width="25px" src="/img/icons/nfe_xml.png"/>
                      <span class="text-right ml-1">XML</span>
                    </a>
                    <div v-if="!travel.mdfe && travel.xml" class="dropdown-divider"/>
                    <a v-if="!travel.mdfe && travel.xml && (travel.xml.status === 6 || travel.xml.status === 1) && travel.can_issue_mdfe"
                      class="dropdown-item"
                      @click.prevent="handleShowModalStoreMDFe(travel)"
                    >
                      <img class="" width="25px" src="/img/icons/nfe_xml.png"/>
                      <span class="text-right ml-1">Emitir MDF-e</span>
                    </a>
                  </template>
                  <div v-if="[0, 7].includes(travel.status)" class="dropdown-divider"/>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    v-if="[0, 7].includes(travel.status)"
                    @click.prevent="setToUnrealized(travel.id)"
                  >
                    <i class="main-icon-menu"
                       :class="travel.status === 0 ? 'fas fa-ban text-danger' : 'fas fa-undo-alt text-success '"></i>
                    <span class="text-right">{{ travel.status === 0 ? "Não realizar" : "Retomar" }}</span>
                  </a>
                  <template
                    v-if="!travel.was_billed && $_operational_permission.can_clear_schedule_travel_mcc && travel.has_mcc">
                    <div v-if="(travel.has_mcc &&
                        travel.automation_status !== 2 &&
                        ![5, 1, 6].includes(travel.status) && ![0, 15].includes(travel.schedule_status)) || $hasPrivilege(1) || travel.status === 5 && ![2, 0].includes(travel.automation_status)"
                         class="dropdown-divider"/>
                    <a
                      @click.prevent="handleClearMcc(travel.uuid)"
                      v-if="(travel.has_mcc &&
                        travel.automation_status !== 2 &&
                        ![5, 1, 6].includes(travel.status) && ![0, 15].includes(travel.schedule_status)) || $hasPrivilege(1) || travel.status === 5 && ![2, 0].includes(travel.automation_status)"
                      class="dropdown-item"
                      href="javascript:void(0)"
                    >
                      <img class="mr-2" width="19" src="/img/icons/icons8/ios/broom_danger.png"
                           alt="broom"/> LIMPAR
                    </a>
                  </template>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <div class="mt-2">
              <div v-if="travel.status !== 1">
                <span class="h3 d-block">{{ travel.load_truck_time ? travel.load_truck_time.slice(-5) : "" }}</span>
                <span class="h4 small d-block">{{ travel.load_truck_time ? travel.load_truck_time.slice(0, 11) : "" }}</span>
              </div>
              <div v-if="travel.status === 1">
                <span v-if="travel.cycle" class="h3 d-block"
                >{{ travel.cycle.start_load_truck_time ? travel.cycle.start_load_truck_time.slice(11, -3) : "" }}
                  até
                  {{ travel.cycle.free_for_travel ? travel.cycle.free_for_travel.slice(11, -3) : "" }}
                </span>
                <span class="h4 small d-block">{{ travel.load_truck_time ? travel.load_truck_time.slice(0, 11) : "" }}</span>
              </div>
              <div class="row pl-3" v-if="travel.send_plant.uuid === travel.return_plant.uuid">
                <i class="fas fa-long-arrow-alt-right text-success">
                  <br/>
                  <i class="fas fa-long-arrow-alt-left text-danger"></i
                  ></i>
                <div class="my-auto">
                  <span class="h4 small d-flex pl-2">
                    {{ travel.send_plant.name }}
                    <span style="font-size: 12px" class="pl-1" v-if="travel.charge_point"> ({{
                        travel.charge_point
                      }})</span>
                  </span>
                </div>
              </div>
              <div v-if="travel.send_plant.uuid !== travel.return_plant.uuid">
                <div class="row pl-3">
                  <i style="margin-top: 0.15rem !important" class="fas fa-long-arrow-alt-right text-success"> </i>
                  <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ travel.send_plant.name }}
                      <span v-if="travel.charge_point"> ({{ travel.charge_point }})</span>
                    </span>
                  </div>
                </div>
                <div class="row pl-3">
                  <i style="margin-top: 0.15rem !important" class="fas fa-long-arrow-alt-left text-danger"> </i>
                  <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ travel.return_plant.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="travel.driver" class="border-3 border-left border-primary mt-1" style="height: 40px">
                <span class="h4 small d-block ml-1">{{ travel.driver }}</span>
                <span class="h4 small d-block ml-1" v-if="travel.equipment"
                >{{ travel.equipment.code }} ( {{ travel.equipment.plate }} )</span
                >
              </div>
            </div>
            <div class="col-12 justify-content-center pr-0 pl-0 text-center mt-2">
              <!--              Indicação reuso-->
              <el-popover v-if="travel.reuse" :title="setTitleReuse(travel.reuse.category_id)" placement="right"
                          class="p-0">
                <div>
                  <h3 class="text-info pl-1">
                    <small class="text-default"
                    ><img style="margin-left: -4px; margin-top: -12px" width="32px" src="/img/icons/os.png"/>
                      <span class="text-default" style="text-transform: none !important"> n° </span>
                    </small>
                    <b class="money">{{ travel.reuse.number }}</b>
                  </h3>
                </div>
                <div style="margin-top: -5px !important">
                  <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> Quantidade:
                    <b>{{ travel.reuse.volume.replace(".", ",") }} </b>m<sup>3</sup></h6>
                </div>
                <div style="margin-top: -5px !important">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <hr class="mt-2 mb-2">
                    </div>
                    <div class="col-2 mt-n3" style="display: flex; align-items: center;">
                       <span style="border: 1px solid #1a70b7;border-radius: 35px;padding: 10px;">
                         <img src="/img/icons/test-tube.png" style="width: 22px;"></span>
                    </div>
                    <div class="col-10">
                      <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> {{ travel.reuse_title }} </h6>
                      <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                        {{ travel.reuse_body }} </h6>
                      <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                        {{ travel.reuse_footer }} </h6>
                    </div>
                  </div>
                </div>
                <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                  <i class="fa-2x fa-solid fa-recycle"
                     :class="travel.reuse.category === 1 ? 'text-success' : 'text-warning'"> </i>
                </base-button>
              </el-popover>
            </div>
            <!-- nota fiscal oculta por enquanto
            <div class="mt-4 d-flex justify-content-between">
              <div class="col-11">
                <span class="h3 d-block">257 <small>(2)</small></span>
                <span class="h3 d-block text-muted small">DANFE</span>
              </div>
              <div class="col-1">
                <i class="fa fa-file-alt text-dark"></i>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-between">
              <div class="col-11">
                <span class="h3 d-block">BT 311</span>
                <span class="h3 d-block text-muted small">
                  Marcelo Ramos
                </span>
              </div>
              <div class="col-1">
                <i class="fa fa-map-marked-alt text-dark"></i>
              </div>
            </div>
            -->
            <div class="row">

              <div class="col p-2" v-if="travel.number">
                <div @click.prevent="downloadProof(travel)" class="pointer new-default-button bg-white"
                     style="width: 100%; opacity: 0.95; border: 1px solid grey;"><img
                  src="/img/icons/os.png" style="width: 22px; height: 22px;">
                  <h6 class="text-truncate h6 mt-2 font-weight-400"
                      style="font-size: 11px; margin-left: 4px;"> n. {{ travel.number }}</h6>
                </div>
              </div>

              <div class="col p-2" v-if="travel.xml">
                <div @click.prevent="downloadDanfe(travel.uuid, travel.xml)" class="pointer new-default-button bg-white"
                     style="width: 100%; opacity: 0.95; border: 1px solid grey;">
                  <img
                    :src="getTextXml(travel.xml).icon" style="width: 17px; height: 19px;">
                  <h6
                    :class="getTextXml(travel.xml).class"
                    class="text-truncate h6 mt-2 font-weight-400"
                    style="font-size: 10px; margin-left: 4px;">
                    <template>
                      {{ getTextXml(travel.xml).title }}
                    </template>
                  </h6>
                </div>
              </div>
              <div class="col p-2" v-if="travel.mdfe">
                <div @click.prevent="downloadMdfe(travel.mdfe)" class="pointer new-default-button bg-white"
                     style="width: 100%; opacity: 0.95; border: 1px solid grey;">
                  <img
                    :src="getTextMDFe(travel.mdfe).icon" style="width: 19px; height: 19px;">
                  <h6
                    :class="getTextMDFe(travel.mdfe).class"
                    class="text-truncate h6 mt-2 font-weight-400"
                    style="font-size: 10px; margin-left: 4px;">
                    <template>
                      {{ getTextMDFe(travel.mdfe).title }}
                    </template>
                  </h6>
                </div>
              </div>

              <div class="col-12">
                <div class="mt-2 d-flex justify-content-center">
                  <div class="text-center">
                    <span class="h2 d-block text-success"
                    >{{ travel.volume }} <span style="text-transform: none">m</span><sup>3</sup>
                      <span class="text-default" style="font-weight: normal">({{ indexTravel + 1 }})</span>
                      <i
                        v-show="enableEdit(travel)"
                        @click.prevent="$emit('handleVolumeEdit', travel)"
                        class="fas fa-edit text-warning ml-2"
                      ></i
                      ></span>
                    <span class="h3 d-block text-primary">
                      {{ travel.accumulated }} <span style="text-transform: none">m</span><sup>3</sup>
                      (Acumulado)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 d-flex justify-content-between" v-if="![1, 6, 7].includes(travel.status)">
              <div class="col-9">
                <a
                  :style="(travel.status === 1 && 'opacity: 25%') || (!travel.hardened_state && 'opacity: 25%')"
                  href="#"
                  @click.prevent="handleHardenedStateTest(travel)"
                >
                  <i class="fas fa-cube fa-2x" style="color: #525f7f"></i>
                </a>
                <span class="h5 text-muted d-block">Ensaios - Estado endurecido</span>
              </div>
              <div class="col-3 pr-2">
                <base-switch
                  size="sm"
                  v-model="travel.hardened_state"
                  @input="setHardenedState(travel)"
                  :disabled="travel.status == 1 || loadingHardenedState || loadingSchedule"
                  type="success"
                  class="success"
                >
                </base-switch>
              </div>
            </div>
            <!--            <collapse class="border rounded p-0 mt-3 mb-3">-->
            <!--              <collapse-item-->
            <!--                name="1"-->
            <!--                class="header-gray"-->
            <!--                back-ground="border-header-danger-bottom"-->
            <!--              >-->
            <!--                <h5 slot="title" class="mb-0 ">Ocorrências</h5>-->
            <!--                <span class="h5 text-muted d-block">-->
            <!--                  Perda de material no carregamento.-->
            <!--                </span>-->
            <!--              </collapse-item>-->
            <!--            </collapse>-->
            <collapse class="border rounded p-0 mt-3 mb-3" v-if="travel.concrete_left_over.length > 0">
              <collapse-item name="1" class="header-gray" back-ground="border-header-danger-bottom">
                <h5 slot="title" class="mb-0">Sobra de Concreto</h5>
                <div :key="index" v-for="(leftOver, index) in travel.concrete_left_over" class="mb-2">
                  <div class="border-left border-3 pl-2"
                       :class="borderLeftOver[`${leftOver.category}${leftOver.category_type}`]">
                    <div class="row">
                      <div class="col-8">
                        <span class="h3 d-block mb-0"> {{ leftOver.volume }} m<sup>3</sup> </span>
                      </div>
                      <div class="col-md-12">
                        <span class="h4 text-muted d-block mb-0">
                          {{ categories[`${leftOver.category}${leftOver.category_type}`] }}
                        </span>
                        <span
                          class="h5 text-muted d-block small"
                          :key="index"
                          v-for="(reason, index) in leftOver.schedule_travel_left_over_reason"
                        >
                          {{ reason.left_over_adjustment.reason }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1 pl-2 pr-2">
                    <div class="col-6 p-1">
                      <base-button
                        @click.prevent="handleEditConcreteLeftOver(travel, leftOver, travel.count_volume_left_over)"
                        size="sm"
                        outline
                        block
                        type="warning"
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                        Editar
                      </base-button>
                    </div>
                    <div class="col-6 p-1">
                      <base-button
                        @click.prevent="
                          handleDeleteConcreteLeftOver(leftOver, {
                            index: indexTravel,
                            index_left_over: index,
                          })
                        "
                        size="sm"
                        outline
                        block
                        type="danger"
                      >
                        <i class="fa-solid fa-trash"></i>Remover
                      </base-button>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <div v-if="travel.additional_exists" @click.once="getAdditionals(travel.uuid)">
              <collapse class="border rounded p-0 mt-3 mb-3">
                <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                  <h5 slot="title" class="mb-0">Adicionais</h5>
                  <div class="row" v-if="loadingAdditionals.includes(travel.uuid)">
                    <div class="col-12">
                      <skeleton-puzl type="text"></skeleton-puzl>
                    </div>
                    <div class="col-8">
                      <skeleton-puzl type="text"></skeleton-puzl>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="travel.additionals && travel.additionals.length">
                      <div v-for="additional in travel.additionals">
                        <div class="row mb-2">
                          <div class="col-md-12">
                            <span class="h4">
                              {{ additional.additional.short_description }}
                              <el-popover trigger="hover" placement="top" class="p-0">
                                <span>{{ additional.justification || "Justificativa não informada" }}</span>
                                <badge v-if="!additional.status" slot="reference" type="danger"> CANCELADO </badge>
                              </el-popover>
                              <br/>
                              <small>{{ additional.reason }}</small>
                            </span>
                          </div>
                          <div class="col-md-12">
                            <span class="h5 small">
                              {{
                                additional.additional.standard_value
                              }} {{ additional.additional.billing_unit_description }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span class="h5 small">Nenhum adicional vinculado.</span>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </div>
          </card>
        </div>
      </div>
      <!-- End Card Weighing -->
      <LoadingPagination :show="loading && !loadingSkeleton" :showOffset="!modal_shown"/>
    </div>
    <ModalHardenedStateTest ref="hardenedStateTest"/>
    <ModalStoreMDFe @update="init" ref="ModalStoreMDFe"/>
  </div>
</template>
<script>
import {base_url_ms} from "@/plugins";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalHardenedStateTest from "./_ModalHardenedStateTest";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalStoreMDFe from '../../../Schedule/Charging/Shared/_ModalStoreMDFe';

export default {
  name: "ListWeighing",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzl,
    ModalHardenedStateTest,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    LoadingPagination,
    ModalStoreMDFe,
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      travels: "travels/fetch",
      Schedule: "schedule/show",
    }),
  },
  data() {
    return {
      format_mixe: period_types,
      show: false,
      modal_shown: false,
      base_url_ms: base_url_ms(),
      loadingHardenedState: false,
      filter: {
        status: null,
      },
      classSituation: {
        0: "text-success-light",
        1: "text-primary",
        2: "text-indigo",
        3: "text-indigo",
        4: "text-warning",
        5: "text-info",
        6: "text-danger",
        7: "text-danger",
        8: "text-warning",
      },
      textSituation: {
        0: "Liberado",
        1: "Concluído",
        2: "Carregando",
        3: "Em edição",
        4: "Aguardando liberação do cliente",
        5: "Em andamento",
        6: "Cancelado",
        7: "Não realizado",
        8: "Pendência",
      },
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
      borderLeftOver: {
        11: "border-success",
        12: "border-primary",
        21: "border-warning",
        22: "border-danger",
      },
      loadingAdditionals: [],
      loadingFreshState: true,
      loadingMoldingLocation: true,
    };
  },
  props: {
    loadingSchedule: Boolean,
    loadingSkeleton: Boolean,
    init: Function,
    downloadProof: Function,
    copy: Function,
    handleReleaseTravel: Function,
    setToUnrealized: Function,
    handleWaterControl: Function,
    emptyWaterControl: Function,
    showAdjustments: Function,
    handleFreshStateTest: Function,
    handleModalScheduleTravelCycleAttchment: Function,
  },
  methods: {
    validateIssueMDFe() {
      return false;
    },
    handleClearMcc(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$Progress.start()
          this.$store
            .dispatch("travels/deleteClearMcc", {uuid: uuid, force: false})
            .then(response => {
              this.$notify({type: 'success', message: response.message})
            })
            .catch(error => {
              this.$notify({type: 'danger', message: error.data.message})
            }).finally(() => {
            this.$Progress.finish();
            loader.hide()
            this.$parent.init()
          });
        }
      }).catch()
    },
    getTextXml(xml) {
      switch (xml.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
        case 1:
          return {
            title: "n. " + xml.number,
            class: "new-default-black-font",
            icon: "/img/nfe.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/nfe.png"
          }
        case 3:
          return {
            title: "Cancelando NF-e",
            class: "text-warning",
            icon: "/img/nfe_in_cancelation_progress.png"
          }
        case 4:
          return {
            title: "n. " + xml.number,
            class: "text-danger",
            icon: "/img/nfe%20cancelada%20icon.svg"
          }
        case 5:
          return {
            title: "Duplicada",
            class: "text-primary",
            icon: "/img/nfe.png"
          }
        case 6:
          return {
            title: "Denegada",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
      }
    },
    getTextMDFe(mdfe) {
      switch (mdfe.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/mdfesefaz.png"
          }
        case 1:
          return {
            title: "n. " + mdfe.number,
            class: "new-default-black-font",
            icon: "/img/mdfesefaz.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
        case 3:
          return {
            title: "n. " + mdfe.number,
            class: "text-success",
            icon: "/img/mdfesefaz.png"
          }
        case 4:
          return {
            title: "n. " + mdfe.number,
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
      }
    },
    downloadMdfe(mdfe) {
      let loader = this.$loading.show();
      const url = this.base_url_ms + 'download-s3?url=' + mdfe.pdf_file_path
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", mdfe.chNFe +'.pdf');
        await link.click()
      });
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
    async downloadDanfe(schedule_travel_uuid, xml) {
      if (xml.status !== 1) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml.key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
    getAdditionals(travel_uuid) {
      this.loadingAdditionals.push(travel_uuid);
      this.$Progress.start();
      let params = {
        filter: {
          schedule_travel_uuid: travel_uuid,
        },
      };
      this.$store
        .dispatch("scheduleAdditional/fetchItems", params)
        .then((response) => {
          this.travels.find((item) => item.uuid === travel_uuid).additionals = response.data;
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, 1);
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, travel_uuid);
          this.$Progress.finish();
          this.$notify({
            type: error.error_type,
            message: error.message,
          });
        });
    },
    setTitleReuse(type) {
      return this.categories[type];
    },
    enableRecoveryForceFinish() {
      if (this.Schedule && (this.Schedule.complete_travels > 0 || this.Schedule.cancelled_travels > 0) && this.Schedule.force_finished) {
        return true;
      }
      return false;
    },
    handleHardenedStateTest(travel) {
      if (travel.status == 1 || !travel.hardened_state) {
        return;
      }
      this.$refs.hardenedStateTest.openModal(travel.uuid, this.Schedule.contract_proposal_formulation, travel.send_plant.id);
    },
    handleEditConcreteLeftOver(travel, leftOver, volumeLimit) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("travelLeftOver/show", leftOver.uuid)
        .then((response) => {
          if (response.data.travel_reference_uuid !== null) {
            loader.hide();
            return this.$notify({type: "warning", message: "Reuso não pode ser editado pois já está sendo utilizado!"});
          }
          leftOver.count_volume_left_over = volumeLimit + parseFloat(leftOver.volume);
          this.$refs.concreteLeftover.editModal(travel, leftOver);
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    handleDeleteConcreteLeftOver(leftOver, params) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
          this.$store
            .dispatch("travelLeftOver/destroy", leftOver.uuid)
            .then((response) => {
              this.$notify({type: response.error_type, message: response.message});
              this.travels[params.index].concrete_left_over.splice(params.index_left_over, 1);
              loader.hide();
            })
            .catch((error) => {
              this.$notify({type: error.data.error_type, message: error.data.message});
              loader.hide();
            });
        }
      });
    },
    toCharging(uuid) {
      this.$router.push(`/operational/schedule/charging/${uuid}`);
    },
    setHardenedState(travel) {
      let params = {
        schedule_travel_uuid: travel.uuid,
        hardened_state: travel.hardened_state,
        features: this.Schedule.contract_proposal_formulation.features,
      };
      this.loadingHardenedState = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("travelConfiguration/storeDefaultHardenedStates", params)
        .then((response) => {
          this.loadingHardenedState = false;
          this.$notify({type: "success", message: response.message});
        })
        .catch(() => (this.loadingHardenedState = false));
    },
    /** Verifica se viagem está apta para edição */
    enableEdit(travel) {
      return (
        travel.status !== 1 &&
        travel.status !== 5 &&
        travel.status !== 6 &&
        travel.status !== 7 &&
        travel.status !== 2 &&
        travel.isset_manual_dosage === false
      );
    },
    /**
     * Download de um arquivo PDF da NFe (Danfe).
     *
     * @param {Object} item - Objeto contendo as informações do item a ser baixado.
     * @param {string} item.pdf_file_path - Caminho do arquivo PDF da NFe (Danfe).
     * @param {string} item.key - Chave da NFe usada como nome do arquivo.
     */
    downloadNfeDanfe(item) {
      let loader = this.$loading.show();
      this.$store
        .dispatch('exports/download', `${this.base_url_ms}download-s3?url='${item.pdf_file_path}`)
        .then((res) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([res], {type: "application/pdf"}));
          link.setAttribute("download", `${item.key}.pdf`);
          link.click();
        })
        .finally(() => loader.hide());
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    },
    handleShowModalStoreMDFe(schedule_travel) {
      this.$refs.ModalStoreMDFe.openModal({
        schedule_travel_id: schedule_travel.id,
        name: schedule_travel.driver,
        document: schedule_travel.driver_document
      })
    },
    /**
     * Download de um arquivo XML da NFe.
     *
     * @param {Object} item - Objeto contendo as informações do item a ser baixado.
     * @param {string} item.xml_file_path - Caminho do arquivo XML da NFe.
     * @param {string} item.key - Chave da NFe usada como nome do arquivo.
     */
    downloadNfeXml(item) {
      let loader = this.$loading.show();
      this.$store.dispatch('exports/download', `${this.base_url_ms}download-s3?url='${item.xml_file_path}`)
        .then(res => {
          let blob = new Blob([res], {type: "application/xml"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${item.key}.xml`);
          link.click();
        })
        .finally(() => loader.hide());
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    },
  },
  async created() {
    this.loadingFreshState = true;
    this.$store.dispatch("testFreshState/fetchItems").then(() => {
      this.loadingFreshState = false;
    });
  },
};
</script>

<style scoped>
.fa-edit {
  cursor: pointer;
}

.text-success-light {
  color: #68b879 !important;
}

.text-strong-warning {
  background-color: #f97300 !important;
  border: #f97300 !important;
}
</style>
