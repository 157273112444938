import { createAxios } from "@/plugins/axios";
import * as types from './mutation_types'
import { destroy, update, cursorPaginate, setUrlWithNextPage, formatResponseData } from '@/store/baseStore'

const endPoint = '/purchase'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
  },
  actions: {
    add({ commit }, params) {
      return createAxios().post(`${endPoint}/purchase-orders`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fetchItemsPaginate({ state }, params) {
      return createAxios().get(`${endPoint}/purchase-orders`, { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: formatResponseData(data).data, next_page: params.page })
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchOrderItems({ commit, state }, id) {
      return createAxios().get(`${endPoint}/purchase-order-items/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({ commit }, params) {
      // Realiza a solicitação
      return createAxios().post(`${endPoint}/purchase-orders/${params.id}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    approval({ commit }, params) {
      return createAxios().put(`${endPoint}/purchase-order-approval/${params.id}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    remove({ commit }, id) {
      return createAxios().delete(`${endPoint}/purchase-entity-budget/${id}`)
        .then(({ data }) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportPdf(_, orderId) {
      const isAsync = 0;
      return createAxios().post(`${endPoint}/purchase-orders/report/${isAsync}/pdf`, { id: orderId })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getHistory({}, id) {
      return createAxios().get(`${endPoint}/purchase_order_history/${id}`)
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
