<template>
  <div>
    <AppFixedPageTitle
      title="Em Aberto"
      icon="/img/icons/icons8/ios/check.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="$_invoices_is_listing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <!-- Esse trecho de filtros será usado no futuro -->
          <!-- <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Vendedor
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.seller_id"
                :items="[]"
                :disableBoxShadow="true"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-12 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Valor da Fatura
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric"
                      v-money="money"
                      v-model.lazy="filter.range_invoice_value.min"
                      placeholder="de"
                    />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric"
                      v-money="money"
                      v-model.lazy="filter.range_invoice_value.max"
                      placeholder="até"
                    />
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.nfse_pending_issue = Number(!filter.nfse_pending_issue)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-danger': filter.nfse_pending_issue}"
              >
                <img
                  height="15"
                  src="/img/icons/icons8/ios/error--v1_danger.png"
                  style="margin-right: 10px;"
                >
                  Avisos
              </button>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.verify_status = Number(!filter.verify_status)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-danger': filter.verify_status}"
              >
                <img
                  height="15"
                  src="/img/icons/icons8/ios/break--v2_danger.png"
                  style="margin-right: 10px;"
                >
                  OBSERVAÇÕES PARA FATURAMENTO
              </button>
            </div>
          </AppSearchBarFilterSection> -->
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            :text="!invoice ? 'Faturar em lote' : 'Canc. Faturamento'"
            :type="!invoice ? 'outline-success' : 'danger'"
            @click.prevent="handleInvoice"
            :icon="!invoice ? '/img/icons/icons8/ios/choose_success.png' : '/img/icons/icons8/ios/choose.png'"
          />
          <AppPageHeaderActionsButton
            text="Avulso"
            type="primary"
            @click.prevent="handleModalSearchContract"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            text="Relatório"
            type="dark"
            @click.prevent="handleShowModalReport"
            icon="/img/icons/icons8/ios/graph-report.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
      :isHideTableOnMobileActive="true"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems"
        variant="text-only"
      />
    </AppTabSelect>
    <ListingManager
      :key="invoiced"
      :listType="listType"
      :contract_proposals="$_invoices_listed.items"
      :loading="$_invoices_is_listing"
      :invoice="invoice"
      :searchBarFilter="searchBarFilter"
      :paymentTerms="paymentTerms"
      :paymentIntermediaries="paymentIntermediaries"
      :paymentMethods="paymentMethods"
      :bankAccounts="bankAccounts"
      :companyPlants="companyPlants"
      :generalSettings="generalSettings"
      :payment_terms_by_method="payment_terms_by_method"
      :payment_intemerdiaries_selected="payment_intemerdiaries_selected"
      :issChangingVerifierClone="issChangingVerifierClone"
      @listItems="listItems"
      @setInvoice="setInvoice"
      @getPaymentTerms="getPaymentTerms"
    />
    <AppViewTrigger v-if="!$_invoices_is_listing" @onIntersected="listItems(true)" />
    <CreateSingle
      :contractProposalId="contract_proposal_id"
      :contractProposalCode="contract_proposal_code"
      :constructionName="construction_name"
      :customerConstructionName="customer_construction_name"
      @fetch="listItems"
      ref="createSingle"
    />
    <MultipleBillingBadge
      v-if="invoice"
      :invoiced="invoiced"
      :contract_proposals="$_invoices_listed.items"
      @handleSubmit="bill"
    />
    <ModalSearchContract @openCreateSingle="showModalCreateSingle" ref="modalSearchContract"/>
    <ModalReport ref="modalReport"/>
  </div>
</template>
<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../../components/AppGlobal';
import invoiceStore from "../../store/invoiceStore";
import { initInvoiceListFilterType, InvoiceListFilterType } from '../../types';
import {date, dialogs, progress, strToNum} from "../../../../../../helpers";
import ListingManager from "./ListingManager.vue";
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract.vue";
import CreateSingle from "@/views/Modules/Financial/Billing/BillingProposal/Shared/_CreateSingle.vue";
import ModalReport from "@/views/Modules/Financial/Allotments/Invoice/Shared/_ModalReport.vue";
import { useInvoiceAllotmentHook } from "@/modules/financial/allotments/invoice/hooks/useInvoiceAllotmentHook";
import { cloneObject } from "@/helpers"
import MultipleBillingBadge from "@/modules/financial/allotments/invoice/views/list/MultipleBillingBadge.vue";
import {useStore} from "@/shared/hooks/useStore";
//#endregion

//#region ComponentRefs
const createSingle = ref(null);
const modalSearchContract = ref(null);
const modalReport = ref(null);
//#endregion

//#region Data
/** type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

const store = useStore();

/** type {InvoiceListFilterType} - Filtro principal */
const filter = reactive(initInvoiceListFilterType());

/** Usado para verificar se card/item estava previamente marcado para faturamento em lote */
const previousContractProposal = ref([]);

/** Usado para verificar se usuário está alterando a regra de ISS padrão */
const issChangingVerifierClone = ref([]);

// Tipo de listagem que será feita
const listType = ref('cards');

// Habilita/Desabilita seleção para faturar em lote
const invoice = ref(false);

// Quantidade de faturas faturadas
const invoiced = ref(0);

// Inicializa a busca por faturas em aberto do dia atual
const currentDate = date.make().format(date.FORMAT.ISO_8601);

const contract_proposal_id = ref(null);
const contract_proposal_code = ref(null);
const construction_name = ref(null);
const customer_construction_name = ref(null);
const payment_terms_by_method = ref([]);
const payment_intemerdiaries_selected = reactive({});

const orderBy = reactive({
  selected: 0,
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [],
    },
    {
      id: 1,
      name: "A-Z",
      selected_name: "A-Z",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "code",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "Z-A",
      selected_name: "Z-A",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "code",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "LANÇAMENTO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "LANÇ. MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "id",
        is_desc: true,
      }],
    },
    {
      id: 4,
      name: "LANÇAMENTO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "LANÇ. MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "id",
        is_desc: false,
      }],
    },
  ]
});
//#endregion

//#region Computeds
/** Store Getters */
const $_invoices_listed = computed(() => invoiceStore.getters.getListed());
const $_invoices_is_listing = computed(() => invoiceStore.getters.getIsListing());

const {
  paymentTerms,
  paymentIntermediaries,
  paymentMethods,
  bankAccounts,
  companyPlants,
  generalSettings
} = useInvoiceAllotmentHook();

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.by_measurement === null,
    },
    {
      id: 0,
      name: 'Diário',
      selected: filter.by_measurement === 0,
    },
    {
      id: 1,
      name: 'Por medição',
      selected: filter.by_measurement === 1,
    },
  ]
});
//#endregion

//#region Methods
function handleInvoice() {
  if (invoice.value) {
    $_invoices_listed.value.items.forEach((item) => {
      item.invoice = false;
    });
    invoiced.value = 0;
    invoice.value = false;
  } else {
    invoice.value = true;
  }
}
function handleShowModalReport() {
  modalReport.value.openModal();
}
/**
 * @param {number} contractProposalId
 * @param {string} contractProposalCode
 * @param {string} constructionName
 * @param {string} customerConstructionName
 */
function showModalCreateSingle(
  contractProposalId,
  contractProposalCode,
  constructionName,
  customerConstructionName
) {
  contract_proposal_id.value = contractProposalId;
  contract_proposal_code.value = contractProposalCode;
  construction_name.value = constructionName;
  customer_construction_name.value = customerConstructionName;

  createSingle.value.openModal();
}
function modifyInvoice(newValue){
  invoice.value = newValue
}
function handleModalSearchContract() {
  modalSearchContract.value.openModal();
}
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  const tabActions = {
    null: () => {
      filter.by_measurement = null;
    },
    0: () => {
      filter.by_measurement = 0;
    },
    1: () => {
      filter.by_measurement = 1;
    }
  };

  if (filter.by_measurement !== item.id) {
    tabActions[item.id]?.();
    listItems();
  }
}

/**
 *
 * @param {Boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilter = withStoreFilters ? invoiceStore.getters.getPageState() : null;
  if (storeFilter) {
    searchBarFilterValue = storeFilter.searchBarFilter;
    filterValue = storeFilter.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}
/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    filter: {
      columns: [],
      values: [],
    },
    range: {
      items: [],
      selected: null,
      start: currentDate,
      end: currentDate,
    },
  }
}

/**
 * Padrão do filtro principal
 * @returns {InvoiceListFilterType}
 */
function defaultFilter() {
  return initInvoiceListFilterType();
}

/**
 * Preparar filtro para modelo esperado pelo endpoint
 */
function prepareFilter() {
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.global = searchBarFilter.custom_search_values;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;

  const selectedItem = orderBy.items[orderBy.selected];
  const selectedFilter = selectedItem.filter.length ? selectedItem.filter[0] : null;

  filter.order_by = selectedFilter
    ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
    : "";
}

async function bill() {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']);
  if (isConfirmed) {
    const loader = progress.showLoader()

    const items = $_invoices_listed.value.items.filter((item) => {
      if (item.invoice === true) {
        item.is_pending = true;
      }
      return item.invoice === true;
    });

    const selecteds = items.map((item) => {
      const cofinsRate = item.cofins_retained_flag && strToNum(item.cofins_rate) > 0
        ? strToNum(item.cofins_rate)
        : 0;
      const csllRate = item.csll_retained_flag && strToNum(item.csll_rate) > 0
        ? strToNum(item.csll_rate)
        : 0;
      const inssRate = item.inss_retained_flag && strToNum(item.inss_rate) > 0
        ? strToNum(item.inss_rate)
        : 0;
      const irRate = item.ir_retained_flag && strToNum(item.ir_rate) > 0
        ? strToNum(item.ir_rate)
        : 0;
      const pisRate = item.pis_retained_flag && strToNum(item.pis_rate) > 0
        ? strToNum(item.pis_rate)
        : 0;

      return {
        id: item.id,
        company_plant_id: item.company_plant_id,
        iss_retain: item.iss_retain,
        cofins_rate: cofinsRate,
        csll_rate: csllRate,
        inss_rate: inssRate,
        ir_rate: irRate,
        pis_rate: pisRate,
        payment_term_id: item.payment_term_id,
        payment_method_id: item.payment_method_id,
        payment_intermediary_id: item.payment_intermediary_id,
        bank_account_id: item.bank_account_id,
        considere_prepayment: item.considere_prepayment,
        use_anticipation_balance: item.use_anticipation_balance,
        company_plant_issuer_id: item.company_plant_issuer_id,
      };
    });

    store.dispatch('billingInvoice/generateInvoices', { items: selecteds, range: searchBarFilter.range })
      .then(async (response) => {
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, response.message)
        invoiced.value = 0;
        loader.hide();
      })
      .catch((error) => {
        const errors = error && error.response && error.response.status === 422
          ? formatErrorValidation(error.response.data.errors)
          : error.data.message;
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
        loader.hide();
      })
      .finally(() => {
        invoice.value = false;
      });
  }
}

function getPaymentTerms(item) {
  if(!paymentMethods || !paymentTerms) return [];

  const payment_terms_splited = item.payment_terms.split(',').map(function (item) {
    return Number(item);
  });

  item.payment_term_id = null;
  item.payment_intermediary_id = null;

  let paymentMethod = paymentMethods.value.find((self) => self.id === item.payment_method_id);

  payment_terms_by_method.value[item.id] = paymentTerms.value.filter(function (selected) {
    let has_method = selected.payment_methods.find((method) => method.uuid === paymentMethod.uuid);
    if (has_method && payment_terms_splited.includes(selected.id)) {
      return selected;
    }
  });

  payment_intemerdiaries_selected[item.id] = paymentMethod.payment_intermediaries;
  item.payment_intermediaries_exists = paymentMethod.payment_intermediaries.length > 0;

  if (payment_terms_by_method.value[item.id].length === 1) {
    item.payment_term_id = payment_terms_by_method.value[item.id][0].id;
  }
  // Força a atualização do componente
}

/**
 *
 * @param {Boolean} isAccumulateItems - Indica se deve acumular itens ou fazer nova pesquisa
 */
async function listItems(isAccumulateItems = false) {
  if(typeof $_invoices_listed.value?.items !== 'undefined') {
    previousContractProposal.value = cloneObject($_invoices_listed.value.items);
  }

  prepareFilter();
  await invoiceStore.actions.list(filter, isAccumulateItems);

  if($_invoices_listed.value.items.length && !$_invoices_is_listing.value) {
    $_invoices_listed.value.items.map(function (item, index) {
      if (! item.payment_methods.includes(',')) {
        item.payment_method_id = Number(item.payment_methods)
      }
      if (! item.payment_terms.includes(',')) {
        item.payment_term_id = Number(item.payment_terms)
      }

      item.invoice = wasItPreviouslyChecked(index);
    });
    $_invoices_listed.value.items.map(function (item) {
      if (item.payment_methods.length === 1) {
        getPaymentTerms(item);
      }
    });
    $_invoices_listed.value.items.map(function (item, index) {
      if (item.payment_term_id) {
        let payment_term = paymentTerms.value.find((payment_term) => payment_term.id === item.payment_term_id)
        if (!item.use_anticipation_balance && payment_term.requires_balance) {
          item.use_anticipation_balance = 1
        }
      }
      // Impostos Federais Padrão
      item.default_cofins_rate = item.cofins_rate;
      item.default_csll_rate = item.csll_rate;
      item.default_inss_rate = item.inss_rate;
      item.default_ir_rate = item.ir_rate;
      item.default_pis_rate = item.pis_rate;
    });
    addOnlyNewItems();
  }
}

function verifyExistence(id) {
  return issChangingVerifierClone.value.some(function (item) {
    return item.id === id;
  });
}

function addOnlyNewItems(){
  $_invoices_listed.value.items.forEach(function (item, index) {
    if (!verifyExistence(item.id)) {
      issChangingVerifierClone.value.push(cloneObject(item));
    }
  });
}

/**
 * Verifica se esse item estava previamente com check
 *
 @param {Number} index - ContractProposal Key
 */
function wasItPreviouslyChecked(index) {
  if(previousContractProposal.value[index]) {
    return previousContractProposal.value[index].invoice;
  }

  return 0;
}

function setInvoice(index, value){
  $_invoices_listed.value.items[index].invoice = !value;

  if (!value) {
    invoiced.value += 1;
  } else {
    invoiced.value -= 1;
  }
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  invoiceStore.mutations.resetStates();
  invoiceStore.mutations.setPageState({ filter, searchBarFilter });
});

const emit = defineEmits([
  "listItems",
  "getPaymentTerms",
  "setInvoice"
]);
//#endregion
</script>
<style scoped>

</style>
