<template>
  <div>
    <base-header class="pb-6 bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">Centrais</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i class="fa fa-home"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#">Configurações</a></li>
              <li class="breadcrumb-item" aria-current="page">Centrais</li>
            </ol>
          </nav>
        </div>
      </div>

      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-2  col-xlg-2 mb-3">
          <base-button :disabled="!$hasPrivilege(1)" block size="lg" type="success" @click="addPlant()">
            <i class="fas fa-plus"></i> NOVO
          </base-button>
        </div>
        <div class="col-md-2  col-xlg-2 mb-3">
          <router-link :to="{ path: '/configuration/general' }">
            <base-button block size="lg" type="light">
              VOLTAR
            </base-button>
          </router-link>
        </div>
      </div>

      <div class="row align-items-center pb-0 mb-3">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0">Empresa: {{ company.business_name }}</h1>
              <small>CNPJ: {{ company.ein }}</small>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter"/>
      <div class="row card-wrapper">
        <div class="col-lg-4" v-for="(itemPlant, index) in $_plants" :key="itemPlant.id">
          <!-- Basic with action button -->
          <card :class="itemPlant.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ itemPlant.name }}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog main-icon-menu"></i>
                  </base-button>
                  <router-link
                    :to="{
                      path: '/configuration/plant/checkpoint/:company_plant_id',
                      name: 'Ponto de Carga',
                      params: { company_plant_id: itemPlant.id }
                    }"
                  >
                    <a class="dropdown-item">
                      <i class="ni ni-building main-icon-menu"></i>
                      Ponto de Carga
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>

                  <a class="dropdown-item" @click="handleCycleSetting(itemPlant)">
                    <i class="ni ni-vector main-icon-menu"></i>
                    Configuração de Ciclo
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleGeneralSettings(itemPlant.uuid)">
                    <i style="margin-right: .9rem!important;" class="fas fa-cogs main-icon-menu"></i>
                    Configurações Gerais
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleShowModalAddIssuerDocument(itemPlant.id)">
                    <i class="ni ni-badge main-icon-menu"></i>
                    CNPJ Emissor
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleModalAddBuyer(itemPlant.id)">
                    <i class="ni ni-badge main-icon-menu"></i>
                    CNPJ Comprador
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleModalAddBankAccount(itemPlant.id)">
                    <i class="ni ni-badge main-icon-menu"></i>
                    Conta Bancária
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleUpdate(itemPlant, index)">
                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0" v-if="$hasPrivilege(1)"></div>
                  <a class="dropdown-item" @click="deletePlant(itemPlant.id)" v-if="$hasPrivilege(1)">
                    <i class="fas fa-times text-danger main-icon-menu"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>

            <!-- Card body -->

            <collapse class="border rounded mt-2" v-if="itemPlant.charge_points != ''">
              <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 ">Pontos de Carga</h5>
                <ul class="list-unstyled" v-for="itemChargePoint in itemPlant.charge_points" :key="itemChargePoint.id">
                  <li>{{ itemChargePoint.name }}</li>
                </ul>
              </collapse-item>
            </collapse>
            <collapse class="border rounded mt-2" v-if="itemPlant.cycle_setting != ''">
              <collapse-item name="1" class="header-gray" back-ground="border-header-danger-bottom">
                <h5 slot="title" class="mb-0 ">Configurações de Ciclo</h5>
                <ul class="list-unstyled">
                  <li>
                    Trajeto (ida):&nbsp;<strong>{{
                      itemPlant.cycle_setting.increse_route_truck_full_send
                    }}&nbsp;%</strong>
                  </li>
                  <li>
                    Trajeto (retorno):&nbsp; <strong>{{ itemPlant.cycle_setting.increse_route_truck_empty_return }}&nbsp;%</strong>
                  </li>
                  <li>
                    Permanência em obra:&nbsp; <strong>{{
                      itemPlant.cycle_setting.time_construction_stay
                    }}&nbsp;min</strong>
                  </li>
                  <li>
                    Intervalo entre lançamentos:&nbsp;<strong>{{
                      itemPlant.cycle_setting.interval_releases
                    }}&nbsp;min</strong>
                  </li>
                  <li>
                    Mistura:&nbsp;<strong>{{ itemPlant.cycle_setting.time_mixing }}&nbsp;min/m<sup>3</sup></strong>
                  </li>
                  <li>
                    Lastro:&nbsp;<strong>{{ itemPlant.cycle_setting.time_clear }}&nbsp;min</strong>
                  </li>
                </ul>
                <div class="form-group row m-0 p-0 border-left border-danger border-3">
                  <label
                    class="col-7 pt-1 pb-1 pl-1 pb-0 col-form-label form-control-label"
                  >
                    Habilitar Preenchimento Automático
                  </label>
                  <div class="col-5 pt-1 pb-1 pr-0 d-flex justify-content-end align-self-center">
                    <base-switch
                      :disabled="storeCycleSettingLoading"
                      @input="storeCycleSetting({... itemPlant.cycle_setting, ...{company_plant_id: itemPlant.id, company_id:itemPlant.company_id}})"
                      type="danger"
                      offText="não"
                      onText="sim"
                      v-model="itemPlant.cycle_setting.automatic_filling">
                    </base-switch>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <collapse class="border rounded mt-2" v-if="itemPlant.company_issuers != ''">
              <collapse-item name="1" class="header-gray" back-ground="border-header-warning-bottom">
                <h5 slot="title" class="mb-0 ">CNPJ Emissor</h5>
                <div class="row" v-for="(itemIssuer, indexIssuer) in itemPlant.company_issuers" :key="itemIssuer.id">
                  <div class="col-md-12 p-1">
                    <small class="font-weight-bold d-flex">{{ itemIssuer.alias_name || itemIssuer.business_name }}
                      <el-popover v-if="itemIssuer.user_name" trigger="click" placement="bottom"
                                  class="p-0 col-1 mr-1">
                        <div>
                          <div class="col-12">
                        <span>
                          <span class="h4">
                            {{ itemIssuer.user_name }}
                          </span>
                          <br>
                          <span>
                            {{ itemIssuer.updated_mixe_at | parseDate }}
                          </span>
                        </span>
                          </div>
                        </div>
                        <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                          <img class="pointer ml-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26px" height="26px" />
                        </div>
                      </el-popover>
                    </small>
                    <small>{{ itemIssuer.ein }}</small>
                  </div>
                  <div class="col-md-12 p-1">
                    <collapse class="border rounded mt-2">
                      <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                        <h5 slot="title" class="mb-0 ">Nota Fiscal Eletrônica</h5>
                        <validation-observer
                          v-slot="{ invalid }"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <label class="label form-control-label">Ocultar o valor total da O.S.</label>
                              <base-input>
                                <base-switch
                                  @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                  v-model="itemIssuer.hide_os" type="danger"/>
                              </base-input>
                            </div>
                            <div class="col-md-12">
                              <label class="label form-control-label">Emissão obrigatória</label>
                              <base-input>
                                <base-switch
                                  @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                  v-model="itemIssuer.required_transmission" type="success"/>
                              </base-input>
                            </div>
                            <div class="col-md-12">
                              <div class="row">
                                <label class="col-md-12 col-form-label form-control-label">Modelo de emissão</label>
                                <div class="col-md-12 mb-1" @click.prevent="setCanSave(itemPlant.id.toString() + itemIssuer.id)">
                                  <base-button style="background: white !important;" class="text-default"
                                               @click.prevent="itemIssuer.type = 1" block outline type="default"><i class="fa-solid fa-laptop-file"></i> Modelo 1
                                    <i v-if="itemIssuer.type === 1" class="fa-regular fa-circle-check"></i>
                                    <i v-else class="fa-regular fa-circle"></i>
                                  </base-button>
                                </div>
                                <div class="col-md-12" @click.prevent="setCanSave(itemPlant.id.toString() + itemIssuer.id)">
                                  <base-button style="background: white !important;" class="text-default"
                                               @click.prevent="itemIssuer.type = 2" block outline type="default"><i class="fa-solid fa-laptop-file"></i> Modelo 2
                                    <i v-if="itemIssuer.type === 2" class="fa-regular fa-circle-check"></i>
                                    <i v-else class="fa-regular fa-circle"></i>
                                  </base-button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label class="col-form-label form-control-label">Ambiente de Emissão</label>
                              <validation-provider rules="required">
                                <select @change="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                        class="form-control form-control-sm" v-model="itemIssuer.environment">
                                  <option value="0">Homologação</option>
                                  <option value="1">Produção</option>
                                </select>
                              </validation-provider>
                            </div>
                            <div class="col-xl-6">
                              <label class="col-form-label form-control-label">Série</label>
                              <validation-provider rules="required">
                                <base-input @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                            v-mask="'######'"
                                            input-classes="form-control-sm" autocomplete="off"
                                            v-model="itemIssuer.series"/>
                              </validation-provider>
                            </div>
                            <div class="col-xl-6">
                              <label class="col-form-label form-control-label">Número
                                <el-popover placement="right" class="p-0">
                                  <span>
                                    Número sequencial que será utilizado para emissão da Nota fiscal.
                                  </span>
                                  <base-button
                                    outiline
                                    slot="reference"
                                    size="sm"
                                    type="secundary"
                                    class="p-0 m-0 btn-rounded"
                                  >
                                    <i class="fas fa-info-circle"></i>
                                  </base-button>
                                </el-popover>
                              </label>
                              <validation-provider rules="required">
                                <base-input @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                            v-mask="'##################'" input-classes="form-control-sm"
                                            autocomplete="off"
                                            v-model="itemIssuer.number"/>
                              </validation-provider>
                            </div>
                            <div class="col-md-12 float-right text-right mt-3">
                              <base-button @click="setOldItemIssuer(
                            itemPlant.id.toString() + itemIssuer.id,
                           itemIssuer
                            )" v-if="canSaveItemIssuer.includes(itemPlant.id.toString() + itemIssuer.id)" type="light">
                                Cancelar
                              </base-button>
                              <base-button
                                :disabled="invalid"
                                @click.prevent="setEnvironment(itemIssuer, itemPlant.id.toString() + itemIssuer.id)"
                                v-if="canSaveItemIssuer.includes(itemPlant.id.toString() + itemIssuer.id)"
                                type="success">
                                Salvar
                              </base-button>
                            </div>
                          </div>
                        </validation-observer>
                      </collapse-item>
                    </collapse>
                  </div>
                  <div class="col-md-12 p-1">
                    <collapse class="border rounded mt-2">
                      <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                        <h5 slot="title" class="mb-0 ">MDF-e</h5>
                        <validation-observer
                          v-slot="{ invalid }"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <label class="col-form-label form-control-label">Ambiente de Emissão</label>
                              <validation-provider rules="required">
                                <select @change="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                        class="form-control form-control-sm" v-model="itemIssuer.mdfe_environment">
                                  <option value="0">Homologação</option>
                                  <option value="1">Produção</option>
                                </select>
                              </validation-provider>
                            </div>
                            <div class="col-xl-6">
                              <label class="col-form-label form-control-label">Série</label>
                              <validation-provider rules="required">
                                <base-input @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                            v-mask="'######'"
                                            input-classes="form-control-sm" autocomplete="off"
                                            v-model="itemIssuer.mdfe_series"/>
                              </validation-provider>
                            </div>
                            <div class="col-xl-6">
                              <label class="col-form-label form-control-label">Número
                                <el-popover placement="right" class="p-0">
                                  <span>
                                    Número sequencial que será utilizado para emissão do Manifesto.
                                  </span>
                                  <base-button
                                    outiline
                                    slot="reference"
                                    size="sm"
                                    type="secundary"
                                    class="p-0 m-0 btn-rounded"
                                  >
                                    <i class="fas fa-info-circle"></i>
                                  </base-button>
                                </el-popover>
                              </label>
                              <validation-provider rules="required">
                                <base-input @input="setCanSave(itemPlant.id.toString() + itemIssuer.id)"
                                            v-mask="'##################'" input-classes="form-control-sm"
                                            autocomplete="off"
                                            v-model="itemIssuer.mdfe_number"/>
                              </validation-provider>
                            </div>
                            <div class="col-md-12 float-right text-right mt-3">
                              <base-button @click="setOldItemIssuer(
                            itemPlant.id.toString() + itemIssuer.id,
                           itemIssuer
                            )" v-if="canSaveItemIssuer.includes(itemPlant.id.toString() + itemIssuer.id)" type="light">
                                Cancelar
                              </base-button>
                              <base-button
                                :disabled="invalid"
                                @click.prevent="setEnvironment(itemIssuer, itemPlant.id.toString() + itemIssuer.id)"
                                v-if="canSaveItemIssuer.includes(itemPlant.id.toString() + itemIssuer.id)"
                                type="success">
                                Salvar
                              </base-button>
                            </div>
                          </div>
                        </validation-observer>
                      </collapse-item>
                    </collapse>
                  </div>
                  <div class="col-md-8 p-1">
                    <base-button
                      block
                      size="sm"
                      outline
                      @click.prevent="!itemIssuer.default && setDefaultItem(itemIssuer.id, itemPlant.id)"
                      :class="itemIssuer.default ? 'active' : ''"
                      type="primary"
                    ><i class="fas fa-user-check"></i>Padrão
                    </base-button
                    >
                  </div>
                  <div class="col-md-4 p-1">
                    <base-button size="sm" block type="danger"
                                 @click.prevent="removeItemIssuer(itemIssuer.id, itemPlant.id, indexIssuer)">
                      <i class="mt-1 fas text-center fa-trash"></i>
                    </base-button>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <div @click.prevent="getCompanyPlantBuyers(itemPlant)">
              <collapse class="border rounded mt-2">
                <collapse-item name="1" class="header-gray" back-ground="border-header-success-bottom">
                  <h5 slot="title" class="mb-0 ">CNPJ Comprador</h5>
                  <SkeletonPuzlFullWidth v-show="loadingCompanyBuyer && !itemPlant.company_buyers"/>
                  <div class="row" v-for="(buyer, indexBuyer) in itemPlant.company_buyers" :key="indexBuyer">
                    <div class="col-md-12 p-1">
                      <ul class="list-unstyled">
                        <li>
                          <small class="font-weight-bold">{{
                              buyer.issuer.alias_name || buyer.issuer.business_name
                            }}</small> <br/>
                          <small>{{ buyer.issuer.ein }}</small>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-8 p-1">
                      <base-button
                        block
                        size="sm"
                        outline
                        :disabled="loadingCompanyBuyer"
                        @click.prevent="setDefaultBuyer(itemPlant, buyer.id)"
                        :class="buyer.default === 1 ? 'active' : ''"
                        type="primary"
                      ><i class="fas fa-user-check"></i>Padrão
                      </base-button
                      >
                    </div>
                    <div class="col-md-4 p-1">
                      <base-button size="sm" block type="danger"
                                   @click.prevent="handleDeleteCompanyPlantBuyer(itemPlant, buyer.id)">
                        <i class="mt-1 fas text-center fa-trash"></i>
                      </base-button>
                    </div>
                  </div>
                  <div v-show="itemPlant.company_buyers  && !itemPlant.company_buyers.length">
                    <h5 class="m-0">Não há CNPJ comprador.</h5>
                  </div>
                </collapse-item>
              </collapse>
            </div>
            <div @click.prevent="getCompanyPlantBankAccounts(itemPlant)">
              <collapse class="border rounded mt-2">
                <collapse-item name="1" class="header-gray" back-ground="border-header-indigo-bottom">
                  <h5 slot="title" class="mb-0 ">Contas</h5>
                  <SkeletonPuzlFullWidth v-show="loadingBankAccount && !itemPlant.bank_accounts"/>
                  <div class="row mb-2" v-for="(bank_account, indexBankAccount) in itemPlant.bank_accounts"
                       :key="indexBankAccount">
                    <div class="col-md-12 p-1">
                      <ul class="list-unstyled mb-1">
                        <li>
                          <small class="font-weight-bold">
                            {{ bank_account.bank_account.name }}
                          </small>
                          <br/>
                          <small class="font-weight-normal m-0">{{ bank_account.bank_account.bank.code }} |
                            {{ bank_account.bank_account.bank.name }}</small>
                          <br/>
                          <small class="font-weight-normal m-0">Ag. {{
                              bank_account.bank_account.number_without_digit_agency
                            }}-{{ bank_account.bank_account.digit_agency }}</small>
                          <br/>
                          <small class="font-weight-normal m-0">
                            C/{{ bank_account.bank_account.account_type }}: {{
                              bank_account.bank_account.number_without_digit_account
                            }}-{{ bank_account.bank_account.digit_account }}
                          </small>
                          <br/>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-8 p-1">
                      <base-button
                        block
                        size="sm"
                        outline
                        :disabled="loadingBankAccount"
                        @click.prevent="setDefaultBankAccount(itemPlant, bank_account.id)"
                        :class="bank_account.default === 1 ? 'active' : ''"
                        type="primary"
                      ><i class="fas fa-user-check"></i>Padrão
                      </base-button
                      >
                    </div>
                    <div class="col-md-4 p-1">
                      <base-button size="sm" block type="danger"
                                   @click.prevent="handleDeleteCompanyPlantBankAccount(itemPlant, bank_account.id)">
                        <i class="mt-1 fas text-center fa-trash"></i>
                      </base-button>
                    </div>
                  </div>
                  <div v-show="itemPlant.bank_accounts  && !itemPlant.bank_accounts.length">
                    <h5 class="m-0">Não há contas.</h5>
                  </div>
                </collapse-item>
              </collapse>
            </div>
          </card>
        </div>
      </div>

      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingSkeleton" :key="index"></SkeletonPuzlGrid>
      </div>
      <div class="row align-items-center" v-if="$_plants && !$_plants.length && !loadingSkeleton">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem centrais cadastradas!
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(storePlant)">
            <div>
              <breadcrumb list-classes="bg-success border-header-success-bottom">
                Identificação
              </breadcrumb>

              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 col-form-label form-control-label">Status</label>
                  <div class="col-md-9 pt-2">
                    <base-switch v-model="plant.status" type="success" offText="inativo" onText="ativo"
                                 class="success"></base-switch>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 pb-0 mb-1 col-form-label form-control-label">Nome<span class="text-danger">&nbsp;*</span></label>
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" autocomplete="off" v-model="plant.name"/>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 pb-0 mb-1 col-form-label form-control-label">Nome abreviado<span class="text-danger">&nbsp;*</span></label>
                  <div class="col-md-9">
                    <validation-provider rules="required|min:2|max:9" v-slot="{ errors }">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        v-model="plant.short_name"
                        maxlength="9"
                      />
                      <small v-if="errors.length">
                        <i class="fas fa-exclamation-triangle text-danger mr-2"/>
                        <strong style="color: #7e7e7e">O campo deve ter no mínimo 2 caracteres!</strong>
                      </small>
                    </validation-provider>
                  </div>
                </div>

                <breadcrumb
                  list-classes="bg-success border-header-success-bottom mt-4"
                >
                  Integração
                </breadcrumb>
                <!--                <div class="form-group row m-0 p-0">-->
                <!--                  <label class="col-md-3 pb-2 mb-1 col-form-label form-control-label"-->
                <!--                    >Logotipo<span class="text-danger">&nbsp;*</span></label-->
                <!--                  >-->
                <!--                  <div class="col-md-9">-->
                <!--                    <file-input-->
                <!--                      ref="logo"-->
                <!--                      v-on:change="fileChange()"-->
                <!--                      class="form-control-sm"-->
                <!--                      initialLabel="Logomarca"-->
                <!--                      placeholder="logomarca"-->
                <!--                    ></file-input>-->
                <!--                  </div>-->
                <!--                </div>-->

                <breadcrumb list-classes="bg-success border-header-warning-bottom mt-4">
                  Endereço
                </breadcrumb>
                <div class="pl-3 pr-3">
                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Cep<span class="text-danger">&nbsp;*</span></label>
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="plant.postal_code"
                          v-mask="['#####-###']"
                          v-on:blur="getAddressByCode"
                        />
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Uf<span class="text-danger">&nbsp;*</span></label>
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select v-model="plant.state" class="form-control form-control-sm"
                                  v-on:change="getCities()">
                            <option v-for="state in states" :key="state.id" :value="state.letter">{{
                                state.letter
                              }}
                            </option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Endereço<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-10">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm" autocomplete="off" v-model="plant.address"/>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Número<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm" autocomplete="off" v-model="plant.number"/>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Complemento</label>
                    <div class="col-md-4">
                      <base-input input-classes="form-control-sm" autocomplete="off" v-model="plant.complement"/>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Bairro<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm" autocomplete="off" v-model="plant.district"/>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Município<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select v-model="plant.city" class="form-control form-control-sm">
                            <option v-for="city in cities" :key="city.id" :value="city.title">{{ city.title }}</option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">Cancelar</base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="storePlantLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

    <modal :show.sync="modal.update" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(updatePlant)">
            <div>
              <breadcrumb list-classes="bg-success border-header-success-bottom">
                Identificação
              </breadcrumb>

              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 col-form-label form-control-label">Status</label>
                  <div class="col-md-9 pt-2">
                    <base-switch v-model="editPlant.status" type="success" offText="inativo" onText="ativo"
                                 class="success"></base-switch>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 pb-0 mb-1 col-form-label form-control-label">Nome<span class="text-danger">&nbsp;*</span></label>
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="editPlant.name"/>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 pb-0 mb-1 col-form-label form-control-label">Nome abreviado<span class="text-danger">&nbsp;*</span></label>
                  <div class="col-md-9">
                    <validation-provider rules="required|min:2|max:9" v-slot="{ errors }">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        v-model="editPlant.short_name"
                        maxlength="9"
                      />
                      <small v-if="errors.length">
                        <i class="fas fa-exclamation-triangle text-danger mr-2"/>
                        <strong style="color: #7e7e7e">O campo deve ter no mínimo 2 caracteres!</strong>
                      </small>
                    </validation-provider>
                  </div>
                </div>

                <breadcrumb
                  list-classes="bg-success border-header-success-bottom mt-4"
                >
                  Integração
                </breadcrumb>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Código externo </label
                  >
                  <div class="col-md-9">
                    <base-input input-classes="form-control-sm">
                      <input
                        :disabled="loadingExternalCode"
                        type="text"
                        v-model="editPlant.external_code"
                        autocomplete="off"
                             class="form-control form-control-sm"
                      />
                    </base-input>
                  </div>
                </div>

                <!--                <div class="form-group row m-0 p-0">-->
                <!--                  <label class="col-md-3 pb-2 mb-1 col-form-label form-control-label"-->
                <!--                    >Logotipo<span class="text-danger">&nbsp;*</span></label-->
                <!--                  >-->
                <!--                  <div class="col-md-9">-->
                <!--                    <file-input-->
                <!--                      ref="editPlantLogo"-->
                <!--                      v-on:change="editFileChange()"-->
                <!--                      class="form-control-sm"-->
                <!--                      initialLabel="editLogomarca"-->
                <!--                      placeholder="logomarca"-->
                <!--                    ></file-input>-->
                <!--                  </div>-->
                <!--                </div>-->

                <breadcrumb list-classes="bg-success border-header-warning-bottom mt-4">
                  Endereço
                </breadcrumb>
                <div class="pl-3 pr-3">
                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Cep<span class="text-danger">&nbsp;*</span></label>
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="editPlant.postal_code"
                          v-mask="['#####-###']"
                          @blur="getAddressByCodeEdit"/>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Uf<span class="text-danger">&nbsp;*</span></label>
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select v-model="editPlant.state" class="form-control form-control-sm"
                                  v-on:change="getCities()">
                            <option v-for="state in states" :key="state.id" :value="state.letter">{{
                                state.letter
                              }}
                            </option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Endereço<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-10">
                      <validation-provider rules="required">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="editPlant.address"/>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Número<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="editPlant.number"/>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">Complemento</label>
                    <div class="col-md-4">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        autocomplete="off"
                        v-model="editPlant.complement"/>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Bairro<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="editPlant.district"/>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Município<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select v-model="editPlant.city" class="form-control form-control-sm">
                            <option v-for="city in cities" :key="city.id" :value="city.title">{{ city.title }}</option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('update')">Cancelar</base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="storePlantLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

    <modal :show.sync="modal.createCycleSetting" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(storeCycleSetting)">
            <div>
              <breadcrumb list-classes="bg-success border-header-success-bottom">
                Parâmetros
              </breadcrumb>

              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">Acréscimo no trajeto - BT
                    Carregada (ida)</label>
                  <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="cycleSetting.increse_route_truck_full_send"
                        v-mask="['###']"
                        class="form-control form-control-sm"
                        input-group-classes="input-group-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">%</small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">Acréscimo no trajeto - Retorno BT
                    (volta)</label>
                  <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="cycleSetting.increse_route_truck_empty_return"
                        v-mask="['###']"
                        class="form-control form-control-sm"
                        input-group-classes="input-group-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">%</small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >Tempo de permanência em obra (padrão)<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="cycleSetting.time_construction_stay"
                          v-mask="['###']"
                          class="form-control form-control-sm"
                          input-group-classes="input-group-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">minutos</small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >Intervalo entre lançamentos (padrão)<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="cycleSetting.interval_releases"
                          v-mask="['###']"
                          class="form-control form-control-sm"
                          input-group-classes="input-group-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">minutos</small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">Tempo de mistura/dosagem (carga
                    pronta)</label>
                  <div class="col-md-7">
                    <validation-provider>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="cycleSetting.time_mixing"
                          v-mask="['###']"
                          class="form-control form-control-sm"
                          input-group-classes="input-group-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">min/m<sup>3</sup></small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Tempo de limpeza/lastro
                  </label>
                  <div class="col-md-7">
                    <validation-provider>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="cycleSetting.time_clear"
                          v-mask="['###']"
                          class="form-control form-control-sm"
                          input-group-classes="input-group-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">minutos</small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('createCycleSetting')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid"
                           :loading="storeCycleSettingLoading"
              >Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalGeneralSettings ref="generalSettings"/>
    <ModalAddBuyer @addedBuyer="registerBuyer" ref="modalAddBuyer"/>
    <ModalAddBankAccount @addedBankAccount="registerBankAccount" ref="modalAddBankAccount"/>
    <ModalAddIssuerDocument
      ref="addIssuerDocument"
      @addIssuerToPlant="addIssuerToPlant"
      @close="showModalAddIssuerDocument = false"
      :show="showModalAddIssuerDocument"
    />
    <LoadingPagination :show="loading && !loadingSkeleton"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import BaseHeader from "@/components/BaseHeader";
import {mask} from "vue-the-mask";
import ModalGeneralSettings from "./Shared/_ModalGeneralSettings";
import ModalAddIssuerDocument from "./Shared/_ModalAddIssuerDocument";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalAddBuyer from "./Shared/_ModalAddBuyer";
import ModalAddBankAccount from "./Shared/_ModalAddBankAccount";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import {viacep} from "@/helpers";
// Sap
import sapIntegrationStore from "../../../../modules/sap/sap-integration/store";
import { hasSap } from "../../../../modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "../../../../modules/sap/sap-integration/types";
import { SapTypeEnum } from "../../../../modules/sap/shared/enums/SapTypeEnum";

export default {
  mixins: [cursorPaginate],
  components: {
    BaseHeader,
    ModalGeneralSettings,
    ModalAddIssuerDocument,
    SkeletonPuzlFullWidth,
    SkeletonPuzlGrid,
    ModalAddBuyer,
    ModalAddBankAccount,
    MultiFilter,
    LoadingPagination,
    viacep,
  },
  directives: {mask},
  data() {
    return {
      showModalAddIssuerDocument: false,
      modal: {
        title: "Adicionar Central",
        create: false,
        update: false,
        createCycleSetting: false,
        createIssuer: false,
        createCompanyPlantIssuer: false
      },
      canSaveItemIssuer: [],
      route: {
        company_id: 0
      },
      validated: false,
      loadingCompanyBuyer: false,
      company: {},
      companyPlantIssuer: {},
      plant: {},
      indexPlant: null,
      cycleSetting: {
        company_id: "",
        company_plant_id: "",
        increse_route_truck_full_send: "",
        increse_route_truck_empty_return: "",
        time_construction_stay: "",
        interval_releases: "",
        time_mixing: "",
        time_clear: ""
      },
      editPlant: {},
      plants: {},
      state: "",
      states: [],
      cities: {},
      storePlantLoading: false,
      storeCycleSettingLoading: false,
      storeCompanyPlantIssuerLoading: false,
      deleteCompanyPlantIssuerLoading: false,
      loadingBankAccount: false,
      logo: [],
      loadingSkeleton: false,
      filter: {},
      hasSap: hasSap(),
      SapTypeEnum: SapTypeEnum,
      loadingExternalCode: false,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/fetch",
    }),
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false;
    },
    openModal(name) {
      this.modal[name] = true;
    },
    setEnvironment(itemIssuer, id) {
      let loader = this.$loading.show()
      this.$store
        .dispatch("plant/postSetNfeEnvironment", {
          has_issuer_id: itemIssuer.has_issuer_id,
          environment: itemIssuer.environment,
          required_transmission: itemIssuer.required_transmission,
          hide_os: itemIssuer.hide_os,
          series: itemIssuer.series,
          type: itemIssuer.type,
          number: itemIssuer.number,
          mdfe_number: itemIssuer.mdfe_number,
          mdfe_series: itemIssuer.mdfe_series,
          mdfe_environment: itemIssuer.mdfe_environment,
          company_plant_issuer_id: itemIssuer.id,
        }).then(response => {
        loader.hide()
        this.$notify({type: "success", message: response.message});
        const index = this.canSaveItemIssuer.findIndex((item) => item === id)
        this.canSaveItemIssuer.splice(index, 1)
      })
        .catch(error => {
          loader.hide()
          this.$notify({type: "danger", message: error.data.message});
        })
    },
    setCanSave(id) {
      if (!this.canSaveItemIssuer.includes(id)) {
        this.canSaveItemIssuer.push(id)
      }
    },
    setOldItemIssuer(id, item_issuer) {
      const index = this.canSaveItemIssuer.findIndex((item) => item === id)
      this.canSaveItemIssuer.splice(index, 1)
      item_issuer.environment = item_issuer.old_nfe.environment
      item_issuer.series = item_issuer.old_nfe.series
      item_issuer.number = item_issuer.old_nfe.number
    },
    addIssuerToPlant(params) {
      const indexPlant = this.$_plants.findIndex(item => item.id == params.company_plant_id);
      if (this.$_plants[indexPlant].company_issuers.length === 0) {
        params.item_issuer.default = 1
      }
      this.$_plants[indexPlant].company_issuers.push(params.item_issuer);
    },
    addPlant() {
      this.plant = {
        company_id: this.route.company_id,
        status: true,
        logo: "",
        postal_code: "",
        state: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: ""
      };
      this.openModal("create");
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/ein", this.companyPlantIssuer)
        .then(response => {
          this.companyPlantIssuer.ein = response.data.data.cnpj || "";
          this.companyPlantIssuer.business_name = response.data.data.nome || "";
          this.companyPlantIssuer.alias_name = response.data.data.fantasia || "";
          this.companyPlantIssuer.email = response.data.data.email || "";
          this.companyPlantIssuer.phone = response.data.data.telefone || "";
          this.companyPlantIssuer.registration_situation = response.data.data.situacao || "";
          this.companyPlantIssuer.postal_code = response.data.data.cep.replace(/[^\d]+/g, "") || "";
          this.getAddressIssuerByCode();
          this.$notify({type: "success", message: "Dados da empresa carregado com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    setDefaultItem(itemIssuerId, itemPlantId) {
      let params = {
        company_plant_id: itemPlantId,
        issuer_id: itemIssuerId
      };
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("plant/postSetDefaultItemIssuer", params)
        .then(response => {
          this.$Progress.finish();
          loader.hide();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          const indexPlant = this.$_plants.findIndex(item => item.id == itemPlantId);
          this.$_plants[indexPlant].company_issuers = [...response.data];
        })
        .catch(() => {
          loader.hide();
          this.$Progress.finish();
        });
    },
    getAddressIssuerByCode() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/address", {postal_code: this.companyPlantIssuer.postal_code})
        .then(response => {
          this.companyPlantIssuer.state = response.data.data.uf;
          this.getCities(this.companyPlantIssuer.state);
          this.companyPlantIssuer.address = response.data.data.logradouro;
          this.companyPlantIssuer.state = response.data.data.uf;
          this.companyPlantIssuer.district = response.data.data.bairro;
          this.companyPlantIssuer.city = response.data.data.localidade;
          this.$notify({type: "success", message: "Dados carregado com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.plant.postal_code.replace(/[^\d]+/g, '');
      if (postal_code) {
        viacep(this.plant.postal_code).then((response) => {
          this.plant.state = response.data.uf;
          this.getCities();
          this.plant.address = response.data.logradouro;
          this.plant.state = response.data.uf;
          this.plant.district = response.data.bairro;
          this.plant.city = response.data.localidade;
          this.$notify({type: "success", message: "Dados carregado com sucesso."});
          this.$Progress.finish();
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    getAddressByCodeEdit() {
      this.$Progress.start();
      let postal_code = this.editPlant.postal_code.replace(/[^\d]+/g, '');
      if (postal_code) {
        viacep(this.editPlant.postal_code).then((response) => {
          this.editPlant.state = response.data.uf;
          this.getCities();
          this.editPlant.address = response.data.logradouro;
          this.editPlant.state = response.data.uf;
          this.editPlant.district = response.data.bairro;
          this.editPlant.city = response.data.localidade;
          this.$notify({type: "success", message: "Dados carregado com sucesso."});
          this.$Progress.finish();
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    getStates() {
      this.$Progress.start();
      this.$axios
        .get("/state")
        .then(response => {
          this.states = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getCompany() {
      this.$Progress.start();
      this.$axios
        .get("/company/" + this.route.company_id)
        .then(response => {
          this.company = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      let company = this.$route.params.company_id;
      this.$store
        .dispatch("plant/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
          company: company,
        })
        .then(response => {
          this.$Progress.finish();
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.$Progress.finish();
        });
    },
    getCities() {
      this.$Progress.start();

      if (this.plant.state != undefined) {
        this.state = this.plant.state;
      } else if (this.editPlant.state != undefined) {
        this.state = this.editPlant.state;
      } else if (this.companyPlantIssuer.state != undefined) {
        this.state = this.companyPlantIssuer.state;
      }

      this.$axios
        .post("/city", {uf: this.state})
        .then(response => {
          this.cities = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    fileChange() {
      this.plant.logo = this.$refs.logo.files[0];
    },
    editFileChange() {
      this.editPlant.logo = this.$refs.editPlantLogo.files[0];
    },
    handleCycleSetting(row) {
      this.cycleSetting.company_plant_id = row.id;
      this.cycleSetting.company_id = row.company_id;

      if (row.cycle_setting != undefined) {
        this.cycleSetting.increse_route_truck_full_send = row.cycle_setting.increse_route_truck_full_send;
        this.cycleSetting.increse_route_truck_empty_return = row.cycle_setting.increse_route_truck_empty_return;
        this.cycleSetting.time_construction_stay = row.cycle_setting.time_construction_stay;
        this.cycleSetting.interval_releases = row.cycle_setting.interval_releases;
        this.cycleSetting.time_mixing = row.cycle_setting.time_mixing;
        this.cycleSetting.time_clear = row.cycle_setting.time_clear;
      }
      this.modal.title = "Configuração de Ciclo";
      this.openModal("createCycleSetting");
    },
    handleGeneralSettings(uuid) {
      this.$refs.generalSettings.openModal(uuid);
    },
    handleCompanyPlantIssuer(row) {
      this.companyPlantIssuer = {
        company_plant_id: row.id,
        company_id: row.company_id,
        status: true,
        ein: "",
        business_name: "",
        alias_name: "",
        email: "",
        phone: "",
        registration_situation: "",
        tax_regime: "",
        municipal_registration: "",
        contributing_number: "",
        state_registration: "",
        suframa_number: "",
        postal_code: "",
        state: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: ""
      };
      this.modal.title = "Adicionar Emitente";
      this.openModal("createCompanyPlantIssuer");
    },
    handleUpdate(row, index) {
      this.indexPlant = index;
      this.editPlant.id = row.id;
      this.editPlant.company_id = row.company_id;
      this.editPlant.status = row.status;
      this.editPlant.name = row.name;
      this.editPlant.short_name = row.short_name.toUpperCase();
      this.editPlant.requires_municipal_building_code = row.requires_municipal_building_code;
      this.editPlant.postal_code = row.address_plant.postal_code;
      this.editPlant.state = row.address_plant.state;
      this.getCities(row.address_plant.state);
      this.editPlant.address = row.address_plant.address;
      this.editPlant.number = row.address_plant.number;
      this.editPlant.complement = row.address_plant.complement;
      this.editPlant.district = row.address_plant.district;
      this.editPlant.city = row.address_plant.city;
      this.editPlant.external_code = row.external_code;
      this.modal.title = "Editar Central";
      this.openModal("update");
    },
    async storeCompanyPlantIssuer() {
      this.$Progress.start();
      this.storeCompanyPlantIssuerLoading = true;
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/plantissuer", this.companyPlantIssuer)
        .then(response => {
          this.$notify({type: response.data.error_type, message: response.data.message});
          this.storeCycleSettingLoading = false;
          this.companyPlantIssuer = {};
          this.getCompany();
          this.init();
          this.$Progress.finish();
          this.closeModal("createCompanyPlantIssuer");
        })
        .catch(error => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.storeCompanyPlantIssuerLoading = false;
        });
    },
    async storeCycleSetting(cycleSetting = false) {
      this.$Progress.start();
      this.storeCycleSettingLoading = true;
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/cyclesetting", cycleSetting ? cycleSetting :this.cycleSetting)
        .then(response => {
          this.$notify({type: response.data.error_type, message: response.data.message});
          this.storeCycleSettingLoading = false;
          this.cycleSetting = {
            company_id: "",
            company_plant_id: "",
            increse_route_truck_full_send: "",
            increse_route_truck_empty_return: "",
            time_construction_stay: "",
            interval_releases: "",
            time_mixing: "",
            time_clear: ""
          }
          this.getCompany();
          this.init();
          this.$Progress.finish();
          this.closeModal("createCycleSetting");
        })
        .catch(error => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.storeCycleSettingLoading = false;
        });
    },
    getCompanyPlantBuyers(company_plant) {
      if (!company_plant.company_buyers) {
        this.loadingCompanyBuyer = true;
        this.$store
          .dispatch("companyPlantBuyer/getByCompanyPlant", company_plant.id)
          .then(response => {
            company_plant.company_buyers = response.data ?? [];
            this.loadingCompanyBuyer = false;
          })
      }
    },
    getCompanyPlantBankAccounts(company_plant) {
      if (!company_plant.bank_accounts) {
        this.loadingBankAccount = true;
        this.$store
          .dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: company_plant.id})
          .then(response => {
            company_plant.bank_accounts = response.data ?? [];
            this.loadingBankAccount = false;
          })
      }
    },
    handleDeleteCompanyPlantBuyer(company_plant, company_plant_buyer_id) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
            this.$store
              .dispatch("companyPlantBuyer/destroy", company_plant_buyer_id)
              .then(response => {
                company_plant.company_buyers.splice(company_plant.company_buyers.findIndex((item) => item.id === company_plant_buyer_id), 1);
                this.$forceUpdate();
                this.$notify({type: response.error_type, message: response.message});
              }).catch(error => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
            });
          }
        });
    },
    handleModalAddBuyer(company_plant_id) {
      this.$refs.modalAddBuyer.openModal(company_plant_id);
    },
    registerBuyer(buyer) {
      this.$_plants.filter(item => item.id == buyer.company_plant_id)[0].company_buyers.push(buyer)
      this.$forceUpdate()
    },
    setDefaultBuyer(plant, buyer_id) {
      this.loadingCompanyBuyer = true;
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      plant.company_buyers.map(function (obj) {
        obj.default = obj.id === buyer_id ? 1 : 0
      })
      this.$store
        .dispatch("companyPlantBuyer/setDefaultCompanyPlantBuyer", {company_plant_id: plant.id, buyer_id: buyer_id})
        .then(response => {
          this.$notify({type: response.error_type, message: response.message});
          this.loadingCompanyBuyer = false;
          this.$forceUpdate();
        })
    },
    setDefaultBankAccount(plant, bank_account_id) {
      this.loadingBankAccount = true;
      let loader = this.$loading.show();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      plant.bank_accounts.map(function (obj) {
        obj.default = obj.id === bank_account_id ? 1 : 0
      })
      this.$store
        .dispatch("companyPlantBankAccount/setDefaultCompanyPlantBankAccount", {
          company_plant_id: plant.id,
          bank_account_id: bank_account_id
        })
        .then(response => {
          this.$notify({type: response.error_type, message: response.message});
          this.loadingBankAccount = false;
          this.$forceUpdate();
          loader.hide();
        })
    },
    handleDeleteCompanyPlantBankAccount(company_plant, bank_account_id) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
            this.$store
              .dispatch("companyPlantBankAccount/destroy", bank_account_id)
              .then(response => {
                company_plant.bank_accounts.splice(company_plant.bank_accounts.findIndex((item) => item.id === bank_account_id), 1);
                this.$forceUpdate();
                this.$notify({type: response.error_type, message: response.message});
                loader.hide();
              }).catch(error => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              loader.hide();
            });
          }
        });
    },
    handleModalAddBankAccount(company_plant_id) {
      this.$refs.modalAddBankAccount.openModal(company_plant_id);
    },
    registerBankAccount(bank_account) {
      this.$_plants.filter(item => item.id == bank_account.company_plant_id)[0].bank_accounts.push(bank_account)
      this.$forceUpdate()
    },
    async updatePlant() {
      this.$Progress.start();
      this.storePlantLoading = true;
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});

      let formData = new FormData();
      formData.append("id", this.editPlant.id);
      formData.append("status", this.editPlant.status);
      formData.append("company_id", this.editPlant.company_id);
      formData.append("name", this.editPlant.name);
      formData.append("short_name", this.editPlant.short_name.toUpperCase());
      formData.append("requires_municipal_building_code", this.editPlant.requires_municipal_building_code);
      formData.append("logo", this.editPlant.logo);
      formData.append("postal_code", this.editPlant.postal_code);
      formData.append("suframa_number", this.editPlant.suframa_number);
      formData.append("state", this.editPlant.state);
      formData.append("address", this.editPlant.address);
      formData.append("number", this.editPlant.number);
      formData.append("complement", this.editPlant.complement);
      formData.append("district", this.editPlant.district);
      formData.append("city", this.editPlant.city);
      if (this.editPlant.external_code) {
        formData.append("external_code", this.editPlant.external_code);
      }
      this.$axios
        .post("/company/plant/update/" + this.editPlant.id, formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(response => {
          this.$notify({type: response.data.error_type, message: response.data.message});
          this.storePlantLoading = false;
          this.editPlant = {};
          this.$_plants[this.indexPlant] = response.data.data;
          this.$Progress.finish();
          this.closeModal("update");
        })
        .catch(error => {
          this.$notify({type: error.data.error_type, message: error.data.exception});
          this.storePlantLoading = false;
        });
    },
    async storePlant() {
      this.$Progress.start();
      this.storePlantLoading = true;
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});

      let formData = new FormData();
      formData.append("status", this.plant.status);
      formData.append("company_id", this.plant.company_id);
      formData.append("name", this.plant.name);
      formData.append("short_name", this.plant.short_name.toUpperCase());
      formData.append("requires_municipal_building_code", this.plant.requires_municipal_building_code);
      formData.append("logo", this.plant.logo);
      formData.append("postal_code", this.plant.postal_code);
      formData.append("suframa_number", this.plant.suframa_number);
      formData.append("state", this.plant.state);
      formData.append("address", this.plant.address);
      formData.append("number", this.plant.number);
      formData.append("complement", this.plant.complement);
      formData.append("district", this.plant.district);
      formData.append("city", this.plant.city);

      this.$axios
        .post("/company/plant", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(response => {
          this.$notify({type: response.data.error_type, message: response.data.message});
          this.storePlantLoading = false;
          this.company = {};
          this.getCompany();
          this.init();
          this.$Progress.finish();
          this.closeModal("create");
        })
        .catch(error => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.storePlantLoading = false;
        });
    },
    removeItemIssuer(itemIssuerId, itemPlantId, indexIssuer) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            let params = {
              id: itemPlantId,
              issuer_id: itemIssuerId
            };
            this.$Progress.start();
            let loader = this.$loading.show();
            this.$store
              .dispatch("plant/unbindCompanyPlantIssuer", params)
              .then(response => {
                this.$Progress.finish();
                loader.hide();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                const indexPlant = this.$_plants.findIndex(item => item.id == itemPlantId);
                this.$_plants[indexPlant].company_issuers.splice(indexIssuer, 1);
              })
              .catch((error) => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message,
                });
                loader.hide();
                this.$Progress.finish();
              });
          }
        });
    },
    handleShowModalAddIssuerDocument(id) {
      this.showModalAddIssuerDocument = true;
      this.$refs.addIssuerDocument.company_plant_id = id;
    },
    deleteCompanyPlantIssuer(id) {
      this.deleteCompanyPlantIssuerLoading = true;
      this.$Progress.start();
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá recuperar este registro!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, apague isto!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
            this.$axios
              .delete("/plantissuer/" + id)
              .then(response => {
                this.$notify({type: response.data.error_type, message: response.data.message});
                this.init();
                this.getCompanies();
                this.$Progress.finish();
                this.deleteCompanyPlantIssuerLoading = false;
              })
              .catch(error => {
                this.$notify({type: error.response.data.error_type, message: error.response.data.message});
                this.deleteCompanyPlantIssuerLoading = false;
              });
          } else {
            this.deleteCompanyPlantIssuerLoading = false;
          }
          this.$Progress.finish();
        });
    },
    deletePlant(id) {
      this.$Progress.start();
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá recuperar este registro!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, apague isto!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
            this.$axios
              .delete("/company/plant/" + id)
              .then(response => {
                this.$notify({type: response.data.error_type, message: response.data.message});
                this.init();
                this.getCompanies();
                this.$Progress.finish();
              })
              .catch((error) => {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.storePlantLoading = false;
              });
          }
          this.$Progress.finish();
        });
    },
  },
  created() {
  },
  mounted() {
    this.route.company_id = this.$route.params.company_id;
    this.getCompany();
    this.init({});
    this.getStates();
  }
};
</script>
<style>
.main-icon-menu {
  min-width: 18px;
}
</style>
