<template>
  <div>
    <InvoiceListPageCards
      v-if="props.listType === 'cards'"
      :contract_proposals="props.contract_proposals"
      :contract_proposal_agroup="contract_proposal_agroup"
      :agroup_selecteds="agroup_selecteds"
      :loadingSkeleton="props.loading"
      :paymentMethods="props.paymentMethods"
      :paymentTerms="props.paymentTerms"
      :bankAccounts="props.bankAccounts"
      :payment_terms_by_method="props.payment_terms_by_method"
      :payment_intemerdiaries_selected="props.payment_intemerdiaries_selected"
      :invoice="props.invoice"
      @setIss="setIss"
      @getPaymentTerms="getPaymentTerms"
      @showModalFederalTaxes="showModalFederalTaxes"
      @handleCreateModalBilling="handleCreateModalBilling"
      @setToAgroup="setToAgroup"
      @setInvoice="setInvoice"
      @handleCreateIss="handleCreateIss"
      @showModalIndividual="showModalIndividual"
    />
    <InvoiceListPageTable
      v-if="props.listType === 'table'"
      :contract_proposals="props.contract_proposals"
      :contract_proposal_agroup="contract_proposal_agroup"
      :agroup_selecteds="agroup_selecteds"
      :loadingSkeleton="props.loading"
      :paymentMethods="props.paymentMethods"
      :paymentTerms="props.paymentTerms"
      :bankAccounts="props.bankAccounts"
      :payment_terms_by_method="props.payment_terms_by_method"
      :payment_intemerdiaries_selected="props.payment_intemerdiaries_selected"
      :invoice="props.invoice"
      @setIss="setIss"
      @getPaymentTerms="getPaymentTerms"
      @showModalFederalTaxes="showModalFederalTaxes"
      @handleCreateModalBilling="handleCreateModalBilling"
      @setToAgroup="setToAgroup"
      @setInvoice="setInvoice"
      @handleCreateIss="handleCreateIss"
      @showModalIndividual="showModalIndividual"
    />
    <div class="container-fluid">
      <PuzlEmptyData v-if="!loading && !contract_proposals.length" />
    </div>
    <!-- Trecho de agrupamento não foi mexido -->
    <div class="agroup-float" v-if="agroup_selecteds.length && !props.invoice">
        <div class="container p-0">
          <div class="row mb-2">
            <div class="col-4 pr-1">
              <base-button type="light" block
                :disabled="!selected_agroup.has_deduct"
                @click.prevent="selected_agroup.iss_retain = !selected_agroup.iss_retain"
                :class="selected_agroup.iss_retain ? 'new-btn-danger' : 'new-btn-light'"
                class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                size="sm">
                <img v-show="!selected_agroup.iss_retain" src="/img/icons/chemical-plant-black.png" width="14"/>
                <img v-show="selected_agroup.iss_retain" src="/img/icons/icons8/ios/name--v1_danger.png" width="14"/>
                <span :class="selected_agroup.iss_retain && 'text-danger fs-10'" class="fs-10">
                  {{ selected_agroup.iss_retain ? 'ISS RETIDO' : 'ISS DEVIDO' }}
                </span>
              </base-button>
            </div>
            <div class="col-4 px-1">
              <base-button type="light" block
                 @click.prevent="showModalFederalTaxes(selected_agroup)"
                :class="hasAnyFederalTaxRetained(selected_agroup) ? 'new-btn-danger' : 'new-btn-light'"
                class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                size="sm">
                <img v-show="!hasAnyFederalTaxRetained(selected_agroup)" src="/img/icons/ledger.png"
                     width="14"/>
                <img v-show="hasAnyFederalTaxRetained(selected_agroup)" src="/img/icons/icons8/ios/ledger_danger.png"
                     width="14"/>
                <span :class="hasAnyFederalTaxRetained(selected_agroup) && 'text-danger'" class="fs-10">
                  FEDERAL
                </span>
              </base-button>
            </div>
            <div class="col-4 pl-1" v-if="selected_agroup.balance > 0">
              <base-button type="light" block
                :disabled="requireBalance(selected_agroup)"
                @click.prevent="selected_agroup.use_anticipation_balance = !selected_agroup.use_anticipation_balance"
                :class="selected_agroup.use_anticipation_balance ? 'new-btn-primary' : 'new-btn-light'"
                class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                size="sm">
                <img v-show="!selected_agroup.use_anticipation_balance"
                  src="/img/icons/icons8/ios/us-dollar-circled--v1_black.png"
                  width="14"/>
                <img v-show="selected_agroup.use_anticipation_balance"
                  src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
                  width="14"/>
                <span :class="selected_agroup.use_anticipation_balance && 'text-primary'" class="fs-10">
                  COMPENSAR
                </span>
              </base-button>
            </div>
            <div class="col-12" v-if="!selected_agroup.has_deduct">
              <base-button
                block size="sm"
                type="danger"
                outline
                class="text-uppercase"
                @click.prevent="handleCreateIss(selected_agroup)">
                <i class="fa-solid fa-triangle-exclamation"/>
                {{ selected_agroup.has_deduct_title }} (sem regra de iss cadastrada)
              </base-button>
            </div>
          </div>
          <div class="row my-md-2">
            <div class="col-md-6 mb-1 mb-md-0 pr-md-1">
              <base-input input-classes="form-control-sm " placeholder="CENTRAL">
                <puzl-select
                  :disableBoxShadow="true"
                  :clearable="false"
                  @change="getCompanyPlantIssuer();getBankAccountsByPlantId(filter_agroup.company_plant_id)"
                  placeholder="CENTRAL"
                  block
                  v-model="filter_agroup.company_plant_id"
                  :items="props.companyPlants"
                />
              </base-input>
            </div>
            <div class="col-md-6 mb-1 mb-md-0 pl-md-1">
              <base-input input-classes="form-control-sm">
                <base-input input-classes="form-control-sm " placeholder="CNPJ EMISSOR">
                  <puzl-select
                    :disableBoxShadow="true"
                    @change="getIss"
                    label="ein"
                    :clearable="false"
                    placeholder="CNPJ EMISSOR"
                    v-model="filter_agroup.company_plant_issuer_id"
                    :items="company_plant_issuers"
                  />
                </base-input>
              </base-input>
            </div>
          </div>
          <div class="row my-md-2">
            <div class="col-md-4 mb-1 mb-md-0 pr-md-1">
              <base-input style="font-size: 5px !important;" input-classes="form-control-sm">
                <el-select
                  style="font-size: 5px !important;"
                  class="disabled-all-shadow-050220251728"
                  @change="getPaymentTerms(selected_agroup)"
                  size="mini"
                  v-model="selected_agroup.payment_method_id"
                  :disabled="loadingMethod"
                  placeholder="MÉTODO" filterable>
                  <el-option v-for="method in getPaymentMethods(selected_agroup)" :key="method.id"
                             :label="method.name"
                             :value="method.id"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-4 mb-1 mb-md-0 px-md-1">
              <base-input input-classes="form-control-sm">
                <el-select
                  class="disabled-all-shadow-050220251728"
                  @change="setRequireBalance(selected_agroup)"
                  size="mini"
                  v-model="selected_agroup.payment_term_id"
                  :disabled="loadingPaymentTerm || !selected_agroup.payment_method_id" placeholder="CONDIÇÃO"
                  filterable>
                  <el-option v-for="term in payment_terms_by_method[selected_agroup.id]" :key="term.id"
                             :label="term.name"
                             :value="term.id"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-4 mb-1 mb-md-0 pl-md-1">
              <base-input input-classes="form-control-sm">
                <el-select
                  size="mini"
                  class="disabled-all-shadow-050220251728"
                  v-model="selected_agroup.bank_account_id"
                  :disabled="loadingBankAccounts"
                  placeholder="Conta"
                  filterable>
                  <el-option v-for="item in company_plant_bank_accounts"
                    :key="item.id"
                    :disabled="!filter_agroup.company_plant_id"
                    :label="item.bank_account.name"
                    :value="item.bank_account_id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row mt-2 mt-md-3 mb-2">
            <div class="col-md-6 mb-1 mb-md-0 pr-md-1">
              <AppButton
                icon="puzzle-matching"
                label="Visual. agrupados"
                :isDisabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !selected_agroup.has_deduct || !selected_agroup.bank_account_id"
                type="primary"
                :hasOutline="true"
                :isBlock="true"
                @click.prevent="showModalIndividualWithFilter(selected_agroup)"
              />
            </div>
            <div class="col-md-6 pl-md-1">
              <AppButton
                icon="cheque"
                label="Fat. agrupados"
                :isDisabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !selected_agroup.has_deduct || !selected_agroup.bank_account_id"
                type="primary"
                :isBlock="true"
                @click.prevent="handleClickAgroup"
              />
            </div>
          </div>
        </div>
      </div>
    <!-- Trecho de agrupamento não foi mexido -->
    <ModalIndividual
      @agrouped="handleClickAgroup"
      @showModalFederalTaxes="showModalFederalTaxes"
      @setIss="setIss"
      @closeAndUpdateInvoiced="handleCloseModalIndividual"
      :issChangingVerifierClone="props.issChangingVerifierClone"
      ref="modalIndividual" />
    <ModalFederalTaxes ref="modalFederalTaxes"/>
    <ModalBilling ref="modalBilling"/>
    <DeductionRuleCreationModal ref="deductionRuleCreationModal" :updateObject="updateObject"/>
    <ModalChangeIssRetain ref="modalChangeIssRetain" @changeIssRetain="changeIss" />
  </div>
</template>
<script setup>
//#region Imports
import {computed, onMounted, reactive, ref, watch} from "vue";
import {dialogs, strToNum, date, progress, cloneObject} from '../../../../../../helpers';
import InvoiceListPageCards from './InvoiceListPageCards.vue';
import InvoiceListPageTable from './InvoiceListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import DeductionRuleCreationModal
  from "@/views/Modules/Configuration/FeesAndTaxes/DeductionRules/Shared/_ModalCreateValidity.vue";
import ModalFederalTaxes from "@/views/Modules/Financial/Allotments/Invoice/Shared/_ModalFederalTaxes.vue";
import ModalIndividual from "@/views/Modules/Financial/Allotments/Invoice/Shared/Modals/IndividualBilling/_ModalIndividual.vue"
import ModalBilling from "@/views/Modules/Financial/Allotments/Invoice/Shared/_ModalBilling.vue";
import companyPlantBankAccountStore
  from "@/modules/configuration/company-plant-bank-account/store/companyPlantBankAccountStore";
import companyPlantIssuerStore from "@/modules/configuration/plant/store/companyPlantIssuerStore";
import invoiceStore from "../../store/invoiceStore";
import { useStore } from "@/shared/hooks/useStore"
import ModalChangeIssRetain from "../Modals/ModalChangeIssRetain.vue";
import AgroupFloatingActions from "@/modules/financial/allotments/invoice/views/list/AgroupFloatingActions.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {AppButton} from "@/components/AppGlobal";
//#endregion

//#region ComponentRefs
const deductionRuleCreationModal = ref(null);
const modalFederalTaxes = ref(null);
const modalIndividual = ref(null);
const modalBilling = ref(null);
const modalChangeIssRetain = ref(null);
//#endregion

//#region Data
const props = defineProps({
  listType: {
    type: String,
    required: true
  },
  contract_proposals: {
    type: [Array, Object],
    required: false,
  },
  loading: {
    type: Boolean,
    required: true
  },
  invoice: {
    type: Boolean,
    required: true
  },
  searchBarFilter: {
    type: Object,
    required: true
  },
  paymentTerms: {
    type: Array,
    required: true
  },
  paymentIntermediaries: {
    type: Array,
    required: true
  },
  paymentMethods: {
    type: Array,
    required: true
  },
  bankAccounts: {
    type: Array,
    required: true
  },
  companyPlants: {
    type: Array,
    required: true
  },
  generalSettings: {
    type: [Array, Object],
    required: true
  },
  payment_terms_by_method: {
    type: Array,
    required: true
  },
  payment_intemerdiaries_selected: {
    type: Object,
    required: true
  },
  issChangingVerifierClone: {
    type: [Array, Object],
    required: true
  }
})

const selected_agroup = ref({
  bank_account_id: null,
  construction_id: null,
  entity_id: null,
  has_deduct: false,
  block_iss: false,
  iss_retain: false,
  city: null,
});
const agroup_selecteds = ref([]);
const company_plant_bank_accounts = ref([])
const company_plant_issuers = ref([]);
const contract_proposal_agroup = ref(null);
const invoiced = ref(0);
const currentItem = ref(null);

const filter_agroup = reactive({
  company_plant_id: null,
  company_plant_issuer_id: null,
});

const loadingCompanyPlant = ref(false);
const loadingPaymentIntermediaries = ref(false);
const loadingPlant = ref(false);
const loadingPaymentTerm = ref(false);
const loadingMethod = ref(false);
const loadingBankAccounts = ref(false);

//#endregion

//#region Computeds
watch(() => props.invoice, (newValue) => {
    if (newValue) {
      agroup_selecteds.value = [];
      contract_proposal_agroup.value = null;
    }
  }
);

/** Store Getters */
const $_invoice_item = computed(() => invoiceStore.getters.getItem());
const $_invoice_is_finding = computed(() => invoiceStore.getters.getIsFinding());

const store = useStore();
//#endregion

//#region Methods
function getPaymentMethods(item) {
  if(!props.paymentMethods && typeof item?.payment_methods === 'undefined') return [];

  const payment_method_splited = item.payment_methods.split(',').map((item) => Number(item));
  return props.paymentMethods.filter((item) => payment_method_splited.includes(item.id));
}

const setRequireBalance = (item) => {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  if (payment_term && !item.use_anticipation_balance && payment_term.requires_balance) {
    item.use_anticipation_balance = 1;
  }
}

function hasAnyFederalTaxRetained(item) {
  return (item.cofins_retained_flag && strToNum(item.cofins_rate) > 0) ||
    (item.csll_retained_flag && strToNum(item.csll_rate) > 0) ||
    (item.inss_retained_flag && strToNum(item.inss_rate) > 0) ||
    (item.ir_retained_flag && strToNum(item.ir_rate) > 0) ||
    (item.pis_retained_flag && strToNum(item.pis_rate) > 0);
}

function getPaymentTerms(item) {
  emit('getPaymentTerms', item);
}

/**
 * Define o ISS Retido
 * @param item - invoice
 */
function changeIss(item){
  item.iss_retain = !item.iss_retain;
}

function setAnticipation(item) {
  if (requireBalance(item)) {
    return;
  }
  item.use_anticipation_balance = !item.use_anticipation_balance;
}

async function setIss(item, index) {
  if (!item.has_deduct) {
    const isConfirmed = await dialogs.confirmAction(
      'Não há regra de dedução cadastrada. Deseja cadastrar agora?',
      ["Sim, cadastrar agora", "Não, cancelar"]
    );
    if (isConfirmed) {
      handleCreateIss(item);
    }
  } else {
    isActualIssRetainValueDifferentFromApi(item, index);
  }
}

function isActualIssRetainValueDifferentFromApi(item, index) {
  props.issChangingVerifierClone.forEach(function (invoice, index) {
    if(invoice.id === item.id){
      if(Boolean(item.iss_retain) === Boolean(invoice.iss_retain)){
        return modalChangeIssRetain.value.openModal(item, index);
      } else {
        item.iss_retain = !item.iss_retain
        return false;
      }
    }
  });
}

function handleCloseModalIndividual(key) {
  const index = props.contract_proposals.findIndex((item) => item.key === key);
  if (index !== -1) {
    props.contract_proposals[index].is_pending = true;
  }
}

async function getBankAccountsByPlantId(company_plant_id) {
  const loader = progress.showLoader();
  const response = await store.dispatch("companyPlantBankAccount/getByCompanyPlant", {company_plant_id: company_plant_id});

  company_plant_bank_accounts.value = response.data
  loader.hide();

  if(company_plant_bank_accounts.value.length){
    const default_bank_account = company_plant_bank_accounts.value.find((item) => item.default);
    selected_agroup.value.bank_account_id = default_bank_account?.bank_account_id;
  }
}

async function getCompanyPlantIssuer() {
  const loader = progress.showLoader()
  const response = await store.dispatch('plant/fetchCompanyPlantIssuersByPlant', { id: filter_agroup.company_plant_id});
  company_plant_issuers.value = response.data;
  loader.hide();

  if (company_plant_issuers.value.length) {
    const default_issuer = company_plant_issuers.value.find(item => item.default);
    if (default_issuer) {
      filter_agroup.company_plant_issuer_id = default_issuer.id;
      await getIss();
    }
  }
}

const getIss = async () => {
  const loader = progress.showLoader()

  try {
    const response = await store.dispatch('FeesAndTaxes/existsISSQNBetweenEntityAndIssuer', {
      construction_id: selected_agroup.value.construction_id,
      issuerId: filter_agroup.company_plant_issuer_id,
    });

    selected_agroup.has_deduct = response.data;

    let active_retained_rates = [];

    const ratesResponse = await store.dispatch('FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuer', {
      entityId: selected_agroup.value.entity_id,
      issuerId: filter_agroup.company_plant_issuer_id,
    });

    if (!selected_agroup.block_iss) {
      if (!ratesResponse.data.length) {
        selected_agroup.iss_retain = false;
      } else {
        const has_issqn = ratesResponse.data.some((item) => item.name === 'ISSQN');
        if (has_issqn) {
          selected_agroup.iss_retain = true;
        }
      }

      active_retained_rates = ratesResponse.data;
      const tax_regime = company_plant_issuers.value.find(
        (item) => item.id === filter_agroup.company_plant_issuer_id
      ).tax_regime;

      await getAllCurrentTaxes(selected_agroup.value.city, tax_regime, active_retained_rates);
    }
  } catch (error) {
    console.error('Erro ao obter ISS:', error);
  } finally {
    loader.hide();
  }
}

const getAllCurrentTaxes = async (constructionCity, issuerRegime, active_retained_rates) => {
  const params = {
    constructionCity: constructionCity,
    issuerRegime: issuerRegime,
  };

  try {
    const response = await store.dispatch('deductionRules/getAllCurrentTaxes', params);

    response.data.forEach(federalTax => {
      selected_agroup[`${federalTax.name.toLowerCase()}_rate`] = federalTax.retained_rate
        ? parseFloat(federalTax.retained_rate)
        : 0;

      const has_active_retained_rate = active_retained_rates.find((item) => item.name === federalTax.name);
      selected_agroup[`${federalTax.name.toLowerCase()}_retained_flag`] = Boolean(has_active_retained_rate);
    });
  } catch (error) {
    const errors = error && error.response && error.response.status === 422
      ? formatErrorValidation(error.response.data.errors)
      : error.data.message;
    dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
  }
}

const showModalIndividualWithFilter = async (item) => {
  const plant_ids = [];
  const plant_issuer_ids = [];

  props.contract_proposals.forEach((contract_proposal) => {
    if (contract_proposal.invoice === true) {
      plant_ids.push(contract_proposal.company_plant_id);
      plant_issuer_ids.push(contract_proposal.company_plant_issuer_id);
    }
  });

  const copy = { ...item };

  const loader = progress.showLoader()
  const payment_method_selected = props.paymentMethods.find(
    (method) => method.id === copy.payment_method_id
  ).name;

  const payment_term_selected = props.paymentTerms.find(
    (term) => term.id === copy.payment_term_id
  ).name;

  const company_plant_selected = props.companyPlants.find(
    (plant) => plant.id === filter_agroup.company_plant_id
  ).name;

  const company_plant_issuer_selected = company_plant_issuers.value.find(
    (issuer) => issuer.id === filter_agroup.company_plant_issuer_id
  ).ein;

  copy.company_plant = company_plant_selected;
  copy.company_plant_issuer = company_plant_issuer_selected;
  copy.payment_method = payment_method_selected;
  copy.payment_term = payment_term_selected;

  const key = copy.key.split('-');

  copy.filter = {
    company_plant_ids: plant_ids,
    id: key[1],
    range: props.searchBarFilter.range,
    agrouped: 1,
    new_company_plant_id: filter_agroup.company_plant_id,
    new_company_plant_issuer_id: filter_agroup.company_plant_issuer_id,
    company_plant_issuer_ids: plant_issuer_ids,
  };

  try {
    await invoiceStore.actions.find(copy.uuid);
    modalIndividual.value.handleCreate(copy);
  } catch (error) {
    const errors = error && error.response && error.response.status === 422
      ? formatErrorValidation(error.response.data.errors)
      : error.data.message;
    dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
  } finally {
    loader.hide();
  }
}

const showModalIndividual = async (item, invoice = true, index = null) => {
  const loader = progress.showLoader()

  if (!item.has_deduct) {
    dialogs.notify(dialogs.TYPE_ENUM.DANGER, `${item.has_deduct_title} (sem regra de ISS cadastrada)`);
  }

  if (item.has_pending_service || item.has_pending_travels) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Existem entregas em andamento');
  }

  if (disableIndividualInvoice(item)) {
    return;
  }

  const copy = { ...item };

  const payment_method_selected = props.paymentMethods.find(
    (method) => method.id === copy.payment_method_id
  )?.name;

  const payment_term_selected = props.paymentTerms.find(
    (term) => term.id === copy.payment_term_id
  )?.name;

  copy.company_plant = item.company_plant_name;
  copy.company_plant_issuer = copy.document;
  copy.plant_issuer_id = copy.company_plant_issuer_id;
  copy.payment_method = payment_method_selected;
  copy.payment_term = payment_term_selected;

  const key = copy.key.split('-');

  copy.filter = {
    company_plant_id: key[0],
    id: key[1],
    range: props.searchBarFilter.range,
    company_plant_issuer_id: key[2],
  };

  try {
    await invoiceStore.actions.find(copy.uuid);
    modalIndividual.value.handleCreate(copy, invoice, index);
  } catch (error) {
    console.error('Erro ao abrir o modal individual:', error);
  } finally {
    loader.hide();
  }
}

const disableIndividualInvoice = (item) => {
  return (
    !item.has_deduct ||
    !item.bank_account_id ||
    item.has_pending_service ||
    item.has_pending_travels ||
    item.is_pending ||
    !item.payment_method_id ||
    item.pending_message ||
    !item.payment_term_id ||
    validPaymentTerm(item)
  );
}

const validPaymentTerm = (item) => {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find((term) => term.id === item.payment_term_id);

  if (!payment_term) {
    return true;
  }

  if (payment_term.requires_balance && payment_term.requires_balance === 2) {
    if (Number(item.balance) > 0 && !item.use_anticipation_balance) {
      return true;
    }
    if (Number(item.balance) < 1) {
      return true;
    }
  }

  return false;
}

const requireBalance = (item) => {
  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  if (!payment_term) {
    return false;
  }

  return Boolean(payment_term.requires_balance);
}
/**
 * Recebe a fatura a ser agrupada
 * @param item
 * @param index
 */
const setToAgroup = (item, index) => {
  const invoice = props.contract_proposals[index].invoice;
  props.contract_proposals[index].invoice = !invoice;

  if (agroup_selecteds.value.length === 0) {
    filter_agroup.company_plant_id = null;
    filter_agroup.company_plant_issuer_id = null;
    filter_agroup.bank_account_id = null;
    selected_agroup.value = null;
  }

  if (agroup_selecteds.value?.includes(item.key)) {
    const index = agroup_selecteds.value.indexOf(item.key);
    agroup_selecteds.value.splice(index, 1);

    if (agroup_selecteds.value.length === 0) {
      contract_proposal_agroup.value = null;
      filter_agroup.company_plant_id = null;
      filter_agroup.company_plant_issuer_id = null;
      selected_agroup.value = null;
    }

    return;
  }

  if (!selected_agroup.value) {
    selected_agroup.value = { ...item, bank_account_id: null };
  }

  contract_proposal_agroup.value = item.code;
  agroup_selecteds.value.push(item.key);
}
const setInvoice = (index, value) => {
  emit('setInvoice', index, value);
}
const handleClickAgroup = async (params) => {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']);

  if (isConfirmed) {
    const loader = progress.showLoader()
    const items = props.contract_proposals.filter((item) => {
      if (item.invoice === true) {
        item.is_pending = true;
      }
      return item.invoice === true;
    });

    const selecteds = items.map((item) => {
      const cofinsRate = selected_agroup.value.cofins_retained_flag && strToNum(selected_agroup.value.cofins_rate) > 0
        ? strToNum(selected_agroup.value.cofins_rate)
        : 0;
      const csllRate = selected_agroup.value.csll_retained_flag && strToNum(selected_agroup.value.csll_rate) > 0
        ? strToNum(selected_agroup.value.csll_rate)
        : 0;
      const inssRate = selected_agroup.value.inss_retained_flag && strToNum(selected_agroup.value.inss_rate) > 0
        ? strToNum(selected_agroup.value.inss_rate)
        : 0;
      const irRate = selected_agroup.value.ir_retained_flag && strToNum(selected_agroup.value.ir_rate) > 0
        ? strToNum(selected_agroup.value.ir_rate)
        : 0;
      const pisRate = selected_agroup.value.pis_retained_flag && strToNum(selected_agroup.value.pis_rate) > 0
        ? strToNum(selected_agroup.value.pis_rate)
        : 0;

      const filtered = {
        id: item.id,
        iss_retain: item.iss_retain,
        cofins_rate: cofinsRate,
        csll_rate: csllRate,
        inss_rate: inssRate,
        ir_rate: irRate,
        pis_rate: pisRate,
        payment_term_id: item.payment_term_id,
        payment_method_id: item.payment_method_id,
        payment_intermediary_id: item.payment_intermediary_id,
        bank_account_id: item.bank_account_id,
        considere_prepayment: item.considere_prepayment,
        use_anticipation_balance: item.use_anticipation_balance,
        company_plant_id: item.company_plant_id,
        company_plant_issuer_id: item.company_plant_issuer_id,
      };

      if (params) {
        filtered.concreteds = params.concreteds;
        filtered.services = params.services;
        filtered.additionals = params.additionals;
        filtered.single = params.single;
      }

      return filtered;
    });

    const response = store.dispatch('billingInvoice/postSetAgroup', {
      items: selecteds,
      selected_agroup: selected_agroup.value,
      filter_agroup: filter_agroup,
      range: props.searchBarFilter.range,
    });

    response.then((response) => {
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, response.message)
      invoiced.value = 0;
      modalIndividual.value.close();
      agroup_selecteds.value = [];
      loader.hide();
    })
      .catch((error) => {
        const errors = error && error.response && error.response.status === 422
          ? formatErrorValidation(error.response.data.errors)
          : error.data.message;
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
        loader.hide();
      });
  }
}
/**
 * Abre modal de faturamento do contrato
 *
 * @param {string} uuid - contract uuid
 */
function handleCreateModalBilling(uuid) {
  modalBilling.value.handleCreateModal(uuid);
}

const showModalFederalTaxes = (item) => {
  const loader = progress.showLoader()
  const key = item.key.split('-');
  const filter = {
    company_plant_id: key[0],
    id: key[1],
    range: props.searchBarFilter.range,
    company_plant_issuer_id: key[2],
  };

  store.dispatch("contractProposal/show", item.uuid)
  .then(() => {
    modalFederalTaxes.value.handleCreate(item, filter);
  })
  .finally(() => {
    loader.hide();
  });
}

const handleCreateIss = (item) => {
  currentItem.value = item;
  const ISSQN_ID = 14;
  const COUNTRY = 'BR';
  const MODAL_DETAIL = `${item.state} - ${item.city} - ${item.tax_regime}`;
  const params = {
    commercialTaxId: ISSQN_ID,
    state: item.state,
    city: item.city,
    regime: item.tax_regime,
  };
  const loader = progress.showLoader()

  store.dispatch("TaxLocality/getByData", params)
    .then((response) => {
      const alreadyExists = response.data !== null;
      if (alreadyExists) {
        const tax = response.data;
        deductionRuleCreationModal.value.handleCreateModal(tax, MODAL_DETAIL);
        loader.value.hide();
        return;
      }

      const payload = {
        commercial_tax_id: ISSQN_ID,
        status: true,
        allow_issqn_retention_of_legal_person: true,
        allow_issqn_retention_of_natural_person: false,
        allow_issuance_without_customer_municipal_registration: true,
        state: item.state,
        city: item.city,
        country: COUNTRY,
        regime: item.tax_regime,
        expiration_day: 0,
      };

      store.dispatch("TaxLocality/add", payload)
        .then((response) => {
          const tax = response.data;
          deductionRuleCreationModal.value.handleCreateModal(tax, MODAL_DETAIL);
          loader.hide();
        })
        .catch((error) => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.errors)
            : error.data.message;
          dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
          loader.hide();
        });
    })
    .catch((error) => {
      const errors = error && error.response && error.response.status === 422
        ? formatErrorValidation(error.response.data.errors)
        : error.data.message;
      dialogs.notify(dialogs.TYPE_ENUM.DANGER, errors)
      loader.hide();
    });
}

function updateObject(){
  emit('listItems');
}

const emit = defineEmits([
  'setIss',
  'showModalFederalTaxes',
  'hasAnyFederalTaxRetained',
  'handleCreateModalBilling',
  'getPaymentMethods',
  'setToAgroup',
  'setInvoice',
  'getPaymentTerms',
  'getBankAccounts',
  'handleCreateIss',
  'showModalIndividual',
]);
//#endregion

//#region Lifecycle
//#endregion
</script>
<style scoped>
.agroup-float {
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 16px;
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .agroup-float {
    z-index: 10 !important;
    position: fixed;
    width: 400px;
    text-align: center;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .agroup-float {
    z-index: 10 !important;
    position: fixed;
    top: 2% !important;
    left: 10px;
    width: 350px !important;
    text-align: center;
  }
}
</style>
<style lang="scss">
.disabled-all-shadow-050220251728 {
  div:first-child {
    input {
      box-shadow: none !important;
      border-radius: 4px !important;
      border: 1px solid #ECECEC;
    }
  }
}
</style>
