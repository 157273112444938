<template>
  <div
    v-if="isProposal"
    class="app-badge proposta"
    v-bind="$attrs"
    v-on="$listeners"
  >
    Proposta
  </div>
  <div
    v-else
    class="app-badge"
    :class="{
      [badgeComponentStatusMap[props.status].color]: true,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <AppIcon
      :icon="badgeComponentStatusMap[props.status].icon"
      width="18"
      :color="badgeComponentStatusMap[props.status].color"
      class="mr-1"
      align="bottom"
    />
    {{ badgeComponentStatusMap[props.status].text }}
  </div>
</template>

<script setup>
import { ContractProposalEnum } from "../../Enum/ContractProposalEnum";
import { AppIcon } from "../../../../../components/AppGlobal";

const props = defineProps({
  status: {
    type: Number,
    required: true,
    validator: (value) => {
      const isValid = Object.values(ContractProposalEnum).includes(value);
      if (!isValid) {
        console.error(
          `Invalid status value: ${value}. Either you forgot to add this value to the mapper or your API is returning the wrong value
          \n Available options: ${Object.values(ContractProposalEnum).join(
            ", "
          )}
          \n
          `
        );
      }
      return isValid;
    },
  },
  isProposal: {
    type: Boolean,
    default: false,
  }
});

const badgeComponentStatusMap = {
  [ContractProposalEnum.ACTIVE]: {
    icon: "check",
    color: "success",
    text: "Ativo",
  },
  [ContractProposalEnum.INACTIVE]: {
    icon: "shutdown",
    color: "danger",
    text: "Bloqueado",
  },
  [ContractProposalEnum.PENDING_APPROVAL]: {
    icon: "hourglass",
    color: "warning",
    text: "Pendente",
  },
  [ContractProposalEnum.PENDING_REVIEW]: {
    icon: "hourglass",
    color: "warning",
    text: "Pendente",
  },
  [ContractProposalEnum.COMPLETED]: {
    icon: "double-tick",
    color: "primary",
    text: "Concluído",
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

.app-badge {
  margin-right: 12px;
  padding: 4px;
  border-radius: 50px;
  min-height: 24px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.success {
    background-color: $success-lighter;
    color: $success;
    &:hover {
      background-color: $success;
    }
  }
  &.danger {
    background-color: $danger-lighter;
    color: $danger;
    &:hover {
      background-color: $danger;
    }
  }
  &.warning {
    background-color: $warning-lighter;
    color: $warning;
    &:hover {
      background-color: $warning;
    }
  }
  &.primary {
    background-color: $primary-lighter;
    color: $primary;
    &:hover {
      background-color: $primary;
    }
  }

  &:hover {
    cursor: pointer;
    color: white !important;

    .app-icon {
      color: white !important;
    }
  }

  &.proposta {
    background-color: $muted-light;
    &:hover {
      background-color: $muted;
    }
  }
}
</style>
