<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in $_equipment_listed.items">
        <div class="card main-card mb-3">
          <div class="card-content">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <!-- status -->
                <base-dropdown menuOnRight class="d-flex justify-content-center dropdown-status">
                  <div slot="title-container" class="dropdown-toggle"
                    :class="getStatusClass(item.status)">
                    <img class="mr-1" ref="icon" height="16" :src="getStatusIcon(item.equipment_situations_id, item.status)" />
                    <span>{{ item.situation_name }}</span>
                  </div>
                  <div v-for="(situation, index) in equipmentSituation" :key="index"
                    @click.prevent="onUpdateEquipmentSituationClick(item.id, situation.id)">
                    <a class="dropdown-item d-flex" style="font-size: 12px;">
                      {{ situation.situation_name }}
                    </a>
                    <div v-if="index !== equipmentSituation.length - 1"
                      style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                  </div>
                </base-dropdown>
                <el-popover trigger="click" placement="left" class="p-0 user-details">
                  <div class="popover-content">
                    <div class="checklist-info">
                      <h4 class="checklist-title mt-2" v-for="(allocation, indexAllocation) in item.company_plants_names.split(',')"
                        :key="indexAllocation">
                        {{ allocation }}
                      </h4>
                    </div>
                  </div>
                  <div slot="reference">
                    <img class="icon-img mr-2" width="25px" src="/img/icons/chemical-plant-black.png" />
                  </div>
                </el-popover>
                <el-popover placement="right" class="p-0">
                  <h5 slot="title" class="mb-0 ">Detalhes</h5>
                  <h5 class="h3 mb-0">
                    {{ item.brand }} &nbsp;({{ item.predominant_color }})
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.model }} ({{ item.equipment_manufacture_year ||
                      '' }}/{{ item.equipment_year }})
                  </h5>
                  <h5 class="h3 mb-0 mt-2">
                    {{ item.chassis }}
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.vehicle_permit_code }}
                  </h5>

                  <h5 class="h3 mb-0 mt-2">
                    {{ item.vehicle_license_name }}
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.vehicle_license_legal_entity }}
                  </h5>
                  <base-button outline slot="reference" size="sm" type="secundary"
                    class="p-0 m-0 shadow-none text-indigo">
                    <img class="mr-1" src="/img/icons/info.png" width="26">
                  </base-button>
                </el-popover>
                <img v-if="item.is_external_service" class="mx-1" src="/img/icons/icons8/ios/lease_success.png" width="24">
                <img v-if="item.concrete_removal" class="ml-2" src="/img/icons/icons8/ios/loading-truck_danger.png" width="24">
              </div>

              <div class="text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0 p-0 d-flex justify-content-end">
                    <img width="34" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <router-link :to="{
                    path: '/equipment/equipment/edit',
                    name: 'equipment.equipment.edit',
                    params: { equipment_uuid: item.uuid }
                  }">
                    <a class="dropdown-item">
                      <img src="/img/icons/icons8/ios/create-new-yellow.png" height="20px">
                      Editar
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleShowModalSetPlant(item.uuid)">
                    <img class="icon-img mr-2" height="20px" src="/img/icons/chemical-plant-black.png" />
                    Alocação de Central
                  </a>
                  <div class="dropdown-divider p-0 m-0" />
                  <a class="dropdown-item" @click.prevent="handleShowModalSearchEntity(item.id)">
                    <img src="/img/icons/salesman.png" height="20px" />
                    Responsável terceiro
                  </a>
                  <div class="dropdown-divider p-0 m-0" />
                  <router-link :to="{
                    path: '/equipment/equipment/implements',
                    name: 'equipment.implement.index',
                    params: { equipment_uuid: item.uuid }
                  }">
                    <a class="dropdown-item">
                      <img src="/img/icons/icons8/ios/truck.png" height="16px">
                      Implementos
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0" />
                  <a class="dropdown-item" @click.prevent="handleShowModalBuySell(item.uuid)">
                    <img class="icon-img mr-2" height="20px" src="/img/icons/icons8/ios/low-price_success.svg" />
                    Dados de Compra / Venda
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="removeEquipment(item.uuid)">
                    <img src="/img/icons/icons8/ios/trash_can.png" height="20px">
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="d-flex align-items-center code-plate">
              <div class="card-value d-flex align-items-end">
                {{ item.code}} |
              </div>
              <div class="card-value d-flex align-items-end ml-1">
                {{ item.plate }}
              </div>
            </div>
            <h5 class="h3 mb-0">
              {{ item.category_name }}
            </h5>
            <h5 class="h3 mb-0 font-weight-normal">
              {{ item.equipment_type_description}}
            </h5>

          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="$_equipment_is_listing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index" />
    </div>
    <ModalSetPlant ref="modalSetPlant" @allocatedPlants="updateAllocatedPlants" />
    <ModalBuySell ref="modalBuySell" />
    <ModalSearchEntity @selected="storeThirdPartyResponsible" ref="modalSearchEntity" />
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, ref, onMounted } from "vue";
import equipmentStore from "../../store/equipmentStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import equipmentSituationStatusEnum from "../../enums/EquipmentSituationStatusEnum";
import EquipmentStatusEnum from "../../enums/EquipmentStatusEnum";
import ModalSetPlant from './_ModalSetPlant';
import ModalBuySell from './_ModalBuySell';
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import { dialogs } from '../../../../../../helpers';
//#endregion

//#region Emits
const emit = defineEmits([
  "onUpdateEquipmentSituationClick",
]);
//#endregion

//#region Data
const equipmentSituation = ref([]);

const modalSetPlant = ref(null);
const modalBuySell = ref(null);
const modalSearchEntity = ref(null);
const equipmentId = ref(null);

//#region Computeds
/** Store Getters */
const $_equipment_listed = computed(() => equipmentStore.getters.getListed());
const $_equipment_is_listing = computed(() => equipmentStore.getters.getIsListing());
//#endregion

//#region Methods

/**
 * Exibe o modal para alocar uma central ao equipamento.
 * @param {string} uuid - UUID do equipamento.
 */
function handleShowModalSetPlant(uuid) {
  modalSetPlant.value.openModal(uuid, 'equipment');
}

/**
 * Exibe o modal para buscar e selecionar um responsável terceiro.
 * @param {number} id - ID do equipamento.
 */
function handleShowModalSearchEntity(id) {
  equipmentId.value = id;
  modalSearchEntity.value.openModal(id);
}

/**
 * Exibe o modal para adicionar ou editar dados de compra/venda.
 * @param {string} uuid - UUID do equipamento.
 */
function handleShowModalBuySell(uuid) {
  modalBuySell.value.openModal(uuid, 'equipment');
}

/**
 * Atualiza a situação do equipamento.
 * @param {number} equipmentId - ID do equipamento.
 * @param {number} situationId - ID da nova situação.
 */
function onUpdateEquipmentSituationClick(equipmentId, situationId) {
  emit("onUpdateEquipmentSituationClick", equipmentId, situationId);
}

/**
 * Carrega as situações disponíveis para os equipamentos.
 */
const loadEquipmentSituation = async () => {
  equipmentSituation.value = await equipmentStore.actions.getEquipmentSituations();
};

/**
 * Remove um equipamento após confirmação.
 * @param {string} uuid - UUID do equipamento a ser removido.
 */
async function removeEquipment(uuid) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await equipmentStore.actions.remove(uuid);
    dialogs.notifyRemoved();
  }
}

/**
 * Armazena o responsável terceiro associado a um equipamento.
 * @param {Object} entity - Dados da entidade responsável.
 * @param {number} entity.id - ID da entidade.
 */
async function storeThirdPartyResponsible(entity) {
  const payload = {
    equipment_id: equipmentId,
    entity_id: entity.id,
  };
  await equipmentStore.actions.storeThirdPartyResponsible(payload);
  dialogs.notify();
  listItems();
}

/**
 * Retorna a classe CSS correspondente ao status do equipamento.
 * @param {number} status - ID do status do equipamento.
 * @returns {string} - Classe CSS.
 */
function getStatusClass(status) {
  switch (status) {
    case EquipmentStatusEnum.keys.INACTIVE: return "inactive-status";
    case EquipmentStatusEnum.keys.UNAVAILABLE: return "unavailable-status";
    case EquipmentStatusEnum.keys.ACTIVE: return "active-status";
    default: return "default-status";
  }
}

/**
 * Retorna o ícone correspondente ao status do equipamento.
 * @param {number} status - ID do status do equipamento.
 * @returns {string} - Caminho do ícone.
 */
 function getStatusIcon(situationStatus, equipmentStatus) {
  if (situationStatus === equipmentSituationStatusEnum.keys.RENTED) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.DAMAGED) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.OUT_OF_USE) {
   if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/power_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/power_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/power_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.SOLD) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/power_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/power_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/power_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.MAINTENANCE) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.OPERATING) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/ok-grenn.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/ok_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/ok--v1_danger.png";
    }
    return "/img/icons/icons8/ios/ok-grenn.png";
  }
  
  if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
    return "/img/icons/icons8/ios/ok-grenn.png";
  } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
    return "/img/icons/icons8/ios/ok_warning.png";
  } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
    return "/img/icons/icons8/ios/ok--v1_danger.png";
  }
  return "/img/icons/icons8/ios/ok-grenn.png";
}

/**
 * Atuliza listagem de centrais alocadas, para não precisar recarregar a página
 * @param {object} plants_updated_after_allocation - equipamento atualizado contendo suas centrais alocadas
 */
function updateAllocatedPlants(plants_updated_after_allocation) {
  const equipmentId = plants_updated_after_allocation.id;
  let foundEquipment = $_equipment_listed.value.items.find((item) => item.id == equipmentId);
  
  if (foundEquipment) {
    // Extrair os nomes das centrais do array de alocação
    let updatedPlantNames = plants_updated_after_allocation.allocation.map((plant) => plant.name);
    
    // Atualizar a string company_plants_names com os novos nomes
    foundEquipment.company_plants_names = updatedPlantNames.join(',');
  }
}

//#endregion

//#region Lifecycle
onMounted(() => {
  loadEquipmentSituation();
});
//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}

h5 {
  color: #2B2D32 !important;
  font-size: 14px !important;
}

.card-content {
  padding: 1rem 1.5rem;
}

.dropdown-toggle {
  border-radius: 16px !important;
  text-align: center;
  font-weight: 400 !important;
  min-width: 121px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  padding: 0 1rem;
}

.dropdown-toggle span {
  font-size: 12px !important;
}

.user-details {
  font-size: 12px !important;
}

.popover-content {
  margin: 0.5rem;
}

.card-value {
  font-weight: 400 !important;
  font-size: 15px;
  line-height: 20px;
  color: #2B2D32;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.card-value:first-of-type {
  font-weight: 500 !important;
}

.divider {
  border-top: 1px solid hsl(240, 1%, 38%, 15%);
  margin: 0.8rem 0;
}

.dropdown-item {
  align-items: center;
  display: flex;
}

.edit-item img,
.delete-item img {
  margin-right: 0.5rem;
}

.code-plate {
  margin: 0.8rem 0 0.2rem 0;
}


/* Status: Indisponível */
.unavailable-status {
  background-color: #FCF3F3;
  color: #DB4539;
}

/* Status: Inativo */
.inactive-status {
  background-color: #fef9f2;
  color: #F2B532;
}

/* Status: Ativo */
.active-status {
  background-color: #f2f7f3;
  color: #149E57;
}
</style>
