<template>
  <div>
    <base-header class="bg-gray-content pb-0">
      <div class="row align-items-center py-4 d-flex justify-content-between">
        <div class="col-lg-4 col-12 mb-n3">
          <div class="card">
            <div class="card-body" style="padding: 0.8rem !important">
              <div class="row mb-n2 show-md">
                <div class="col-2">
                <span class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img height="28px" src="/img/icons/test-tube.png"></span>
                </div>
                <div class="col-md-7 text-left mt-n1">
                  <h5><span class="font-weight-700"
                            style="text-transform: uppercase; font-size: 16px !important;">{{
                      mix_design.plant && mix_design.plant.name || ""
                    }}</span>
                  </h5>
                  <h5 class="font-weight-600 mt-n25"
                      style="text-transform: uppercase; font-size: 14px !important;">
                    <template v-if="mix_design.type">
                      {{ mix_design.type.technical_description || "" }}
                      <template v-if="mix_design.subtype">
                        {{ nextHeader(mix_design.subtype.technical_description) }}
                      </template>
                    </template>
                    <template v-if="mix_design.subtype">
                      {{ mix_design.subtype.technical_description || "" }}
                      <template v-if="mix_design.classification">
                        {{
                          nextHeader(
                            mix_design.classification.technical_description
                          )
                        }}
                      </template>
                    </template>
                    <template v-if="mix_design.classification">
                      {{ mix_design.classification.technical_description || "" }}
                    </template>
                  </h5>
                  <h5 class="mt-n25">
                    <template v-if="mix_design.slumpFlow">
                      {{ mix_design.slumpFlow.nomenclature || "" }}
                      <template v-if="mix_design.maximum_size">
                        {{ nextHeader(mix_design.maximum_size.description) }}
                      </template>
                    </template>
                    <template v-if="mix_design.maximum_size">
                      {{ mix_design.maximum_size.description || "" }}
                    </template>
                  </h5>
                </div>
                <div class="col-3 text-right mt-2">

                  <span class="badge pointer badge-default badge-light font-weight-400"
                        :class="MixDesignstatusClass[mix_design.status].class"
                        style="background: none; font-size: 14px;">  {{ MixDesignstatusClass[mix_design.status].name }} </span>
                </div>
              </div>
              <div class="row mb-n2 show-mobile">
                <div class="col-md-2 mb-2">
                <span class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img height="28px" src="/img/icons/test-tube.png"></span>
                </div>
                <div class="col-9 text-left mt-n1">
                  <h5><span class="font-weight-700"
                            style="text-transform: uppercase; font-size: 16px !important;">{{
                      mix_design.plant && mix_design.plant.name || ""
                    }}</span>
                  </h5>
                  <h5 class="font-weight-600 mt-n25"
                      style="text-transform: uppercase; font-size: 14px !important;">
                    <template v-if="mix_design.type">
                      {{ mix_design.type.technical_description || "" }}
                      <template v-if="mix_design.subtype">
                        {{ nextHeader(mix_design.subtype.technical_description) }}
                      </template>
                    </template>
                    <template v-if="mix_design.subtype">
                      {{ mix_design.subtype.technical_description || "" }}
                      <template v-if="mix_design.classification">
                        {{
                          nextHeader(
                            mix_design.classification.technical_description
                          )
                        }}
                      </template>
                    </template>
                    <template v-if="mix_design.classification">
                      {{ mix_design.classification.technical_description || "" }}
                    </template>
                  </h5>
                  <h5 class="mt-n25">
                    <template v-if="mix_design.slumpFlow">
                      {{ mix_design.slumpFlow.nomenclature || "" }}
                      <template v-if="mix_design.maximum_size">
                        {{ nextHeader(mix_design.maximum_size.description) }}
                      </template>
                    </template>
                    <template v-if="mix_design.maximum_size">
                      {{ mix_design.maximum_size.description || "" }}
                    </template>
                  </h5>
                </div>
                <div class="col-3 text-right mt-n2">
                  <span class="badge pointer badge-default badge-light font-weight-400"
                        :class="MixDesignstatusClass[mix_design.status].class"
                        style="background: none; font-size: 14px;">  {{ MixDesignstatusClass[mix_design.status].name }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        REFATORAR-->
        <div style="margin-top: -34px !important" class="show-md col-md-2 mb-1 mt-md-n4 p-1">
          <base-button
            @click.prevent="handleSetGroups(mix_design)"
            :disabled="!mixDesignUuid"
            block
            size="md"
            type="primary"
            class=""
          >
            <img src="/img/icons/icons8/ios/parallel-tasks.png" width="19" height="19"/>
            GRUPOS
          </base-button>
        </div>
        <div style="margin-top: -34px !important" class="show-md col-md-2 pb-0 mb-1 mt-md-n4 p-1"
        >
          <a href="#" @click.prevent="handleAdjustment()">
            <base-button block size="md" type="success" class="text-uppercase" :disabled="!mixDesignUuid">
              <img src="/img/icons/icons8/ios/wrench_white.png" width="19" height="19"/> ajuste
            </base-button>
          </a>
        </div>
        <div style="margin-top: -34px !important" class="show-md col-md-2 mb-1 mt-md-n4 p-1">
          <router-link :to="{ path: '/technology/mix/mixdesigns/edit/'+mixDesignUuid }">
            <base-button
              :disabled="!mixDesignUuid"
              block
              size="md"
              type="warning"
              class=""
            >
              <i class="fas fa-edit"></i> Editar
            </base-button>
          </router-link>
        </div>
        <div style="margin-top: -34px !important" class="show-md col-md-2 mb-1 mt-md-n4 p-1">
          <router-link :to="{ path: '/technology/mix/grid' }">
            <base-button
              :disabled="!mixDesignUuid"
              block
              size="md"
              type="light"
            >
              <img width="19" height="19" src="/img/icons/test-tube-white.png" class="mr-1">
              Formulações
            </base-button>
          </router-link>
        </div>

        <div  class="show-mobile col-md-2 mb-1">
          <base-button
            @click.prevent="handleSetGroups(mix_design)"
            :disabled="!mixDesignUuid"
            block
            size="md"
            type="primary"
            class=""
          >
            <img src="/img/icons/icons8/ios/parallel-tasks.png" width="19" height="19"/>
            GRUPOS
          </base-button>
        </div>
        <div  class="show-mobile col-md-2 mb-1"
        >
          <a href="#" @click.prevent="handleAdjustment()">
            <base-button block size="md" type="success" class="text-uppercase" :disabled="!mixDesignUuid">
              <img src="/img/icons/icons8/ios/wrench_white.png" width="19" height="19"/> ajuste
            </base-button>
          </a>
        </div>
        <div  class="show-mobile col-md-2 mb-1">
          <router-link :to="{ path: '/technology/mix/mixdesigns/edit/'+mixDesignUuid }">
            <base-button
              :disabled="!mixDesignUuid"
              block
              size="md"
              type="warning"
              class=""
            >
              <i class="fas fa-edit"></i> Editar
            </base-button>
          </router-link>
        </div>
        <div  class="show-mobile col-md-2 mb-1">
          <router-link :to="{ path: '/technology/mix/grid' }">
            <base-button
              :disabled="!mixDesignUuid"
              block
              size="md"
              type="light"
            >
              <img width="19" height="19" src="/img/icons/test-tube-white.png" class="mr-1">
              Formulações
            </base-button>
          </router-link>
        </div>
      </div>
      <div class="row mt-n4">
        <div class="col-md-12">
          <card>
            <div class="form-group row m-0 mb-1">
              <div
                class="col-md-2 pb-0 mb-1 pl-0 col-form-label form-control-label">
                <label class="form-control-label">
                  <img src="/img/icons/icons8/ios/rating.png" width="19"/>
                  Referência</label>
                <puzl-select
                  :clearable="false"
                  v-model="filter.reference_type"
                  @change="init()"
                  :items="[{id: 2, name: 'Referência comercial'}, {id: 1, name: 'Referência técnica'}]">
                </puzl-select>
              </div>
              <div
                class="col-md-3 pb-0 mb-1 pl-0 col-form-label form-control-label">
                <label class="form-control-label">
                  <img src="/img/icons/icons8/ios/progress-indicator_primary.png" width="19"/>
                  Status</label>
                <puzl-select
                  v-model="filter.status"
                  @change="init()"
                  multiple
                  :items="[{id: 1, name: 'Ativo'}, {id: 5, name: 'Comercial'}, {id: 2, name: 'Rascunho'}, {id: 0, name: 'Inativo'}]">
                </puzl-select>
              </div>
              <div
                class="col-md-2 pb-0 mb-1 pl-0 col-form-label form-control-label">
                <label class="form-control-label">
                  <img src="/img/icons/icons8/ios/3-circle_danger.png" width="19"/>
                  Nível de aplicação</label>
                <puzl-select
                  @change="init()"
                  v-model="level_feature_id"
                  :items="$_feature_levels"
                  label="label"
                  customKey="value"
                  :disabled="adjustment_blocks.includes(11)"
                  :loading="loading_filters"
                />
              </div>
              <div
                class="col-md-2 pb-0 mb-1 pl-0 col-form-label form-control-label">
                <label class="form-control-label">
                  <img src="/img/icons/icons8/ios/vlc_warning.png" width="19"/>
                  Slump final</label>
                <puzl-select
                  v-model="slump_final_id"
                  @change="init()"
                  :items="getSlumpFlow()"
                  label="nomenclature"
                  :disabled="adjustment_blocks.includes(12)"
                  :loading="loading_filters"
                />
              </div>
              <div
                class="col-md-2 pb-0 mb-1 pl-0 col-form-label form-control-label">
                <label class="form-control-label">
                  <img src="/img/icons/icons8/ios/test-tube_primary.png" width="19"/>
                  Ponto de carga</label>
                <puzl-select
                  @change="init()"
                  v-model="charge_point_adjustments"
                  :items="$_company_plant_charge_point_items"
                  custom-key="uuid"
                  :disabled="adjustment_blocks.includes(7)"
                  :loading="loading_company_plant_charge_points"
                />
              </div>
              <div class="col-md text-center form-control-label">
                <img width="30" src="https://img.icons8.com/ios/100/1a70b7/personal-video-recorder-menu.png"
                     alt="personal-video-recorder-menu">
                <h6> PUZL ADJUST POR TIPO</h6>
                <a href="#" @click.prevent="use_type = !use_type;init()">
                  <i v-if="use_type" class="fa-regular fa-circle-check text-success"></i>
                  <i v-else class="fa-solid fa-ban text-danger"></i>
                </a>
              </div>
            </div>
            <div class="row mb-n3 p-0">
                <transition name="fade">
                  <div class="col-md-12 " v-if="$_groups_without_references.length">
          <span class="display-4" style="font-size: 0.95rem">
           <i class="text-warning fa-solid fa-triangle-exclamation"></i>
            Há grupos sem referência comercial
          </span>
                    <br>
                    <a href="#"
                       @click.prevent="setGroupModalReference(item)"
                       class="text-danger font-weight"
                       v-for="(item, index) in $_groups_without_references">
                      {{ item.group_name }}
                      <span v-show="index !== $_groups_without_references.length - 1">|</span>
                    </a>
                  </div>

                </transition>
                <transition name="fade">
                  <div class="col-md-12" v-if="doesntHasLargeAggregate">
          <span class="display-4" style="font-size: 0.95rem">
           <i class="text-warning fa-solid fa-triangle-exclamation"></i>
            TABELA SEM AGREGADO GRAÚDO
          </span>
                  </div>
                </transition>
            </div>
          </card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <card class="mt-n3">
        <div class="grid-container" id="grid">
          <div>
            <!--            <div class="row">-->
            <!--              <div class="col-12">-->
            <!--                <i class="text-warning fa-solid fa-circle-exclamation"></i>-->
            <!--                <span class="display-4" style="font-size: 0.8rem">-->
            <!--                  Os parâmetros técnicos devem ser preenchidos para o cálculo do traço.-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->
            <div
              class="border rounded p-0 mt-3 bg-white grid-item--header"
              style="z-index: 2"
            >
              <div>
                <div class="border-header-default-bottom grid-item--header" style="background: white !important;">
                  <div class="row">
                    <div class="col-4">
                      <div style="margin-top: 10px" class="pl-2 pr-2 title-box font-weight-400  text-uppercase">
                        <img class="mr-1" src="/img/icons/icons8/ios/heart-puzzle_success.png" width="19" height="19">
                        Responsabilidades
                      </div>
                    </div>
                    <div class="col-8 text-right mb-1" style="margin-top: 6px">
                      <base-button style="border-radius: 4px !important;" @click.prevent="handleCreateMixe()"
                                   class="mr-2 text-uppercase" outline size="sm"
                                   type="success">
                        <i class="fas fa-plus"></i> novo
                      </base-button>
                    </div>
                  </div>
                </div>
                <div style="padding: 0.5rem 1rem !important">
                  <table class="table-without-border table grid-item--header table-sm" style="overflow:hidden;">
                    <tbody>
                    <tr v-if="key === 0" v-for="(item, key) in mix_design_resume.features">
                      <td class="align-middle grid-col--fixed-left p-1 pl-2">
                        <h5>
                        </h5>
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>
                      <td
                        class="align-middle text-center grid-col p-0"
                        v-for="(item, index) in Object.values(item.description)"
                      >
                        <BaseDropdownV2 size="sm" class="mt-1 mb-1">
                          <BaseDropdownItem @click="handleEditMixeResume(index)">
                            <img src="/img/icons/edit.png" width="19" class="mr-1"> Editar
                          </BaseDropdownItem>
                          <div class="dropdown-divider mt-0 mb-0"/>
                          <BaseDropdownItem v-show="item.status !== 1" @click="handleActivateMixe(index)">
                            <img src="/img/icons/icons8/ios/checked--v2_success.png" width="19" class="mr-1"> Ativar
                          </BaseDropdownItem>
                          <div v-show="item.status !== 1" class="dropdown-divider mt-0 mb-0"/>
                          <BaseDropdownItem @click="handleEditMixe(index)">
                            <img src="/img/icons/icons8/ios/heart-puzzle_success.png" width="19" height="19"
                                 class="mr-1"/> Responsabilidade
                          </BaseDropdownItem>
                          <div class="dropdown-divider mt-0 mb-0"/>
                          <BaseDropdownItem @click="handleSetAdjustmentMixe(index)">
                            <img src="/img/icons/icons8/ios/wrench_primary.png" width="19" height="19" class="mr-1"/>
                            Ajustar
                          </BaseDropdownItem>
                          <div class="dropdown-divider mt-0 mb-0"/>
                          <BaseDropdownItem @click="handleReplicate(index)">
                            <img src="/img/icons/icons8/ios/copy--v1-green.png" width="19" class="mr-1"> Replicar
                          </BaseDropdownItem>
                          <div class="dropdown-divider mt-0 mb-0"/>
                          <BaseDropdownItem @click="handleDeleteMixDesignMixe(index)">
                            <img src="/img/icons/icons8/ios/delete--v1_danger.png" width="19" class="mr-1"> Excluir
                          </BaseDropdownItem>
                        </BaseDropdownV2>
                      </td>
                    </tr>
                    <tr v-for="(item, key) in mix_design_resume.features">
                      <td class="align-middle grid-col--fixed-left p-1 pl-2">
                        <div class=" title-box  text-uppercase"> {{ item.label }}</div>
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>

                      <td
                        v-on:mouseover="activeCol(index)"
                        class="align-middle text-center grid-col p-0"
                        v-for="(item, index) in Object.values(item.description)"
                      >
                        <div class="mt-2">
                          <div class=" title-box  text-uppercase"> {{ item.value || '-' }}</div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="key === 0" v-for="(item, key) in mix_design_resume.features">
                      <td class="align-middle grid-col--fixed-left p-1 pl-2">
                        &nbsp;
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>
                      <td
                        class="align-middle text-center grid-col p-0"
                        v-for="(item, index) in Object.values(item.description)"
                      >
                        <div v-if="item.status === 1" class="mt-2 mb-2 mb-md-0 mini-card-green"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> Ativo </h4>
                        </div>
                        <div v-if="item.status === 0" class="mt-2 mb-2 mb-md-0 mini-card-red"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> Inativo </h4>
                        </div>
                        <div v-if="item.status === 5" class="mt-2 mb-2 mb-md-0 mini-card-blue"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> Comercial </h4>
                        </div>
                        <div v-if="item.status === 2" class="mt-2 mb-2 mb-md-0 mini-card-grey"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> Rascunho </h4>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <collapse class="border rounded p-0 mt-3 card-body-molde">
              <collapse-item
                :is-active="true"
                class="header-gray card-body-molde"
                class-body="p-0"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="p-0 m-0">
                  <td class="align-middle" :class="scrollLeft > 0 && 'pl-2'"
                      style="background: none;font-size: 13px !important;font-weight: 500 !important;">
                    <div class="pr-2 title-box font-weight-400 text-uppercase">
                      <img class="mr-1" src="/img/icons/icons8/ios/blockly-black.png" width="19" height="19"> Parâmetros
                      Técnicos
                    </div>
                  </td>
                </h5>
                <table class="table table-without-border" style="z-index: 2;overflow: hidden">
                  <tbody>
                  <tr
                    v-for="(parameter,
                      index) in mix_design_resume.technical_parameters"
                  >
                    <td class="align-middle grid-col--fixed-left p-1 pl-2">
                      <div class=" title-box  text-uppercase">
                        {{ parameter.name }}
                      </div>
                    </td>
                    <td class="align-middle text-center grid-col p-0">
                      &nbsp;
                    </td>
                    <td
                      v-on:mouseover="activeCol(key)"
                      class="align-middle text-center grid-col p-0"
                      v-for="(value, key) in parameter.values"
                    >
                      <div class=" title-box   text-uppercase">
                        {{ value || '-' }}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </collapse-item>
            </collapse>


            <collapse class="border rounded p-0 mt-3 card-body-molde">
              <collapse-item
                :is-active="true"
                class="header-gray card-body-molde"
                class-body="p-0"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="p-0 m-0">
                  <td class="align-middle" :class="scrollLeft > 0 && 'pl-2'"
                      style="background: none;font-size: 13px !important;font-weight: 500 !important;">
                    <div style="text-transform: none !important;"
                         class="pr-2 title-box font-weight-400 text-uppercase">
                      <img class="mr-1" src="/img/icons/icons8/ios/blockly-black.png" width="19" height="19"> TEORES E
                      QUANTIDADES
                      (KG/M³)
                    </div>
                  </td>
                </h5>
                <table class="table table-without-border" style="overflow: hidden">
                  <tbody>
                  <tr>
                    <td
                      @click.prevent="show_costs = !show_costs"
                      class=" p-1 pointer"
                    >
                      <template v-if="show_costs">
                        &nbsp;<i class="fa-regular fa-eye-slash"></i> OCULTAR PREÇOS
                      </template>
                      <template v-else>
                        &nbsp;<i class="fa-regular fa-eye"></i> EXIBIR PREÇOS
                      </template>
                    </td>
                    <td class="align-middle text-center grid-col p-2">
                      <span class="">M.E. (g/cm³)</span>
                    </td>
                    <td
                      class="align-middle text-center grid-col p-2"
                      v-for="value in sumValues"
                    >
                      <div class="text-success   title-box   text-uppercase">
                        {{
                          division(value)
                            .toString()
                            .replace(".", ",")
                        }}
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td
                      class="align-middle p-1 text-right"
                    >
                      &nbsp;
                    </td>
                    <td class="align-middle text-center grid-col p-2">
                      <span v-if="show_costs" class="">CUSTO MCC</span>
                    </td>
                    <td
                      class="align-middle text-center grid-col p-2"
                      v-for="value in sumPrices"
                    >
                      <div v-if="show_costs" class="font-weight-400 title-box text-danger text-uppercase">
                        {{
                          value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                          })
                        }}
                        / m³
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(product, index) in mix_design_resume.groups">
                    <td class="align-middle grid-col--fixed-left-2 p-1">
                      <div
                        class="p-1 pl-2"
                        :class="[!product.reference ? 'bg-danger' : '']"
                      >
                        <h4 :class="[!product.reference ? 'text-white' : '']">
                            <span class="font-weight-400" v-if="product.reference">
                              {{ product.reference.product_name }}
                              ({{ product.name }})
                            </span>
                          <span class="font-weight-400" v-else>
                              {{ product.name }}
                            </span>
                          <a href="#" @click.prevent="handleReference(product.cmc_group_id)">
                            <img src="/img/icons/icons8/ios/sorting-arrows_warning.png" width="24"/>
                          </a>
                          <a href="#" class="ml-2" @click.prevent="handleGetMcc(product.cmc_group_id)">
                            <img v-show="product.reference" src="/img/icons/icons8/ios/chemical-plant_primary.png"
                                 width="24"/>
                            <img v-show="!product.reference" src="/img/icons/icons8/ios/chemical-plant_white.png"
                                 width="24"/>
                          </a>
                        </h4>
                        <h4 class="small mt-n2" v-if="product.reference">
                          {{ product.reference.supplier_name }}
                          ({{ product.reference.origin_name }})
                        </h4>
                        <h4 class="small text-white" v-else>
                          Referência não informada
                        </h4>
                      </div>
                    </td>
                    <td class="align-middle text-center grid-col p-0">
                      <div v-if=" product.reference &&
                              product.reference.specific_gravity"
                           class="text-success title-box  text-uppercase">
                        {{ product.reference.specific_gravity }}
                      </div>
                      <div v-else>-</div>
                      <template v-if="show_costs">
                        <div v-if="product.reference && product.reference.price"
                             class="font-weight-400 text-danger title-box   text-uppercase">
                          {{
                            product.reference.price.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                              minimumFractionDigits: 2
                            })
                          }}
                          /kg
                        </div>
                        <div v-else class="text-danger small">-</div>
                      </template>
                    </td>
                    <td
                      v-on:mouseover="activeCol(key)"
                      class="align-middle text-center grid-col p-0"
                      v-for="(item, key) in product.values"
                    >
                      <div
                        class=" title-box text-uppercase">
<!--                        categoria fixa água cmc_categories-->
                        {{ product.category_id == 6 ? Math.round(formatValue(item.value) || 0) : formatValue(item.value) || 0  }} ({{
                          formatValue(item.percentage)
                        }})
                      </div>
                      <template v-if="show_costs">
                        <div
                          class="font-weight-400 text-danger title-box   text-uppercase">
                          R$ {{
                            priceMcc(
                              item.value,
                              product.reference ? product.reference.price : 0
                            )
                          }} ({{
                            formatPercentage(
                              key,
                              priceMcc(
                                item.value,
                                product.reference ? product.reference.price : 0
                              )
                            )
                          }}%)
                        </div>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </collapse-item>
            </collapse>

            <div
              class="border rounded p-0 mt-3 bg-white grid-item--header"
              style="z-index: 2"
            >
              <div>
                <div class="border-header-default-bottom grid-item--header" style="background: white !important;">
                  <div class="row">
                    <div class="col-4">
                      <div style="margin-top: 10px" class="pl-2 pr-2 mb-2 title-box font-weight-400  text-uppercase">
                        <img class="mr-1" src="/img/icons/icons8/ios/heart-puzzle_success.png" width="19" height="19">
                        Configuração
                      </div>
                    </div>
                  </div>
                </div>
                <div style="padding: 0.5rem 1rem !important">
                  <table class="table table-without-border grid-item--header table-sm" style="overflow:hidden;">
                    <tbody>
                    <tr>
                      <td class="align-middle grid-col--fixed-left p-1 pl-2" style="">
                        <div class=" title-box  text-uppercase"> Exige aprovação técnica de programação
                        </div>
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>
                      <td
                        class="align-middle text-center grid-col p-0"
                        v-for="(item, index) in mix_design_resume.configurations"
                      >
                        <div @click.prevent="setRequireTechnicalSchedule(1, item.id, index)"
                             v-if="item.require_technical_schedule === 0"
                             class="pointer mt-2 mb-2 mb-md-0 mini-card-red"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> Não </h4>
                        </div>
                        <div @click.prevent="setRequireTechnicalSchedule(0, item.id, index)" v-else
                             class="pointer mt-2 mb-2 mb-md-0 mini-card-green"
                             style="display: inline-flex; align-items: center;"><h4
                          class="font-weight-normal m-0 p-0"> SIM </h4>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle grid-col--fixed-left p-1 pl-2" style="">
                        <div class=" title-box  text-uppercase"> Informações de nota fiscal</div>
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>
                      <td
                        class="align-middle text-center grid-col p-0"
                        v-for="item in mix_design_resume.configurations"
                      >
                        <el-popover trigger="click" placement="bottom"
                                    class="p-0  m-0">
                          <div class="row mt-2">
                            <div class="col-md-12">
                         <textarea @input="setObservation(item)" rows="8" maxlength="400"
                                   class="form-control"
                                   v-model="item.observation"/>
                            </div>
                          </div>
                          <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                            <img v-if="!item.observation" class="pointer mt-2"
                                 style="height: 19px" src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"/>
                            <img v-else class="pointer mt-2"
                                 style="height: 19px"
                                 src="/img/icons/icons8/ios/speech-bubble-with-dots--v1-primary.png"/>
                          </div>
                        </el-popover>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle grid-col--fixed-left p-1 pl-2" style="">
                        <div class=" title-box  text-uppercase"> Última alteração
                        </div>
                      </td>
                      <td class="align-middle text-center grid-col p-0">
                        &nbsp;
                      </td>
                      <td
                        class="align-middle text-center grid-col p-0"
                        v-for="item in mix_design_resume.configurations"
                      >
                        <el-popover trigger="click" placement="bottom"
                                    class="p-0  m-0">
                          <div class="col-12">
                          <span>
                            <span class="h4">
                              {{ item.user_name }}
                            </span>
                            <br>
                            <span>
                              {{ item.updated_at | parseDate }}
                            </span>
                          </span>
                          </div>
                          <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="19px" height="19px"
                                 class="pointer">
                          </div>
                        </el-popover>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <modal-reference
      @getWithoutReference="getWithoutReference()"
      @submit="fetchResume()"
      ref="modalReference"
    ></modal-reference>
    <ModalAdjustment @fetchMixes="init" ref="ModalAdjustmentMixDesigns" :item="selected"/>
    <modal-adjustment-mixe @fetchMixes="init" ref="ModalAdjustmentMixDesignsMixe" :item="selected"/>
    <slide-fade-modal @close="init" size="md" ref="slide-fade-modal" title="MAPA DE PONTO DE CARGA" platform="default"
                      icon="physics-white">
      <div class="row">
        <div class="col-6">
          <h5 class="h3 new-default-black-font p-1 mb-0">
            {{ selected_group }}
          </h5>
        </div>
        <div class="col-6">
          <base-button style="border-radius: 4px !important;" @click.prevent="handleCreateAdjustment"
                       class="text-uppercase" block size="sm" type="success">
            <i class="fas fa-plus"></i> Incluir novo MCC
          </base-button>
        </div>
      </div>
      <hr class="mt-3 mb-3">
      <div class="row">
        <div class="col-md-12">
          <card style="overflow: auto;height: calc(100vh - 165px);">
            <div class="row card-wrapper" v-show="loadingSkeleton">
              <div class="col-lg-12 mb-n4 mt-2" v-for="item in 3" :key="item">
                <card>
                  <skeleton-puzl type="button"/>
                  <skeleton-puzl type="button"/>
                  <skeleton-puzl type="button"/>
                  <br>
                  <skeleton-puzl type="button"/>
                  <skeleton-puzl type="button"/>
                </card>
              </div>
            </div>
            <PuzlEmptyData :with-card="false" v-if="!cmcAdjustments.length && !loadingSkeleton"></PuzlEmptyData>
            <div class="row card-wrapper" v-if="cmcAdjustments.length && !loadingSkeleton">
              <div class="col-lg-12 mb-n4 mt-2" v-for="(cmcAdjustment, index) in cmcAdjustments" :key="index">
                <!-- Basic with action button -->
                <card
                  :class="
              cmcAdjustment.color === 0
                ? 'card-border-top-success'
                : cmcAdjustment.color === 1
                ? 'card-border-top-warning'
                : cmcAdjustment.color === 2
                ? 'card-border-top-primary'
                : cmcAdjustment.color === 3
                ? 'card-border-top-danger'
                : ''
            "
                >
                  <!-- Card header -->
                  <div class="row align-items-center mb-3">
                    <div class="col-9 justify-content-center pr-0">
                      <p class="pb-0 mb-0 pr-1">
                        <b>{{ cmcAdjustment.charge_point.name }}</b>
                        | {{ cmcAdjustment.company_plant.name }}
                      </p>
                    </div>
                    <div class="col-3 text-right pl-0">
                      <base-dropdown menuOnRight>
                        <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                          <i class="fas fa-cog"></i>
                        </base-button>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item" @click="handleEditCmcAdjustment(cmcAdjustment.uuid)">
                          <i class="fas fa-edit text-warning"></i>
                          Editar
                        </a>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item" @click="handleDeleteCmcAdjustment(cmcAdjustment.uuid)">
                          <i class="fas fa-times text-danger"></i>
                          Excluir
                        </a>
                      </base-dropdown>
                    </div>
                    <div class="col-9 justify-content-center pr-0">
                      <p class="pb-0 mb-0 pr-1 mt-n2">
                        <b
                        >{{ cmcAdjustment.cmc_group.name }}
                          <a @click.prevent="$refs['modal-reference'].handleOpenModal(cmcAdjustment)" href="#"
                             class="text-primary">
                            <i class="zoom fas fa-star"></i>
                          </a>
                        </b>
                        <br/>{{ cmcAdjustment.cmc_category.name }}
                      </p>
                      <p class="pb-0 mb-0 pr-1 mt-n2">
                        <b>{{ cmcAdjustment.cmc.product }}</b>
                        <br/>{{ cmcAdjustment.cmc.supplier }} ({{ cmcAdjustment.cmc.supplier_origin }})
                      </p>
                    </div>
                    <div class="col-12 justify-content-center mt-n1">
                      <hr class="m-0" type="primary"/>
                    </div>
                    <div class="col-9 justify-content-center pr-0">
                      <p v-for="(dosage, index) in cmcAdjustment.dosage_locations" :key="index"
                         class="pb-0 mb-0 pr-1 pt-1 text-success">
                        <b>{{ dosage.name }}</b>
                        <b v-if="dosage && dosage.code != 0" class="text-capitalize">({{ dosage.dosage_location }}
                          {{ dosage.code }})</b>
                        <b v-if="dosage && dosage.code === 0" class="text-capitalize mt-n1">{{
                            dosage.dosage_location
                          }}</b>
                      </p>
                      <p v-if="!cmcAdjustment.dosage_locations || !cmcAdjustment.dosage_locations[0]"
                         class="pb-0 mb-0 pr-1 pt-1">
                        <b>Nenhum informado</b>
                      </p>
                      <p class="small text-muted m-0 pt-1 pb-3">Locais de dosagem ({{
                          cmcAdjustment.measured_unit.initials
                        }})</p>
                    </div>
                  </div>
                  <!-- Ajustes -->
                  <collapse class="border rounded p-0 mt-3">
                    <collapse-item name="1" class="header-gray card-body-molde"
                                   back-ground="border-header-primary-bottom">
                      <h5 slot="title" class="mb-0">Ajustes</h5>
                      <div class="row">
                        <div class="col-md-12 justify-content-center">
                          <base-button
                            block
                            slot="title-container"
                            type="dark"
                            size="sm"
                            class="rounded"
                            outline
                            @click="resetAll(cmcAdjustment)"
                          >
                            zerar todas
                          </base-button>
                        </div>
                      </div>

                      <div class="row justify-content-md-center">
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.binder > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.binder < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            aglomerante
                          </h5>
                          <base-input input-group-classes="input-group-sm">
                            <input
                              maxlength="4"
                              inputmode="numeric"
                              v-model="cmcAdjustment.binder"
                              v-on:input="
                          cmcAdjustment.binder = formatString($event.target.value, limit.binder.bottom, limit.binder.top, '', cmcAdjustment)
                        "
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment)"
                            v-model="cmcAdjustment.binder"
                            :connect="true"
                            :options="{ step: 1, range: { min: parseInt(limit.binder.bottom), max: parseInt(limit.binder.top) } }"
                            :decimals="0"
                          >
                          </base-slider>
                        </div>
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.water > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.water < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            água
                          </h5>
                          <base-input input-group-classes="input-group-sm">
                            <input
                              maxlength="4"
                              v-on:input="
                          cmcAdjustment.water = formatString($event.target.value, limit.water.bottom, limit.water.top, '', cmcAdjustment)
                        "
                              inputmode="numeric"
                              v-model="cmcAdjustment.water"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment)"
                            v-model="cmcAdjustment.water"
                            :connect="true"
                            :options="{ step: 1, range: { min: parseInt(limit.water.bottom), max: parseInt(limit.water.top) } }"
                            :decimals="0"
                          >
                          </base-slider>
                        </div>
                      </div>

                      <div class="row justify-content-md-center">
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.content_mortar > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_mortar < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            teor de argamassa
                          </h5>
                          <base-input input-group-classes="input-group-sm">
                            <input
                              maxlength="6"
                              v-on:input="
                          cmcAdjustment.content_mortar = formatString(
                            $event.target.value,
                            limit.content_mortar.bottom,
                            limit.content_mortar.top,
                            1,
                            cmcAdjustment
                          )
                        "
                              inputmode="numeric"
                              v-model="cmcAdjustment.content_mortar"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment)"
                            v-model="cmcAdjustment.content_mortar"
                            :connect="true"
                            :options="{
                        step: 0.1,
                        range: { min: parseInt(limit.content_mortar.bottom), max: parseInt(limit.content_mortar.top) },
                      }"
                            :decimals="1"
                          >
                          </base-slider>
                        </div>
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.total_air > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.total_air < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            ar total
                          </h5>
                          <base-input input-group-classes=" input-group-sm">
                            <input
                              maxlength="6"
                              v-on:input="
                          cmcAdjustment.total_air = formatString(
                            $event.target.value,
                            limit.total_air.bottom,
                            limit.total_air.top,
                            1,
                            cmcAdjustment
                          )
                        "
                              inputmode="numeric"
                              v-model="cmcAdjustment.total_air"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment)"
                            v-model="cmcAdjustment.total_air"
                            :connect="true"
                            :options="{ step: 0.1, range: { min: parseInt(limit.total_air.bottom), max: parseInt(limit.total_air.top) } }"
                            :decimals="1"
                          >
                          </base-slider>
                        </div>
                      </div>
                      <!-- aditivo a -->
                      <div v-if="cmcAdjustment.agent" class="row justify-content-md-center">
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.agent && cmcAdjustment.agent[0].value > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.agent && cmcAdjustment.agent[0].value < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            Aditivo A
                          </h5>
                          <base-input input-classes="form-control-sm">
                            <el-select
                              @change="resetAgent(cmcAdjustment, 0)"
                              filterable
                              v-model="cmcAdjustment.agent[0].id"
                              size="mini"
                              placeholder="Selecione"
                            >
                              <el-option label="Selecione" value=""></el-option>
                              <el-option
                                v-if="!cmcAdjustment.agent || agent.id !== cmcAdjustment.agent[1].id"
                                v-for="agent in agentGroups"
                                :key="agent.id"
                                :label="agent.group_name"
                                :value="agent.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input v-if="cmcAdjustment.agent && cmcAdjustment.agent[0].id"
                                      input-group-classes="input-group-sm mt-3">
                            <input
                              maxlength="7"
                              v-on:input="
                          cmcAdjustment.agent[0].value = formatDecimals(
                            $event.target.value,
                            limit.agent.bottom,
                            limit.agent.top,
                            2,
                            cmcAdjustment
                          )
                        "
                              v-model="cmcAdjustment.agent[0].value"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            v-if="cmcAdjustment.agent[0].id"
                            @input="updatedAgent(cmcAdjustment, true)"
                            v-model="cmcAdjustment.agent[0].value"
                            :connect="true"
                            :options="{ step: 0.01, range: { min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top) } }"
                            :decimals="2"
                          >
                          </base-slider>
                        </div>

                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.agent && cmcAdjustment.agent[1].value > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.agent && cmcAdjustment.agent[1].value < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            Aditivo B
                          </h5>
                          <base-input input-classes="form-control-sm">
                            <el-select
                              @change="resetAgent(cmcAdjustment, 1)"
                              filterable
                              v-model="cmcAdjustment.agent[1].id"
                              size="mini"
                              placeholder="Selecione"
                            >
                              <el-option label="Selecione" value=""></el-option>
                              <el-option
                                v-if="!cmcAdjustment.agent || agent.id !== cmcAdjustment.agent[0].id"
                                v-for="agent in agentGroups"
                                :key="agent.id"
                                :label="agent.group_name"
                                :value="agent.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input v-if="cmcAdjustment.agent && cmcAdjustment.agent[1].id"
                                      input-group-classes="input-group-sm mt-3">
                            <input
                              maxlength="7"
                              v-on:input="
                          cmcAdjustment.agent[1].value = formatDecimals(
                            $event.target.value,
                            limit.agent.bottom,
                            limit.agent.top,
                            2,
                            cmcAdjustment
                          )
                        "
                              v-model="cmcAdjustment.agent[1].value"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <div>
                            <base-slider
                              v-if="cmcAdjustment.agent[1].id"
                              @input="updatedAgent(cmcAdjustment, true)"
                              v-model="cmcAdjustment.agent[1].value"
                              :connect="true"
                              :options="{ step: 0.01, range: { min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top) } }"
                              :decimals="2"
                            >
                            </base-slider>
                          </div>
                        </div>
                      </div>
                      <!--Start Observation-->
                      <div class="row">
                        <div class="col-md-12 pt-4">
                          <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="cmcAdjustment.observation"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        v-on:input="forceUpdate(cmcAdjustment)"
                      >
                      </textarea>
                          </base-input>
                        </div>
                      </div>
                      <div v-if="cmcAdjustment.controls == 1" class="row">
                        <div class="col-md-6 mt-4">
                          <base-button block type="secondary" @click="restoreData(cmcAdjustment)">Cancelar</base-button>
                        </div>
                        <div class="col-md-6 mt-4">
                          <base-button block type="primary" @click="handleCmcAdjustmentUpdate(cmcAdjustment, index)">
                            Salvar
                          </base-button>
                        </div>
                      </div>

                      <!--Start Last Updated At-->
                      <div class="row mt-4 justify-content-sm-center">
                        <div class="col-md-6 text-center">
                          <small>{{ cmcAdjustment.updated_at | parseDate('DD MMM YYYY HH:mm:ss') }}</small>
                        </div>
                        <div class="col-md-6 text-center">
                          <small>{{ cmcAdjustment.user }}</small>
                        </div>
                      </div>
                    </collapse-item>
                  </collapse>
                  <collapse v-if="cmcAdjustment.content !== false" class="border rounded p-0 mt-3">
                    <collapse-item name="1" class="header-gray card-body-molde"
                                   back-ground="border-header-warning-bottom">
                      <h5 slot="title" class="mb-0">
                        Ajuste de teor
                        <i v-if="cmcAdjustment.content && cmcAdjustment.content != 0"
                           class="fas fa-exclamation-triangle text-default pl-2"></i>
                      </h5>
                      <div class="row">
                        <div class="col-md-12 justify-content-center">
                          <base-button
                            block
                            slot="title-container"
                            type="dark"
                            size="sm"
                            class="rounded"
                            outline
                            @click="resetContent(cmcAdjustment)"
                          >
                            zerar todas
                          </base-button>
                        </div>
                      </div>
                      <div class="row pt-3">
                        <div class="col-12 pr-1">
                          <base-button slot="title-container" type="white" class="p-0 pr-1 pl-1 rounded m-0">
                            <i class="fas fa-info"></i>
                          </base-button>
                        </div>
                      </div>

                      <div class="row justify-content-md-center pt-3">
                        <div class="col-md-7 text-center">
                          <h5 :class="getContentCardClass(cmcAdjustment.content)">Teor do MCC</h5>
                          <base-input input-group-classes=" input-group-sm mt-3">
                            <input
                              maxlength="6"
                              v-on:input="cmcAdjustment.content = formatString($event.target.value, '-100', '100', '', cmcAdjustment, 1)"
                              inputmode="numeric"
                              v-model="cmcAdjustment.content"
                              @blur="checkContentLimits(cmcAdjustment)"
                              class="form-control form-control-sm"
                              :class="cmcAdjustment.content > 0 ? 'is-valid' : cmcAdjustment.content < 0 ? 'is-invalid' : ''"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment, true, 1)"
                            v-model="cmcAdjustment.content"
                            :connect="true"
                            :options="{ step: 1, range: { min: -100, max: 100 } }"
                            :decimals="0"
                          >
                          </base-slider>
                        </div>
                      </div>
                      <div class="row justify-content-md-center">
                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.content_min && cmcAdjustment.content_min > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_min && cmcAdjustment.content_min < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            Limitar teor mínimo
                          </h5>
                          <base-input input-group-classes="input-group-sm mt-3">
                            <input
                              maxlength="6"
                              v-on:input="cmcAdjustment.content_min = formatString($event.target.value, '0', '100', '', cmcAdjustment, 1)"
                              v-model="cmcAdjustment.content_min"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment, true, 1)"
                            v-model="cmcAdjustment.content_min"
                            :connect="true"
                            :options="{ step: 1, range: { min: 0, max: 100 } }"
                            :decimals="0"
                          >
                          </base-slider>
                        </div>

                        <div class="col-md-6 text-center pt-2">
                          <h5
                            :class="
                        cmcAdjustment.content_max && cmcAdjustment.content_max > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_max && cmcAdjustment.content_max < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                          >
                            Limitar teor máximo
                          </h5>
                          <base-input input-group-classes="input-group-sm mt-3">
                            <input
                              maxlength="6"
                              v-on:input="cmcAdjustment.content_max = formatString($event.target.value, '0', '100', '', cmcAdjustment, 1)"
                              v-model="cmcAdjustment.content_max"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> % </small>
                            </template>
                          </base-input>
                          <base-slider
                            @input="updatedAgent(cmcAdjustment, true, 1)"
                            v-model="cmcAdjustment.content_max"
                            :connect="true"
                            :options="{ step: 1, range: { min: 0, max: 100 } }"
                            :decimals="0"
                          >
                          </base-slider>
                        </div>
                      </div>
                      <!--Start Observation-->
                      <div class="row">
                        <div class="col-md-12 pt-4">
                          <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="cmcAdjustment.observation_content"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        v-on:input="forceUpdate(cmcAdjustment, 1)"
                      >
                      </textarea>
                          </base-input>
                        </div>
                      </div>
                      <div v-if="cmcAdjustment.content_controls === 1" class="row">
                        <div class="col-md-6 mt-4">
                          <base-button block type="secondary" @click="restoreContentData(cmcAdjustment)">Cancelar
                          </base-button>
                        </div>
                        <div class="col-md-6 mt-4">
                          <base-button block type="primary" @click="handleCmcAdjustmentUpdate(cmcAdjustment, index)">
                            Salvar
                          </base-button>
                        </div>
                      </div>
                      <!--Start Last Updated At-->
                      <div class="row mt-4 justify-content-sm-center">
                        <div class="col-md-6 text-center">
                          <small>{{ cmcAdjustment.updated_at }}</small>
                        </div>
                        <div class="col-md-6 text-center">
                          <small>{{ cmcAdjustment.user }}</small>
                        </div>
                      </div>
                    </collapse-item>
                  </collapse>
                </card>
              </div>
            </div>
          </card>

        </div>
      </div>
    </slide-fade-modal>
    <modal-edit-adjustment @updatedCmcAdjustment="handleInit;" ref="editCmc"></modal-edit-adjustment>
    <modal-create @createdCmcAdjustments="updateMcc" ref="ModalCreateAdjustment"></modal-create>
    <modal-create-mixe @submit="init" ref="ModalCreateMixe"/>
    <ModalEditMixe @submit="init" ref="ModalEditMixe"/>
    <ModalGroup @submit="init();getOnlyMixDesign()" ref="ModalGroup"/>
    <ModalEditMixeResume @submit="init" ref="ModalEditMixeResume"/>
  </div>
</template>

<script>

import PuzlSelect from "@/components/PuzlSelect.vue";
import ModalAdjustment from "@/views/Modules/Technology/Mix/Shared/_ModalAdjustment";
import ModalAdjustmentMixe from "@/views/Modules/Technology/Mix/Shared/_ModalAdjustmentMixe.vue";
import ModalReference from "./_ModalReference";
import {debounce, formatErrorValidation, round1} from "@/plugins";
import {
  period_types,
  value_z_to_percentage,
  nextHeader
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {mapGetters} from "vuex";
import SlideFadeModal from "@/components/SlideFadeModal.vue";
import list from '/src/views/Modules/Technology/Adjustment/Shared/mixins/list'
import cursorPaginate from "@/mixins/cursorPaginate";
import adjustmentMixin from "@/views/Modules/Technology/Adjustment/Mixins/adjustment";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import {SkeletonPuzl} from "@/components";
import ModalEditAdjustment from "@/views/Modules/Technology/Adjustment/Shared/_ModalEditCmcAdjustment.vue";
import ModalCreate from "@/views/Modules/Technology/Adjustment/Shared/_ModalCreate.vue";
import ModalCreateMixe from './_ModalCreateMixe.vue'

import BaseDropdownV2 from '@/components/Utils/BaseDropdown.vue'
import BaseDropdownItem from "@/components/Utils/BaseDropdownItem.vue";
import ModalEditMixe from '../Shared/_ModalEditMixe.vue';
import ModalGroup from '../Shared/_ModalGroup.vue'
import ModalEditMixeResume from '../Shared/_ModalEditMixeResume.vue'
import Swal from "sweetalert2";

let filters = {
  status: [],
  reference_type: 2,
  plant_id: ""
};
export default {
  components: {
    ModalEditAdjustment,
    SkeletonPuzl,
    PuzlEmptyData,
    ModalReference,
    PuzlSelect,
    ModalAdjustment,
    ModalAdjustmentMixe,
    SlideFadeModal,
    ModalCreate,
    ModalCreateMixe,
    BaseDropdownV2,
    BaseDropdownItem,
    ModalEditMixe,
    ModalGroup,
    ModalEditMixeResume
  },
  name: "IndexResume",
  mixins: [cursorPaginate, adjustmentMixin, list],
  computed: {
    ...mapGetters({
      mix_design: "MixDesigns/show",
      mix_design_resume: "mixDesignResume/getResume",
      $_feature_levels: 'mixConcretePiece/feature_levels',
      $_slump_flows: 'mixSlumpFlow/fetch',
      $_company_plant_charge_point_items: "plantChargePoint/fetch",
      cmcAdjustments: "adjustmentCmc/fetch",
      groups: "cmcGroup/showGroupByCategory",
      $_groups_without_references: 'MixDesignMixe/fetchWithoutReferences',
    }),
    doesntHasLargeAggregate() {
      return this.mix_design.groups && this.mix_design.groups.findIndex(item => item.id === 3) === -1
    }
  },
  mounted() {
    this.filter.reference_type = 2
    this.show_costs = true
    this.loading_filters = true
    Promise.all([
      this.$store.dispatch("mixConcretePiece/getFeatureLevels"),
      this.$store.dispatch('mixSlumpFlow/fetchItems')
    ]).then(() => this.loading_filters = false)
    const grid = document.getElementById('grid')
    grid.addEventListener('scroll', this.onScroll)
    this.load();
  },
  data() {
    return {
      filter: filters,
      mixDesignUuid: this.$route.params.mix_design_uuid,
      reference: 2,
      selected_group: null /* Nome do grupo a ser usado no modal de referencias */,
      nextHeader: nextHeader,
      mixe_status: {},
      slump_flow_ids: [],
      adjustment_blocks: [],
      scrollLeft: 0,
      filter_mcc: { /* Filtro para ser usado na criação de MCC no slide fade modal*/
        cmc_group_id: null,
        category_id: null,
        company_plant_id: null
      },
      show_costs: true,
      type_id: null,
      use_type: false,
      loading_company_plant_charge_points: false,
      once: true,
      has_type_adjustment: false,
      loading_filters: false,
      level_feature_id: null,
      slump_final_id: null,
      charge_point_adjustments: null,
      company_plant_id: null,
      prevRoute: null,
      has_adjustment_type: false,
      has_type_mcc: false,
      active: false,
      sumValues: {},
      sumPrices: {},
      loading: false,
      selected: null,
      loading_all_reference: false,
      MixDesignstatusClass: {
        0: {
          class: 'badge-danger-bordered',
          name: 'Inativo',
        },
        1: {
          class: 'badge-success-bordered',
          name: 'Ativo',
        },
        2: {
          class: 'badge-secondary-bordered',
          name: 'Rascunho',
        }
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  methods: {
    setGroupModalReference(group) {
      this.$refs.modalReference.handleCreate(2, group.id, this.mix_design.plant.id);
    },
    handleSetGroups(mix_design) {
      this.$refs.ModalGroup.handleCreate(mix_design)
    },
    handleCreateAdjustment() {
      this.$refs["slide-fade-modal"].toggleShowComponent()
      this.$refs.ModalCreateAdjustment.handleCreateModal(this.filter_mcc);
    },
    updateMcc() {
      this.handleGetMcc(this.selected_group_id)
      this.init()
    },
    handleGetMcc(cmc_group_id) {
      let group = this.mix_design_resume.groups.find((item) => item.cmc_group_id == cmc_group_id)
      this.selected_group = group.name

      this.$store.dispatch('cmcGroup/show', group.cmc_group_id).then(response => {
        this.filter_mcc = {
          cmc_group_uuid: response.data.uuid,
          cmc_category_uuid: response.data.cmc_category.uuid,
          cmc_category_id: response.data.cmc_category.id,
          company_plant_uuid: this.mix_design.plant.uuid
        }
      })

      this.selected_group_id = cmc_group_id
      this.handleInit({
        cmc_group_id: cmc_group_id,
        company_plant_uuid: this.mix_design.plant.uuid
      })
      this.$refs["slide-fade-modal"].handleOpenModal()
    },
    /** verifica o scroll horizontal **/
    onScroll() {
      this.scrollLeft = document.getElementById('grid').scrollLeft
    },
    handleCreateAdjustmentList() {
      this.added = !this.added;
    },
    handleCreateMixe() {
      this.$refs.ModalCreateMixe.handleCreateMixe(this.mix_design)
    },
    getOnlyMixDesign() {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store
        .dispatch("MixDesigns/show", this.mixDesignUuid)
        .then(({data}) => {
          loader.hide()
        }).catch(() => {
          loader.hide()
        this.$Progress.finish();
      })
    },
    getWithoutReference() {
      this.loading_all_reference = true
      this.$store.dispatch('MixDesignMixe/fetchGroupsWithoutReference',
        this.$route.params.mix_design_uuid).then(() => {
        this.loading_all_reference = false
      })
    },
    getMixDesigns() {
      this.$store
        .dispatch("MixDesigns/show", this.mixDesignUuid)
        .then(({data}) => {
          this.adjustment_blocks = data.adjustment_blocks
          this.company_plant_id = data.plant.id
          this.loading_company_plant_charge_points = true
          this.$store
            .dispatch(
              "plantChargePoint/getByPlant",
              data.plant.uuid
            ).then(() => this.loading_company_plant_charge_points = false)
          this.slump_flow_ids = data.slump_flow_ids
          this.type_id = data.type.id
          this.$Progress.finish();
          this.fetchResume();
        }).catch(() => {
        this.$Progress.finish();
      });
    },
    init() {
      if (this.once) {
        this.once = false;
        this.$Progress.start();
        return this.getMixDesigns()
      }
      this.getWithoutReference()
      this.fetchResume();
    },
    handleClickFilterReference(reference) {
      if (this.filter.reference_type != reference) {
        this.filter.reference_type = reference;
        this.init();
      }
    },
    setRequireTechnicalSchedule(require_technical_schedule, id, index) {
      this.$Progress.start()
      return this.$Swal.confirmAction('Esse traço deve exigir aprovação técnica de programação?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let params = {
              require_technical_schedule: require_technical_schedule,
              id: id
            }
            let loader = this.$loading.show()
            this.$store.dispatch("MixDesignMixe/postSetRequireTechnicalSchedule", params).then(response => {
              loader.hide()
              this.$notify({type: 'success', message: response.message});
              this.$set(this.mix_design_resume.configurations[index], 'require_technical_schedule', require_technical_schedule)
            }).catch(() => loader.hide());
          }
        }).catch(() => this.$Progress.finish())
    },
    setObservation: debounce(function (item) {
      let params = {
        observation: item.observation,
        id: item.id
      }
      let loader = this.$loading.show()
      this.$store.dispatch("MixDesignMixe/postSetObservation", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    }, 500),
    formatValue(value) {
      // value = round1(parseFloat(value), 2)
      return value.toString().replace(".", ",");
    },
    handleValidButton(type) {
      const filtered = t => this.filter.status.includes(t);
      switch (type) {
        case 1:
          if (filtered(type)) {
            return this.loading ? "text-white bg-success" : "active";
          }
        case 2:
          if (filtered(type)) {
            return this.loading ? "text-white bg-default" : "active";
          }
        case 5:
          if (filtered(type)) {
            return this.loading ? "text-white bg-primary" : "active";
          }
        case 0:
          if (filtered(type)) {
            return this.loading ? "text-white bg-danger" : "active";
          }
      }
    },
    handleValidReferences(key) {
      if (this.filter.reference_type == 1 && key == 1) {
        return this.loading ? "text-white bg-indigo" : "active";
      }
      if (this.filter.reference_type == 2 && key == 2) {
        return this.loading ? "text-white bg-yellow" : "active";
      }
    },
    getSlumpFlow() {
      return this.$_slump_flows.filter((item) => this.slump_flow_ids.includes(item.id))
    },
    fetchResume() {
      let loader = this.$loading.show()
      this.loading = true;
      this.$Progress.start();
      this.filter.plant_id = this.mix_design.plant.id;
      let params = {
        filter: this.filter,
        uuid: this.mixDesignUuid,
        reference_type: this.reference,
        adjustments: {
          level_feature: this.level_feature_id,
          slump_flow_id: this.slump_final_id,
          company_plant_id: this.company_plant_id,
          type_id: this.use_type ? this.type_id : null,
          charge_point_adjustments: this.charge_point_adjustments
        }
      };
      this.$store
        .dispatch("mixDesignResume/fetchResume", params)
        .then(response => {
          for (var index in response.data.features) {
            let teste = response.data.features[index].description
            for (var key in teste) {
              this.mixe_status[key] = teste[key].status;
            }
          }
          this.has_type_adjustment = response.data.has_type_adjustment
          loader.hide()
          this.sumGroups(this.mix_design_resume.groups);
          this.$Progress.finish();
          this.loading = false;
        }).catch(() => {
        this.$Progress.finish();
        this.loading = false
        loader.hide()
      });
    },
    load() {
      this.filter.status = []
      this.filter.status.push(1, 5, 2);
      this.init();
    },
    sumGroups(groups) {
      let sumValues = {};
      let sumPrices = {};
      const format = value => value.toString().replaceAll(",", ".");
      const self = this;
      groups.forEach(function (item, index) {
        item.values.forEach(function (group, key) {
          const reference = item.reference ? item.reference.price : 0;
          let value = parseFloat(format(group.value));
          value = isNaN(value) ? 0 : value;

          let price = self.priceMcc(group.value, reference).replace(",", ".");
          price = isNaN(price) ? 0 : price;

          if (!sumValues[key]) {
            sumValues[key] = 0 + value;
            sumPrices[key] = 0 + parseFloat(price);
          } else {
            sumValues[key] += value;
            sumPrices[key] += parseFloat(price);
          }
        });
      });
      this.sumValues = sumValues;
      this.sumPrices = sumPrices;
      return groups;
    },
    handleDeleteMixDesignMixe(index) {
      const mixe_uuid = this.mix_design_resume.uuids[index]
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$Progress.start();
          this.$store.dispatch('MixDesignMixe/destroy', mixe_uuid).then((response) => {
            this.init()
            this.$Progress.finish()
            this.$notify({
              type: response.error_type,
              message: response.message
            })
          }).catch((error) => {
            if (error.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            }
          })
          this.$Progress.finish();
        }
      }).catch((error) => {
        this.$Progress.finish()
      })
    },
    async handleActivateMixe(index) {
      let loader = this.$loading.show()
      const mixe_uuid = this.mix_design_resume.uuids[index]
      const item = await this.$store.dispatch('MixDesignMixe/show', mixe_uuid)
      let mixe = item.data
      for (let key in mixe.items) {
        for (let item in mixe.items[key]) {
          if (item == "binder") {
            continue
          }
          if (!mixe.items[key][item].item_description) {
            loader.hide()
            return this.$notify({
              type: 'danger',
              message: 'Todos os itens de responsabilidade devem ser definidos para continuar!'
            });
          }
        }

      }
      for (let key in mixe.technical_parameter) {
        if (!mixe.technical_parameter[key]) {
          loader.hide()
          return this.$notify({
            type: 'danger',
            message: 'Todos os parametros devem ser preenchidos para continuar!'
          });
        }
      }
      let mixMixe = {
        ...mixe
      }
      mixMixe.status = 1
      mixMixe.save_and_activate = true
      mixMixe.items = mixMixe.items[mixMixe.id]

      delete mixMixe.items.binder

      for (let index in mixMixe.items) {
        mixMixe.items[index]['feature_id'] = mixMixe.items[index]['mix_feature_id']
      }

      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$store.dispatch('MixDesignMixe/update', mixMixe)
        .then(response => {
          this.$notify({
            type: 'success',
            message: response.message
          });
          this.init()
        }).catch(error => {
        if (error.data.confirm) {
          Swal.fire({
            title: error.data.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, substituir!',
            cancelButtonText: 'Não, mantenha',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            buttonsStyling: false,
          }).then(response => {
            if (response.isConfirmed) {
              this.saveAndActivate(mixMixe)
            } else {
              this.$Progress.finish()
            }
          })
        } else {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
          this.$Progress.finish()
        }
      }).finally(() => {
        loader.hide()
      })
    },
    saveAndActivate(mixMixe) {
      this.$store.dispatch('MixDesignMixe/saveAndActivate', mixMixe)
        .then(response => {
          this.loadingSave = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.init()
        }).catch(error => {
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        })
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    async handleEditMixeResume(index) {
      let loader = this.$loading.show()
      const mixe_uuid = this.mix_design_resume.uuids[index]
      const item = await this.$store.dispatch('MixDesignMixe/show', mixe_uuid)
      loader.hide()
      let itemMixe = item.data
      this.$refs.ModalEditMixeResume.handleOpenModal(itemMixe, this.mix_design)
    },
    async handleEditMixe(index) {
      let loader = this.$loading.show()
      const mixe_uuid = this.mix_design_resume.uuids[index]
      const item = await this.$store.dispatch('MixDesignMixe/show', mixe_uuid)
      loader.hide()
      let itemMixe = item.data
      for (let index in itemMixe.items) {
        delete itemMixe.items[index]['binder']
        itemMixe.items[index] = Object.values(itemMixe.items[index])
      }
      this.$refs.ModalEditMixe.handleEditMixe(itemMixe, this.mix_design)
    },
    handleReplicate(index) {
      const mixe_uuid = this.mix_design_resume.uuids[index]
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let loader = this.$loading.show()
      this.$store.dispatch('MixDesignMixe/replicate', mixe_uuid).then((response) => {
        this.$notify({
          type: 'success',
          message: response.message
        });
        this.init()
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    handleReference(cmc_group_id) {
      this.$refs.modalReference.handleCreate(
        this.filter.reference_type,
        parseInt(cmc_group_id),
        this.mix_design.plant.id
      );
    },
    formatPercentage(key, value) {
      if (Object.keys(this.sumPrices).length) {
        let teste =
          parseFloat(value.replace(",", ".")) / parseFloat(this.sumPrices[key]);
        teste = round1(parseFloat(teste) * 100, 2);
        return isNaN(teste) ? 0 : teste;
      } else {
        return 0;
      }
    },
    handleAdjustment() {
      this.selected = this.mix_design
      this.$refs.ModalAdjustmentMixDesigns.handleAdjustmentModal(this.mix_design)
    },
    handleSetColor(active, index) {
      let key = this.mixe_status[index];
      let color = "";
      switch (key) {
        case 1:
          color = "column_success";
          break;
        case 5:
          color = "column_commercial";
          break;
        case 0:
          color = "column_inative";
          break;
        default:
          color = "column_draft";
          break;
      }
      return "selected_column " + color;
      // if (active === index) {
      //   return "selected_column " + color;
      // }
      // return "";
    },
    priceMcc(value, price) {
      value = value.toString();
      price = price.toString();
      return this.formatValue(
        round1(value.replaceAll(",", ".") * price.replaceAll(",", "."), 2)
      );
    },
    division(val) {
      val = val / 1000;
      return val.toFixed(3);
    },
    activeCol(index) {
      this.active = index;
    },
    handleSetAdjustmentMixe(index) {
      let loader = this.$loading.show()
      const mixe_uuid = this.mix_design_resume.uuids[index]
      this.$store.dispatch('MixDesignMixe/show', mixe_uuid).then((response) => {
        this.selected = this.mix_design
        this.$refs.ModalAdjustmentMixDesignsMixe.handleAdjustmentModal(response.data)
        loader.hide()
      })
    },
  }
}
;
</script>

<style>
.table-fixed {
  display: block;
  overflow-x: auto;
  /*white-space: nowrap;*/
  overflow-y: auto;
}

.grid-col {
  min-width: 120px;
  flex-wrap: nowrap;
}

.grid-container {
  display: grid;
  /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
}

.grid {
  display: flex;
  flex-wrap: nowrap;
}

.grid-col {
  width: 120px;
  max-width: 120px;
}

.grid-item--header {
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: 0;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
}

.grid-col--fixed-left-2 {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
}

.grid-col--fixed-right {
  position: sticky;
  right: 0;
  z-index: 9998;
  background: white;
}

.grid-item {
  border: 1px solid gray;
}

/* width */
.grid-container::-webkit-scrollbar {
  width: 10px;
}

/* width */
.grid-container::-webkit-scrollbar:vertical {
  display: none;
}

/* Track */
.grid-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.grid-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.grid-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.selected_column {
  background-color: #e0f2f7;
}

.column_draft {
  background-color: #fafafa;
}

.column_inative {
  background-color: #ff6666;
}

.column_success {
  background-color: #d6f5d6;
}

.column_commercial {
  background-color: #ccd9ff;
}

.badge-success-bordered {
  color: rgb(26 183 37) !important;
  background: none;
  font-size: 20px;
  box-shadow: inset 0 0 1em white, 0 0 0.1em rgb(26 183 37)
}

.badge-danger-bordered {
  color: #e84747 !important;
  background: none;
  font-size: 20px;
  box-shadow: inset 0 0 1em white, 0 0 0.1em #e84747
}

.badge-secondary-bordered {
  color: grey !important;
  background: none;
  font-size: 20px;
  box-shadow: inset 0 0 1em white, 0 0 0.1em grey
}

.title-box {
  font-weight: 500 !important;
  font-size: 0.84rem;
}

.table-without-border > tbody > tr > td,
.table-without-border > tbody > tr > th {
  border: none !important;
}
</style>
