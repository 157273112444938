<template>
  <!-- If the icon is an SVG (found in AppIconVariants.variants.svg),
       render a div that uses CSS mask to “display” the SVG -->
  <div
    v-if="isSvgResult"
    v-bind="$attrs"
    v-on="$listeners"
    :style="maskStyle"
    :class="[color, { 'invert-on-hover': props.isHover }]"
    class="app-icon d-inline-block"
  ></div>

  <!-- Otherwise, render the PNG version using an <img> tag -->
  <img
    v-else
    :src="iconSrc"
    :width="computedWidth"
    :height="computedHeight"
    :class="{ 'invert-on-hover': props.isHover }"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script setup>
import { computed } from "vue";
import { AppIconVariants } from "./AppIconVariants";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Boolean} isHover - Inverte a cor do icon PNG para branco no hover.
 * @property {String} icon - Nome da chave do ícone em AppIconVariants.variants.icon
 * @property {Number|String|null} width - Largura do ícone
 * @property {Number|String|null} height - Altura do ícone
 * @property {String|null} align - Nome da propriedade CSS vertical-align do ícone
 * @property {String|null} color - Nome da chave da cor em AppIconVariants.variants.color
 */
/** @type {Props} */
const props = defineProps({
  icon: {
    type: String,
    required: true,
    default: AppIconVariants.defaultVariants.icon,
  },
  width: {
    type: [Number, String],
    default: 16,
    required: false,
  },
  height: {
    type: [Number, String],
    default: undefined,
    required: false,
  },
  align: {
    type: String,
    required: false,
    default: "center",
  },
  color: {
    type: String,
    default: AppIconVariants.defaultVariants.color,
    required: false,
    validator: (value) => {
      const isValid = value in AppIconVariants.variants.color;
      if (!isValid) {
        console.error(
          `Invalid fill color: ${value}. It should be one of the following:
          \n ${Object.keys(AppIconVariants.variants.color).join(", ")}`
        );
      }
      return isValid;
    },
  },
  isHover: {
    type: Boolean,
  },
});

// Helper function to normalize the icon name (remove ".svg" if present)
function normalizeIconName(icon) {
  return icon.endsWith(".svg") ? icon.replace(/\.svg$/, "") : icon;
}

// Compute the normalized icon name.
const normalizedIcon = computed(() => normalizeIconName(props.icon));

// Determine whether the requested icon is available as an SVG.
const isSvgResult = computed(
  () => normalizedIcon.value in AppIconVariants.variants.svg
);

// Compute the width in CSS units.
const computedWidth = computed(() => {
  return (typeof props.width === "string" && props.width.includes("px")) ||
    props.width === "auto"
    ? props.width
    : `${props.width}px`;
});

// Compute the height. If no height is provided, default to the computed width.
const computedHeight = computed(() => {
  if (props.height === undefined) {
    return computedWidth.value;
  }
  return (typeof props.height === "string" && props.height.includes("px")) ||
    props.height === "auto"
    ? props.height
    : `${props.height}px`;
});

// Compute the PNG source URL (if applicable).
const iconSrc = computed(() => {
  const isIconPng = props.icon in AppIconVariants.variants.png;
  if (isIconPng) {
    return `/img/icons${AppIconVariants.variants.png[props.icon]}`;
  }
  console.error(
    `Invalid icon name: ${props.icon}. It should be a key in AppIconVariants.js.`
  );
  return `/img/icons/icons8/ios/svgs/${AppIconVariants.defaultVariants.icon}`;
});

// When rendering an SVG via CSS mask, we compute the URL from your public folder.
const maskUrl = computed(() => {
  if (!isSvgResult.value) return "";
  const baseUrl = "/img/icons/icons8/ios/svgs/";
  return baseUrl + AppIconVariants.variants.svg[normalizedIcon.value];
});

// Build a style object for the SVG icon using CSS mask properties.
// The backgroundColor (from the valid color in AppIconVariants.variants.color)
// will “fill” the icon shape defined by the mask.
const maskStyle = computed(() => {
  if (!isSvgResult.value) return {};
  return {
    width: computedWidth.value,
    height: computedHeight.value,
    WebkitMask: `url('${maskUrl.value}') no-repeat ${props.align}`,
    mask: `url('${maskUrl.value}') no-repeat ${props.align}`,
    WebkitMaskSize: "contain",
    maskSize: "contain",
  };
});
</script>

<style lang="scss">
.app-icon svg {
  vertical-align: top;
}
</style>

<style scoped lang="scss">
@import "./AppIcon.scss";

.invert-on-hover:hover {
  filter: brightness(0) invert(1);
  color: white;
}
</style>
