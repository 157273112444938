<template>
  <div>
    <modal
      :show.sync="modal.create"
      class="individual-billing"
      size="xxl"
    >
      <template slot="header">
        <img src="/img/icons/icons8/ios/check--v1_primary.png" class="mr-2" width="24">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>

      <div class="row m-0 p-0" v-show="loadingSkeleton">
        <template v-for="i in 9">
          <div class="col-md-4 m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </template>
      </div>

      <div class="container-fluid" v-show="!loadingSkeleton">
        <div class="row">
          <!-- Informações sobre obra/cliente/contrato -->
          <div class="col-12 px-md-0 col-md-4">
            <div class="container-fluid mb-2 mb-md-0 px-0" style="padding-left: 0 !important;">
              <div class="row">
                <div class="col-lg-8">
                  <h3 class="new-default-black-font m-0 p-0">
                    {{ contract_proposal.code }}
                  </h3>
                  <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                    {{ params.customer_name }}
                  </h4>
                  <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                    {{ params.construction_name }}
                  </h4>
                  <hr class="gray-divider">
                  <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                    {{ params.company_plant }}
                  </h4>
                  <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                    {{ params.company_plant_issuer }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <!-- Cards informativos do cabeçalho do modal -->
          <div class="col-12 col-md-8 text-right px-md-0">
            <div class="header-individual-infos">
              <div class="individual-info-primary">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <img src="/img/icons/icons8/ios/test-tube_primary.png" width="25">
                    </div>
                    <div class="col-12">
                      <h3 class="new-default-black-font m-0 p-0">
                        Concreto
                      </h3>
                    </div>
                    <div class="col-12 col-md pr-0">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ $helper.toMoney(sumConcrete) }}
                      </h4>
                    </div>
                    <div class="pl-md-0 col-12 col-md text-right-md">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ sumVolume }}m <sup>3</sup>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="individual-info-gray">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <img src="/img/icons/icons8/ios/todo-list_secondary.png" width="25">
                    </div>
                    <div class="col-12">
                      <h3 class="new-default-black-font m-0 p-0">
                        Serviços
                      </h3>
                    </div>
                    <div class="col-12 col-md pr-0">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ $helper.toMoney(sumServices) }}
                      </h4>
                    </div>
                    <div class="pl-md-0 col-12 col-md text-right-md">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ sumVolumeServices }}m <sup>3</sup>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="individual-info-warning">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <img src="/img/icons/icons8/ios/add-square_warning.png" width="25">
                    </div>
                    <div class="col-12">
                      <h3 class="new-default-black-font m-0 p-0">
                        Adicionais
                      </h3>
                    </div>
                    <div class="col-12">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ $helper.toMoney(sumAdditionals) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="individual-info-success">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <img src="/img/icons/icons8/ios/add-success.png" width="25">
                    </div>
                    <div class="col-12">
                      <h3 class="new-default-black-font m-0 p-0">
                        Avulsos
                      </h3>
                    </div>
                    <div class="col-12">
                      <h4 class="new-default-black-font font-weight-normal m-0 p-0">
                        {{ $helper.toMoney(sumSingle) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-individual-infos">
              <div class="d-block"></div>
              <div class="d-none d-lg-block"></div>
              <div class="d-none d-lg-block"></div>
              <div class="individual-info-success pointer"
                style="margin-top: 10px;padding: 4px;"
                @click.prevent="showModalCreateSingle()">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div style="display: flex; align-items: center;">
                        <img src="/img/icons/icons8/ios/svgs/plus-math.svg" width="15" class="mr-2" />
                        <h5 class="new-default-black-font m-0 font-weight-500">Adicionar</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Divisor -->
          <div class="col-12 px-md-0 mt-4">
            <div class="container-fluid px-0" style="padding-right: 0 !important; padding-left: 0 !important;">
              <div class="row">
                <div class="col-12 col-lg-8 col-xl-9">
                  <div class="listView-container-fluid">
                    <!-- Cabeçalho da listagem "A faturar" -->
                    <div class="listView-container-header row align-items-center">
                      <div class="col-auto px-0 listView-container-header-title">
                        <img
                          :src="isAllSelected ? '/img/icons/double-box-tick-primary.png' : '/img/icons/double-box-unticked-primary.png'"
                          @click.prevent="selectOrUnselectAll()"
                          class="pointer"
                          width="22">
                        <h4 class="new-default-black-font m-0">A faturar</h4>
                      </div>
                      <div class="col">
                        <hr class="gray-divider m-0">
                      </div>
                      <div class="col-auto pl-0">
                        <div
                          style="display: flex;align-items: center;">
                          <div>
                            <i
                              @click.prevent="setViewType('cards')"
                              :class="{'opacity-25': listType !== 'cards'}"
                              class="pointer fa-regular fs-22 fa-hard-drive"
                            />
                            <i
                              @click.prevent="setViewType('table')"
                              :class="{'opacity-25': listType !== 'table'}"
                              class="pointer fa-solid fs-22 fa-table-list ml-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Listagens Cards/tabela -->
                    <div class="container-fluid px-0 listView-scroll" style="padding: 0 25px 0 25px !important">
                      <div class="row card-wrapper" v-if="listType === 'cards'">
                        <div class="col-md-4 px-2" v-for="(item, index) in $_invoices" :key="index">
                          <div class="card">
                            <div class="row mb-2">
                              <!-- Lugar para badges descritivos e CTAs -->
                              <div class="col-10">
                                <div class="info-cards-spot">
                                  <div
                                    v-for="type in itemTypes"
                                    :key="type.id"
                                    v-if="item.type === type.id"
                                    @click.prevent="[billingStatusEnum.CANCELED, billingStatusEnum.CANCELED_CONCRETE].includes(item.status) ? '' : push(item.id, item.type)"
                                    :class="type.selectedList.includes(item.id) ? type.selectedClass : type.outlineClass">
                                    <img :src="type.icon" />
                                    <h4 class="m-0 ml-1">{{ type.label }}</h4>
                                  </div>
                                  <div class="new-nanocard-green"
                                    v-if="item.type === billingTypeEnum.SINGLE && !item.approved"
                                    @click.prevent="handleApprove(item)">
                                    <img src="/img/icons/icons8/ios/thumb-up_success.png">
                                    <h4 class="m-0 ml-1">Aprovar</h4>
                                  </div>
                                  <div class="new-nanocard-red"
                                    v-if="[billingStatusEnum.CANCELED, billingStatusEnum.CANCELED_CONCRETE].includes(item.status)">
                                    <img src="/img/icons/icons8/ios/cancel_danger.png">
                                    <h4 class="m-0 ml-1">Cancelado</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Traço, valores e demais informações-->
                            <!-- Se for do tipo Concreto: 1 -->
                            <div class="row" v-if="item.type === billingTypeEnum.CONCRETE">
                              <div class="col-12">
                                <h4 class="new-default-black-font m-0 p-0 font-weight-500">
                                  {{ item.title }}
                                </h4>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ item.body }}
                                </h5>
                                <template
                                  v-for="(puzl_addtion, index) in item.puzl_additions">
                                  <h5 class="new-default-gray-font m-0 p-0 font-weight-normal">
                                    {{ puzl_addtion.name }}
                                    ({{ puzl_addtion.volume }} kg/m<sup>3</sup>)
                                    <span
                                      class="new-default-gray-font"
                                      v-if="index !== item.puzl_additions.length -1">
                                      +
                                    </span>
                                  </h5>
                                </template>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                                  {{ item.volume.toString().replace('.', ',') }} m <sup>3</sup>
                                </h5>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                                  {{ $helper.toMoney(item.formulation_value) }} /m<sup>3</sup>
                                </h5>
                              </div>
                            </div>
                            <!-- Se for do tipo Serviço: 2 -->
                            <div class="row" v-else-if="item.type === billingTypeEnum.SERVICE">
                              <div class="col-12">
                                <h4 class="new-default-black-font m-0 p-0 font-weight-500">
                                  {{ item.service_name }}
                                </h4>
                                <h4 class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ item.service_description }}
                                </h4>
                                <h5 v-if="['price_fixed', 'price_meters', 'price_hour'].includes(item.price_key)"
                                  class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ item.volume_performed }} m³
                                </h5>
                                <h5 v-if="item.price_key === 'price_fixed'"
                                  class="new-default-black-font m-0 p-0 font-weight-normal">
                                  Taxa Mínima
                                </h5>
                                <h5 v-if="item.price_key === 'price_meters'"
                                  class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ $helper.toMoney(item.proposal_by_meters) }} /m³
                                </h5>
                                <template v-if="item.price_key === 'price_hour'">
                                  <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                    {{ $helper.secondsToTime(item.hour_performed * 3600, true) }}
                                  </h5>
                                  <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                    {{ $helper.toMoney(item.proposal_by_hours) }} /hr
                                  </h5>
                                </template>
                              </div>
                            </div>
                            <!-- Se for do tipo Adicional: 3 -->
                            <div class="row" v-if="item.type === billingTypeEnum.ADDITIONAL">
                              <div class="col-12">
                                <h4 class="new-default-black-font m-0 p-0 font-weight-500">
                                  {{ item.short_description }}
                                </h4>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ item.reason || '-' }}
                                </h5>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400" v-if="item.billing_unit_id !== 0">
                                  {{ format(item.calculation_base) || '-' }}
                                </h5>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400" v-else>
                                  {{ $helper.toMoney(item.calculation_base) }}
                                </h5>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                                  {{ item.standard_value }} {{ item.billing_unit }}
                                </h5>
                              </div>
                            </div>
                            <!-- Se for do tipo Avulso: 4 -->
                            <div class="row" v-if="item.type === billingTypeEnum.SINGLE">
                              <div class="col-12">
                                <h4 class="new-default-black-font m-0 p-0 font-weight-500">
                                  {{ item.description }}
                                </h4>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                  {{ item.quantity }}(Quantidade)
                                </h5>
                                <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                                  {{ $helper.toMoney((item.value / item.quantity)) }} /unid
                                </h5>
                              </div>
                            </div>
                            <!-- Divisor -->
                            <div class="row">
                              <div class="col-12">
                                <hr class="gray-divider my-2">
                              </div>
                            </div>
                            <!-- Valor e data -->
                            <div class="row">
                              <div class="col-12">
                                <div style="display: flex; align-items: baseline;">
                                  <h6 class="font-weight-400 new-default-black-font m-0">R$</h6>
                                  <h2 class="new-default-black-font m-0 font-weight-400 m-0">
                                    {{ $helper.toMoneyWithoutCurrencyLayout(item.value) }}
                                  </h2>
                                  <base-dropdown menuOnRight v-if="item.type === billingTypeEnum.CONCRETE && Number(item.max_versions) > 1">
                                    <div class="version-identifier"
                                         slot="title-container">
                                      <h4 class="m-0 text-primary font-weight-500">
                                        V.{{ item.version }}
                                      </h4>
                                      <img src="/img/icons/icons8/ios/less-than_primary.png" width="15">
                                    </div>
                                    <a v-if="indexVersion !== Number(item.version)"
                                       @click.prevent="handleUpdateVersion(item.id, indexVersion, index)" class="dropdown-item"
                                       v-for="indexVersion in Number(item.max_versions)">
                                      <span class="text-right">V.{{ indexVersion }}</span>
                                    </a>
                                  </base-dropdown>
                                  <div class="version-identifier"
                                    style="opacity: .5; cursor: auto;"
                                    v-if="(item.type === 1 && !item.max_versions) || (item.type === billingTypeEnum.CONCRETE &&  Number(item.max_versions) === 1)">
                                    <h4 class="m-0 text-primary font-weight-500">
                                      V.{{ item.version }}
                                    </h4>
                                    <img src="/img/icons/icons8/ios/less-than_primary.png" width="15">
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <h6 class="font-weight-normal new-default-gray-font m-0">
                                  {{ $helper.parseDate(item.date_at, 'DD MMM YYYY HH:mm') }}
                                </h6>
                              </div>
                            </div>
                            <!-- Mini cards de OS e NFe -->
                            <div class="row px-2 mt-3"
                              v-if="[billingTypeEnum.CONCRETE, billingTypeEnum.SERVICE,billingTypeEnum.ADDITIONAL].includes(item.type)">
                              <div @click.prevent="handleOsClick(item)"
                                :style="[billingTypeEnum.CONCRETE, billingTypeEnum.SERVICE].includes(item.type) ? 'cursor: pointer;' : 'cursor: auto;'"
                                class="col-6 px-2">
                                <a
                                  class="new-cardwithbox-shadow"
                                  href="#"
                                  style="display: inherit;height: 23px;">
                                  <div
                                    class="new-default-black-font"
                                    style="font-size: 12px; display: block;">
                                    <img
                                      src="/img/icons/icons8/ios/os.png"
                                      class="mr-1"
                                      width="15">
                                    {{ item.number }}
                                  </div>
                                </a>
                              </div>
                              <div v-show="billingTypeEnum.CONCRETE === item.type ? item.nfe : item.type === billingTypeEnum.ADDITIONAL"
                                class="col-6 px-2">
                                <a
                                  class="new-cardwithbox-shadow"
                                  @click.prevent="downloadDanfe(item)"
                                  href="#"
                                  style="display: inherit;height: 23px;">
                                  <div
                                    class="new-default-black-font"
                                    style="font-size: 12px; display: block;">
                                    <img
                                      src="/img/icons/nfe.png"
                                      class="mr-1"
                                      width="11">
                                    {{ item.nfe }}
                                  </div>
                                </a>
                              </div>
                            </div>
                            <!-- Ações Editar/excluir/cancelar -->
                            <base-dropdown
                              v-if="item.type !== billingTypeEnum.CONCRETE"
                              menuOnRight
                              style="position: absolute; right: 12px; top: 12px;">
                              <div
                                slot="title-container"
                                class="dropdown-toggle rounded m-0"
                              >
                                <img
                                  width="25"
                                  src="/img/icons/icons8/ios/settings--v1_primary.png"
                                >
                              </div>
                              <a
                                v-if="[billingTypeEnum.SERVICE,billingTypeEnum.ADDITIONAL].includes(item.type)"
                                @click.prevent="handleEditClick(item)"
                                class="dropdown-item new-default-black-font"
                                style="align-items: center; display: flex"
                                >
                                <img
                                  src="/img/icons/create-new.png"
                                  width="20"
                                />
                                Editar
                              </a>
                              <a
                                class="dropdown-item new-default-black-font"
                                v-if="item.type === billingTypeEnum.SINGLE"
                                @click.prevent="removeSingle(item.id)"
                                style="align-items: center; display: flex">
                                <img
                                  src="/img/icons/icons8/ios/cancel_danger.png"
                                  width="20"
                                />
                                Excluir
                              </a>
                            </base-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-0" v-if="listType === 'table'">
                        <div class="row">
                          <ShadedTable
                            :length="$_invoices.length"
                            :headers="headers"
                            :loading="loadingSkeleton">
                            <template
                              v-for="(item, index) in $_invoices"
                              :slot="index">
                              <!-- Badges informativos e CTAs -->
                              <ShadedCol
                                align="center"
                                customStyle="display: flex;">
                                <div
                                  v-if="![billingStatusEnum.CANCELED, billingStatusEnum.CANCELED_CONCRETE].includes(item.status) && item.type === type.id"
                                  v-for="type in itemTypes"
                                  :key="type.id"
                                  @click.prevent="push(item.id, item.type)"
                                  style="text-align: center;"
                                  class="new-minicard-table"
                                  :class="type.selectedList.includes(item.id) ? type.selectedClass : type.outlineClass">
                                  <div>
                                    <img :src="type.icon" />
                                    <h4 class="m-0 ml-1">{{ type.label }}</h4>
                                  </div>
                                </div>
                                <div v-else-if="item.type === type.id"
                                  style="display: grid; grid-template-columns: 90% 10%;align-items: center;gap: 3px;">
                                  <div
                                    v-for="type in itemTypes"
                                    :key="type.id"
                                    v-if="item.type === type.id"
                                    style="text-align: center;"
                                    class="new-minicard-table"
                                    :class="type.selectedList.includes(item.id) ? type.selectedClass : type.outlineClass">
                                    <div>
                                      <img :src="type.icon" />
                                      <h4 class="m-0 ml-1">{{ type.label }}</h4>
                                    </div>
                                  </div>
                                  <img src="/img/icons/icons8/ios/cancel_danger.png" width="18" alt="">
                                </div>
                              </ShadedCol>
                              <!-- Data -->
                              <ShadedCol align="center" style="width: 15px;">
                                <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                  {{ $helper.parseDate(item.date_at, 'DD MMM YYYY HH:mm') }}
                                </h5>
                              </ShadedCol>
                              <!-- Informação do traço -->
                              <ShadedCol>
                                <el-popover v-if="item.type === billingTypeEnum.CONCRETE" trigger="click" placement="top" class="p-0 pr-0 mr-1">
                                  <span
                                    style="width: 250px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                                    class="row">
                                    <div
                                      class="col-12 mb-2"
                                      style="display: flex;
                                      align-items: center;">
                                      <img src="/img/icons/icons8/ios/test-tube_primary.png" class="mr-2" width="22">
                                      <h4
                                        style="font-size: 14px;"
                                        class="p-0 m-0 font-weight-500 new-default-black-font">
                                        Traço
                                      </h4>
                                    </div>
                                    <div
                                      class="col-12 mb-2 px-0">
                                      <hr class="gray-divider m-0">
                                    </div>
                                    <div
                                      class="col-12">
                                      <h5 class="new-default-black-font m-0 p-0 font-weight-normal">
                                        {{ item.body }}
                                      </h5>
                                      <template
                                        v-for="(puzl_addtion, index) in item.puzl_additions">
                                        <h5 class="new-default-gray-font m-0 p-0 font-weight-normal">
                                          {{ puzl_addtion.name }}
                                          ({{ puzl_addtion.volume }} kg/m<sup>3</sup>)
                                          <span
                                            class="new-default-gray-font"
                                            v-if="index !== item.puzl_additions.length -1">
                                            +
                                          </span>
                                        </h5>
                                      </template>
                                    </div>
                                  </span>
                                  <h5 slot="reference" class="m-0 mt-1 new-default-black-font font-weight-normal">
                                    {{ item.title }}
                                  </h5>
                                </el-popover>
                                <h5 v-else class="m-0 mt-1 new-default-black-font font-weight-normal">
                                  <template v-if="item.type === billingTypeEnum.SERVICE">
                                    {{ item.service_name }}
                                  </template>
                                  <template v-if="item.type === billingTypeEnum.ADDITIONAL">
                                    {{ item.short_description }}
                                  </template>
                                  <template v-if="item.type === billingTypeEnum.SINGLE">
                                    {{ item.description }}
                                  </template>
                                </h5>
                              </ShadedCol>
                              <!-- OS -->
                              <ShadedCol>
                                <div class="container px-0">
                                  <div class="row align-items-center"
                                    v-if="[billingTypeEnum.CONCRETE, billingTypeEnum.SERVICE,billingTypeEnum.ADDITIONAL].includes(item.type)">
                                    <div class="col-6">
                                      <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                        {{ item.number }}
                                      </h5>
                                    </div>
                                    <div class="col-6 text-right">
                                      <img
                                        @click.prevent="handleOsClick(item)"
                                        :style="[billingTypeEnum.CONCRETE, billingTypeEnum.SERVICE].includes(item.type) ? 'cursor: pointer;' : 'cursor: auto;'"
                                        src="/img/icons/icons8/ios/os.png"
                                        class="mr-1"
                                        width="20">
                                    </div>
                                  </div>
                                </div>
                              </ShadedCol>
                              <!-- NFe -->
                              <ShadedCol>
                                <div class="container px-0" style="width: 100%;">
                                  <div class="row align-items-center"
                                    v-show="billingTypeEnum.CONCRETE === item.type ? item.nfe : item.type === billingTypeEnum.ADDITIONAL">
                                    <div class="col-6">
                                      <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                        {{ item.nfe }}
                                      </h5>
                                    </div>
                                    <div class="col-6 text-right">
                                      <img
                                        src="/img/icons/nfe.png"
                                        class="mr-1"
                                        width="15">
                                    </div>
                                  </div>
                                </div>
                              </ShadedCol>
                              <!-- Quantidade m³ -->
                              <ShadedCol>
                                <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                  <template v-if="item.type === billingTypeEnum.CONCRETE">
                                    {{ item.volume.toString().replace('.', ',') }} m <sup>3</sup>
                                  </template>
                                  <template v-if="item.type === billingTypeEnum.SERVICE">
                                    <template v-if="['price_fixed', 'price_meters', 'price_hour'].includes(item.price_key)">
                                      {{ item.volume_performed }} m³
                                    </template>
                                  </template>
                                  <template v-if="item.type === billingTypeEnum.ADDITIONAL">
                                    <template v-if="item.billing_unit_id !== 0">
                                      {{ format(item.calculation_base) || '-' }}
                                    </template>
                                    <template v-else>
                                      {{ $helper.toMoney(item.calculation_base) }}
                                    </template>
                                  </template>
                                  <template v-if="item.type === billingTypeEnum.SINGLE">
                                    {{ item.quantity }}
                                  </template>
                                </h5>
                              </ShadedCol>
                              <!-- valor e versionamento -->
                              <ShadedCol align="center">
                                <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                  <template v-if="item.type === billingTypeEnum.CONCRETE">
                                    {{ $helper.toMoney(item.formulation_value) }} /m<sup>3</sup>
                                  </template>
                                  <template v-else-if="item.type === billingTypeEnum.SERVICE">
                                    <template v-if="item.price_key === 'price_fixed'">
                                      Taxa Mínima
                                    </template>
                                    <template v-if="item.price_key === 'price_meters'">
                                      {{ $helper.toMoney(item.proposal_by_meters) }} /m³
                                    </template>
                                    <template v-if="item.price_key === 'price_hour'">
                                      {{ $helper.toMoney(item.proposal_by_hours) }} /hr
                                    </template>
                                  </template>
                                  <template v-else-if="item.type === billingTypeEnum.ADDITIONAL">
                                    {{ item.standard_value }} {{ item.billing_unit }}
                                  </template>
                                  <template v-else-if="item.type === billingTypeEnum.SINGLE">
                                    {{ $helper.toMoney((item.value / item.quantity)) }} /unid
                                  </template>
                                </h5>
                              </ShadedCol>
                              <!-- valor total -->
                              <ShadedCol>
                                <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                                  {{ $helper.toMoney(item.value) }}
                                </h5>
                              </ShadedCol>
                              <!-- Ações excluir/cancelar/editar -->
                              <ShadedCol align="center">
                                <base-dropdown
                                  v-if="item.type !== billingTypeEnum.CONCRETE"
                                  menuOnRight>
                                  <div
                                    slot="title-container"
                                    class="dropdown-toggle rounded m-0"
                                  >
                                    <img
                                      width="25"
                                      src="/img/icons/icons8/ios/settings--v1_primary.png"
                                    >
                                  </div>
                                  <a
                                    v-if="[billingTypeEnum.SERVICE,billingTypeEnum.ADDITIONAL].includes(item.type)"
                                    @click.prevent="handleEditClick(item)"
                                    class="dropdown-item new-default-black-font"
                                    style="align-items: center; display: flex"
                                  >
                                    <img
                                      src="/img/icons/create-new.png"
                                      width="20"
                                    />
                                    Editar
                                  </a>
                                  <a
                                    class="dropdown-item new-default-black-font"
                                    v-if="item.type === billingTypeEnum.SINGLE"
                                    @click.prevent="removeSingle(item.id)"
                                    style="align-items: center; display: flex">
                                    <img
                                      src="/img/icons/icons8/ios/cancel_danger.png"
                                      width="20"
                                    />
                                    Excluir
                                  </a>
                                </base-dropdown>
                                <base-dropdown menuOnRight v-if="item.type === billingTypeEnum.CONCRETE && Number(item.max_versions) > 1">
                                  <div class="version-identifier m-0"
                                       slot="title-container">
                                    <h4 class="m-0 text-primary font-weight-500">
                                      V.{{ item.version }}
                                    </h4>
                                    <img src="/img/icons/icons8/ios/less-than_primary.png" width="15">
                                  </div>
                                  <a v-if="indexVersion !== Number(item.version)"
                                     @click.prevent="handleUpdateVersion(item.id, indexVersion, index)" class="dropdown-item"
                                     v-for="indexVersion in Number(item.max_versions)">
                                    <span class="text-right">V.{{ indexVersion }}</span>
                                  </a>
                                </base-dropdown>
                                <div class="version-identifier m-0"
                                     style="opacity: .5; cursor: auto;"
                                     v-if="(item.type === 1 && !item.max_versions) || (item.type === billingTypeEnum.CONCRETE &&  Number(item.max_versions) === 1)">
                                  <h4 class="m-0 text-primary font-weight-500">
                                    V.{{ item.version }}
                                  </h4>
                                  <img src="/img/icons/icons8/ios/less-than_primary.png" width="15">
                                </div>
                              </ShadedCol>
                            </template>
                          </ShadedTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Formulário pré-faturamento -->
                <div class="col-12 my-3 my-lg-0 col-lg-4 col-xl-3 pl-lg-0">
                  <div class="listView-container">
                    <div class="container-fluid px-0" style="padding-right: 0 !important; padding-left: 0 !important;">
                      <!-- Configurações -->
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0" style="height: 25px;">
                          <img src="/img/icons/settings.png" width="22">
                        </div>
                        <div class="col-auto px-2">
                          <h3 class="new-default-black-font m-0">
                            Configurações
                          </h3>
                        </div>
                        <div class="col pl-0">
                          <hr class="gray-divider m-0">
                        </div>
                      </div>
                      <div class="row mt-3 mb-2 align-items-center">
                        <div class="col-12">
                          <div :class="Number(params.balance) > 0 ? 'use-balance' : 'dont-use-balance'">
                            <h4 class="text-center m-0" v-if="Number(params.balance) > 0">
                              Saldo do cliente {{ $helper.toMoney(params.balance ) }}
                            </h4>
                            <h4 class="text-center m-0" v-else>
                              Cliente Não Possui Saldo
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2 mb-5 mb-2 px-3 align-items-center">
                        <div class="col-4 pl-0 pr-1">
                          <base-button type="light" block
                                       @click.prevent="$emit('setIss', params, index)"
                                       :class="params.iss_retain ? 'new-btn-danger' : 'new-btn-light'"
                                       class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                       size="sm">
                            <img v-show="!params.iss_retain" src="/img/icons/chemical-plant-black.png" width="14"/>
                            <img v-show="params.iss_retain" src="/img/icons/icons8/ios/name--v1_danger.png" width="14"/>

                            <span :class="params.iss_retain && 'text-danger fs-10'" class="fs-10">
                              {{ params.iss_retain ? 'ISS RETIDO' : 'ISS DEVIDO' }}
                            </span>
                          </base-button>
                        </div>
                        <div class="col-4 px-1">
                          <base-button type="light" block
                                       @click.prevent="$emit('showModalFederalTaxes', params)"
                                       :class="hasAnyFederalTaxRetained(params) ? 'new-btn-danger' : 'new-btn-light'"
                                       class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                       size="sm">
                            <img v-show="!hasAnyFederalTaxRetained(params)"
                                 src="/img/icons/ledger.png"
                                 width="14"/>
                            <img v-show="hasAnyFederalTaxRetained(params)"
                                 src="/img/icons/icons8/ios/ledger_danger.png"
                                 width="14"/>
                            <span :class="hasAnyFederalTaxRetained(params) && 'text-danger'"
                                  class="fs-10">
                               FEDERAL
                            </span>
                          </base-button>
                        </div>
                        <div class="col-4 pl-1 pr-0" v-if="Number(params.balance) > 0">
                          <base-button type="light" block
                                       @click.prevent="params.use_anticipation_balance = !params.use_anticipation_balance"
                                       :class="params.use_anticipation_balance ? 'new-btn-primary' : 'new-btn-light'"
                                       class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                                       size="sm">
                            <img v-show="!params.use_anticipation_balance"
                                 src="/img/icons/icons8/ios/us-dollar-circled--v1_black.png"
                                 width="14"/>
                            <img v-show="params.use_anticipation_balance"
                                 src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
                                 width="14"/>
                            <span :class="params.use_anticipation_balance && 'text-primary'" class="fs-10">
                               COMPENSAR
                            </span>
                          </base-button>
                        </div>
                      </div>
                      <!-- Informações -->
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0" style="height: 25px;">
                          <img src="/img/icons/icons8/ios/info-squared_warning.png" width="22">
                        </div>
                        <div class="col-auto px-2">
                          <h3 class="new-default-black-font m-0">
                            Informações
                          </h3>
                        </div>
                        <div class="col pl-0">
                          <hr class="gray-divider m-0">
                        </div>
                      </div>
                      <div class="row mt-3 mb-2 align-items-center">
                        <div class="col-12">
                          <div class="dont-use-balance billed-period" style="border-radius: 4px !important">
                            <h4 class="text-center font-weight-400 new-default-black-font m-0">
                              {{ $helper.parseDate(params.filter.range.start, 'DD/MM/YYYY') }} até {{ $helper.parseDate(params.filter.range.end, 'DD/MM/YYYY') }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center" v-if="reidi > 0">
                        <div class="col-auto pr-0">
                          <h4 class="text-danger m-0">
                            Reidi
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="text-danger m-0">
                            {{ $helper.toMoney(reidi) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font m-0">
                            Valor Bruto
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font m-0">
                            {{ $helper.toMoney(getTotalValue()) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            MCC Teórico
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            {{ $helper.toMoney(sumTheoricalAdjusted) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center" v-if="sumIncreasedLossCostMcc !== 0">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            Perda MCC
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            {{ $helper.toMoney(sumIncreasedLossCostMcc) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            DEDUÇÃO ({{ onlyViewDeduction }}%)
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <div style="display: flex; align-items: center">
                            <img src="/img/icons/create-new.png"
                               class="pointer mr-2"
                               @click="openEditDeductionModal"
                               width="16">
                            <h4 class="new-default-black-font font-weight-normal m-0">
                              {{ $helper.toMoney((deduct || 0)) }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            Base de cálculo
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            {{ $helper.toMoney((base || 0)) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font m-0">
                            ISS (5%)
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font m-0">
                            {{ $helper.toMoney((sumISS || 0)) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            Retenções federais
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="new-default-black-font font-weight-normal m-0">
                            {{ $helper.toMoney((sumFederalTaxes || 0)) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2 align-items-center">
                        <div class="col-auto pr-0">
                          <h4 class="text-primary m-0">
                            Valor líquido
                          </h4>
                        </div>
                        <div class="col px-2">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pl-0">
                          <h4 class="text-primary m-0">
                            {{ $helper.toMoney(getFinalValue()) }}
                          </h4>
                        </div>
                      </div>
                      <!-- Pagamento -->
                      <div class="row align-items-center mt-5">
                        <div class="col-auto pr-0" style="height: 25px;">
                          <img src="/img/icons/icons8/ios/transaction-approved_danger.png" width="22">
                        </div>
                        <div class="col-auto px-2">
                          <h3 class="new-default-black-font m-0">
                            Pagamento
                          </h3>
                        </div>
                        <div class="col pl-0">
                          <hr class="gray-divider m-0">
                        </div>
                      </div>
                      <div class="row my-3 align-items-center">
                        <div class="col-12">
                          <h6 class="font-weight-normal m-0 new-default-gray-font">
                            Método
                          </h6>
                        </div>
                        <div class="col-12">
                          <PuzlSelect
                            @input="getPaymentTerms()"
                            v-model="params.payment_method_id"
                            placeholder="MÉTODO"
                            :disabled="params.payment_methods === null"
                            :items="getPaymentMethods()"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="row my-3 align-items-center">
                        <div class="col-12">
                          <h6 class="font-weight-normal m-0 new-default-gray-font">
                            Condição
                          </h6>
                        </div>
                        <div class="col-12">
                          <PuzlSelect
                            @change="setRequireBalance()"
                            v-model="params.payment_term_id"
                            placeholder="CONDIÇÃO"
                            :disabled="!params.payment_method_id || payment_terms === null || typeof payment_terms === 'undefined'"
                            :items="payment_terms_by_method[params.id] ? payment_terms_by_method[params.id] : []"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="row my-3 align-items-center">
                        <div class="col-12">
                          <h6 class="font-weight-normal m-0 new-default-gray-font">
                            Conta bancária
                          </h6>
                        </div>
                        <div class="col-12">
                          <PuzlSelect
                            @change="setRequireBalance()"
                            v-model="params.bank_account_id"
                            placeholder="Conta"
                            :disabled="bank_accounts === null"
                            :items="getBankAccounts(params.bank_accounts)"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="row mt-5 mb-2 align-items-center">
                        <div class="col-10 offset-1 text-center px-0" style="gap: 10px; display: flex; justify-content: flex-end">
                          <AppButton
                            label="Cancelar"
                            type="danger"
                            :isBlock="true"
                            :hasOutline="true"
                            @click.prevent="close"
                          />
                          <AppButton
                            v-if="invoice"
                            :isDisabled="!(concrete_selecteds.length || services_selecteds.length || additionals_selecteds.length || single_selecteds.length) || getFinalValue() < 0"
                            label="Faturar"
                            type="primary"
                            :isBlock="true"
                            @click.prevent="save"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <create-single @fetch="init(false)" ref="createSingle"/>
    </modal>
    <ModalChangeServiceValue @updated="init" ref="modalChangeServiceValue"/>
    <ModalChangeAdditionalValue @updated="init" ref="modalChangeAdditionalValue"/>
    <ModalEditDeduction @handleSave="updateDeductionValue" ref="modalEditDeduction"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import InputDatePicker from "@/components/InputDatePicker.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import {mapGetters} from "vuex";
import CreateSingle from '../../../../../Billing/BillingProposal/Shared/_CreateSingle.vue'
import ModalChangeServiceValue from "../../../../../Billing/Shared/_ModalChangeServiceValue.vue"
import ModalChangeAdditionalValue from "../../../../../Billing/Shared/_ModalChangeAdditionalValue.vue"
import {parseDate, progress, roundWithDecimal, strToNum} from "@/helpers";
import AppIcon from "../../../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import ShadedTable from "@/components/ShadedTable.vue";
import EntityPossibleActions
  from "@/views/Modules/Financial/BillReceive/Entity/Shared/Lists/Common/_EntityPossibleActions.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {AppButton, AppOnOffToggle} from "@/components/AppGlobal";
import {getPreferredViewMode, setPreferredViewMode} from '@/plugins/session';
import ModalEditDeduction from "@/modules/financial/allotments/invoice/views/Modals/ModalEditDeduction.vue";

const BILLING_TYPE_ENUM = Object.freeze({
  CONCRETE: 1, // Concreto
  SERVICE: 2, // Serviço
  ADDITIONAL: 3, // Adicional
  SINGLE: 4, // Avulso
});

const BILLING_STATUS_ENUM = Object.freeze({
  CANCELED: 3, // Cancelado
  CANCELED_CONCRETE: 6, // Cancelado
});

export default {
  name: "ModalIndividual",
  components: {
    ModalEditDeduction,
    AppButton,
    AppOnOffToggle,
    ShadedCol,
    EntityPossibleActions,
    ShadedTable,
    AppIcon,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    CreateSingle,
    ModalChangeServiceValue,
    ModalChangeAdditionalValue,
  },
  props: {
    issChangingVerifierClone: {},
  },
  data() {
    return {
      billingTypeEnum: BILLING_TYPE_ENUM,
      billingStatusEnum: BILLING_STATUS_ENUM,
      modal: {
        title: 'Faturamento individual',
        create: false,
      },
      payment_terms_by_method: [],
      payment_intemerdiaries_selected: [],
      headers: ["Serviço", "Data", "Traço", "O.S", "NFE", "VOL", "P.UNIT", "V.TOTAL", "AÇÃO"],
      defaultPercDeduction: null,
      defaultValDeduction: null,
      user_defined_deduction_amount: false,
      invoice: true,
      base: 0,
      deduct: 0,
      reidi: 0,
      listType: 'cards',
      reidi_percentage: 0,
      concrete_selecteds: [],
      services_selecteds: [],
      additionals_selecteds: [],
      single_selecteds: [],
      loadingSkeleton: false,
      loading: false,
      params: null,
      index: null,
    };
  },
  computed: {
    ...mapGetters({
      $_invoices: "billingInvoice/getListBills",
      contract_proposal: "contractProposal/show",
      $_financial_limit: "permissionFinancialLimit/show",
      payment_methods: "paymentMethod/fetch",
      payment_terms: "paymentTerm/fetch",
      bank_accounts: "bankAccount/fetch",
    }),
    onlyViewDeduction() {
      const total = this.getTotalValue()
      let deduct = total * Number(this.params.deduction.deduct) / 100;

      if (this.params.deduction.condition && !this.user_defined_deduction_amount) {
        let mcc = 0
        if (this.params.deduction.consider_reuse) {
          mcc = this.sumRealMcc
        } else {
          mcc = this.sumTheoricalAdjusted
        }

        let deduct_mcc = total * ((mcc / total) * 100) / 100;

        deduct_mcc += this.sumIncreasedLossCostMcc

        deduct_mcc = Math.floor((total > 0 ? (deduct_mcc / total) * 100 : 0) * 100) / 100;

        if (this.params.deduction.condition === 'Adotar sempre o MCC') {
          deduct = deduct_mcc
        }
        if (this.params.deduction.condition === 'Adotar sempre maior dedução') {
          if (deduct_mcc > deduct) {
            deduct = deduct_mcc
          }
        }
        if (this.params.deduction.condition === 'Adotar sempre menor dedução') {
          if (deduct_mcc < deduct) {
            deduct = deduct_mcc
          }
        }
      } else {
        deduct = this.params.deduction.deduct;
      }

      return deduct;
    },
    isAllSelected() {
      let its = true;

      for (const invoice of this.$_invoices) {
        switch (invoice.type) {
          case this.billingTypeEnum.CONCRETE:
            its = this.concrete_selecteds.includes(invoice.id);
            break;
          case this.billingTypeEnum.SERVICE:
            its = this.services_selecteds.includes(invoice.id);
            break;
          case this.billingTypeEnum.ADDITIONAL:
            its = this.additionals_selecteds.includes(invoice.id);
            break;
          case this.billingTypeEnum.SINGLE:
            if (invoice.approved) {
              its = this.single_selecteds.includes(invoice.id);
            }
            break;
        }

        if (!its) {
          break;
        }
      }

      return its;
    },
    itemTypes() {
      return [
        {
          id: this.billingTypeEnum.CONCRETE,
          label: "Concreto",
          icon: "/img/icons/icons8/ios/test-tube_primary.png",
          selectedList: this.concrete_selecteds,
          selectedClass: "new-minicard-blue",
          outlineClass: "new-minicard-blue-outline",
        },
        {
          id: this.billingTypeEnum.SERVICE,
          label: "Serviço",
          icon: "/img/icons/icons8/ios/todo-list_secondary.png",
          selectedList: this.services_selecteds,
          selectedClass: "new-minicard-gray",
          outlineClass: "new-minicard-gray-outline",
        },
        {
          id: this.billingTypeEnum.ADDITIONAL,
          label: "Adicional",
          icon: "/img/icons/icons8/ios/add-square_warning.png",
          selectedList: this.additionals_selecteds,
          selectedClass: "new-minicard-yellow",
          outlineClass: "new-minicard-yellow-outline",
        },
        {
          id: this.billingTypeEnum.SINGLE,
          label: "Avulso",
          icon: "/img/icons/icons8/ios/add-success.png",
          selectedList: this.single_selecteds,
          selectedClass: "new-minicard-green",
          outlineClass: "new-minicard-green-outline",
        },
      ];
    },
    sumISS: function () {
      if (!this.params) {
        return 0
      }

      let increased_loss_cost = 0

      const total = this.getTotalValue()
      let deduct = total * Number(this.params.deduction.deduct) / 100;

      if (this.params.deduction.condition && !this.user_defined_deduction_amount) {
        let mcc = 0
        if (this.params.deduction.consider_reuse) {
          mcc = this.sumRealMcc
        } else {
          mcc = this.sumTheoricalAdjusted
        }

        let deduct_mcc = total * ((mcc / total) * 100) / 100;

        deduct_mcc += this.sumIncreasedLossCostMcc

        if (this.params.deduction.condition === 'Adotar sempre o MCC') {
          deduct = deduct_mcc
        }
        if (this.params.deduction.condition === 'Adotar sempre maior dedução') {
          if (deduct_mcc > deduct) {
            deduct = deduct_mcc
          }
        }
        if (this.params.deduction.condition === 'Adotar sempre menor dedução') {
          if (deduct_mcc < deduct) {
            deduct = deduct_mcc
          }
        }
      }

      const base = total - deduct;
      this.base = base;
      this.deduct = deduct;
      return roundWithDecimal((base * Number(this.params.deduction.retained_rate) / 100).toFixed(2), 2)
    },
    sumFederalTaxes: function () {
      if (!this.params) {
        return 0
      }
      const total = this.getTotalValue();
      let federalTaxes = 0;
      if (strToNum(this.params.cofins_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.cofins_rate) / 100);
      }
      if (strToNum(this.params.csll_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.csll_rate) / 100);
      }
      if (strToNum(this.params.inss_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.inss_rate) / 100);
      }
      if (strToNum(this.params.ir_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.ir_rate) / 100);
      }
      if (strToNum(this.params.pis_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.pis_rate) / 100);
      }
      return federalTaxes;
    },
    sumVolume: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.volume) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumVolumeServices: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 2 && this.services_selecteds.includes(b.id) && b.status === 1 ? Number(b.volume_performed) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumConcrete: function () {
      let initial = 0

      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumTheoricalAdjusted: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.theorical_adjusted) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    /**
     * soma do custo de perca
     * @returns {*}
     */
    sumIncreasedLossCostMcc: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.increased_loss_cost_mcc_value_total) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumRealMcc: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.real_mcc) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumServices: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 2 && this.services_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumAdditionals: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 3 && this.additionals_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumSingle: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 4 && this.single_selecteds.includes(b.id) ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
  },
  methods: {
    /**
     * Atualiza os valores de dedução.
     * @param {Object} value - Objeto contendo informações da dedução.
     * @param {boolean} value.has_deduct_changed - Indica se a dedução foi alterada.
     * @param {number} value.deduct_in_percentage - Valor da dedução em porcentagem.
     * @param {number} value.deduct_in_value - Valor da dedução em reais.
     * @param {string} value.lastInputChanged - Último campo editado pelo usuário
     */
    updateDeductionValue(value){
      this.user_defined_deduction_amount = value.has_deduct_changed;

      this.deduct = value.deduct_in_value;
      this.params.deduction.deduct = value.deduct_in_percentage;
    },
    openEditDeductionModal() {
      this.$refs.modalEditDeduction.openModal(this.params.deduction.deduct, this.deduct, this.defaultValDeduction, this.defaultPercDeduction, this.getTotalValue());
    },
    async downloadDanfe(item){
      let params = {
        uuid: item.sequencial_number_plant_schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.pdf`);
          link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        }).catch(error => {
          loader.hide();
        });
    },
    selectOrUnselectAll() {
      if (this.isAllSelected) {
        this.concrete_selecteds = this.concrete_selecteds.filter(id =>
          this.$_invoices.some(invoice => invoice.id === id &&
            [this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
        );

        this.services_selecteds = this.services_selecteds.filter(id =>
          this.$_invoices.some(invoice => invoice.id === id &&
            [this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
        );

        this.additionals_selecteds = this.additionals_selecteds.filter(id =>
          this.$_invoices.some(invoice => invoice.id === id &&
            [this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
        );

        this.single_selecteds = this.single_selecteds.filter(id =>
          this.$_invoices.some(invoice => invoice.id === id &&
            [this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
        );
      } else {
        this.concrete_selecteds = this.$_invoices
          .filter(invoice => invoice.type === this.billingTypeEnum.CONCRETE &&
            ![this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
          .map(invoice => invoice.id)
          .concat(this.concrete_selecteds);

        this.services_selecteds = this.$_invoices
          .filter(invoice => invoice.type === this.billingTypeEnum.SERVICE &&
            ![this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
          .map(invoice => invoice.id)
          .concat(this.services_selecteds);

        this.additionals_selecteds = this.$_invoices
          .filter(invoice => invoice.type === this.billingTypeEnum.ADDITIONAL &&
            ![this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
          .map(invoice => invoice.id)
          .concat(this.additionals_selecteds);

        this.single_selecteds = this.$_invoices
          .filter(invoice => invoice.type === this.billingTypeEnum.SINGLE && invoice.approved &&
            ![this.billingStatusEnum.CANCELED, this.billingStatusEnum.CANCELED_CONCRETE].includes(invoice.status))
          .map(invoice => invoice.id)
          .concat(this.single_selecteds);
      }
    },
    hasAnyFederalTaxRetained(item) {
      return (item.cofins_retained_flag && strToNum(item.cofins_rate) > 0) ||
        (item.csll_retained_flag && strToNum(item.csll_rate) > 0) ||
        (item.inss_retained_flag && strToNum(item.inss_rate) > 0) ||
        (item.ir_retained_flag && strToNum(item.ir_rate) > 0) ||
        (item.pis_retained_flag && strToNum(item.pis_rate) > 0);
    },
    getPaymentMethods() {
      if(!this.payment_methods) return [];

      const payment_method_splited = this.params.payment_methods.split(',').map((item) => Number(item));
      return this.payment_methods.filter((item) => payment_method_splited.includes(item.id));
    },
    getBankAccounts(bankAccounts) {
      const bank_accounts_splited = bankAccounts.split(',').map(function (item) {
        return Number(item)
      })
      return this.bank_accounts.filter(item => {
        return bank_accounts_splited.includes(item.id)
      })
    },
    setRequireBalance() {
      if(!this.params) {
        return
      }
      let payment_term = this.payment_terms.find((payment_term) => payment_term.id === item.payment_term_id)
      if (!this.params.use_anticipation_balance && payment_term.requires_balance) {
        this.params.use_anticipation_balance = 1
      }
    },
    handleOsClick(item){
      if(item.type === this.billingTypeEnum.CONCRETE) {
        this.downloadProof(item.sequencial_number_plant_schedule_travel_uuid)
      } else if(item.type === this.billingTypeEnum.SERVICE) {
        this.getReport(item.schedule_service_order_uuid)
      }
    },
    handleEditClick(item){
      if(item.type === this.billingTypeEnum.SERVICE){
        this.handleModalChangeServiceValue(item);
      } else if(item.type === this.billingTypeEnum.ADDITIONAL) {
        this.handleModalChangeAdditionalValue(item);
      }
    },
    setViewType(type) {
      this.listType = type
      setPreferredViewMode(type);
    },
    handleApprove(item) {
      if (!this.$_financial_limit.invoice_can_add_single) {
        return this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para aprovar avulso'
        });
      }
      this.$Swal.confirmAction('Deseja realmente aprovar o avulso?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loading = this.$loading.show()
            this.$store.dispatch('detached/approve', {id: item.id, status: !item.approved}).then(response => {
              item.approved = !item.approved
              this.$notify({
                type: 'success',
                message: response.message
              });
              this.push(item.id, 4)
            }).finally(() => loading.hide())
          }
        }).catch(() => this.$Progress.finish())
    },
    getFinalValue() {
      let total = roundWithDecimal((Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle)) - this.reidi, 2);
      if (this.params.iss_retain) {
        total -= this.sumISS;
      }
      total -= this.sumFederalTaxes;
      return total;
    },
    getTotalValue() {
      return roundWithDecimal((Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle) - this.reidi), 2)
    },
    handleUpdateVersion(id, version, index) {
      if (! this.$_financial_limit.invoice_can_change_concrete_price) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar a versão de preço',
        })
      }
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("travels/updateVersion", {
          id: id,
          version: version
        }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.init(false)
        loader.hide()
      })
    },
    removeSingle(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
          this.$store.dispatch('billingInvoice/destroySingle', id).then(response => {
            this.$notify({type: response.error_type, message: response.message})
            this.$Progress.finish()
          }).catch(error => {
            this.$notify({type: error.error_type, message: error.message})
            this.$Progress.finish()
          })
        }
      })
    },
    push(id, type) {
      switch (type) {
        case 1:
          this.concrete_selecteds = this.$helper.Array.selecteds(this.concrete_selecteds, id)
          break;
        case 2:
          this.services_selecteds = this.$helper.Array.selecteds(this.services_selecteds, id)
          break;
        case 3:
          this.additionals_selecteds = this.$helper.Array.selecteds(this.additionals_selecteds, id)
          break;
        case 4:
          let item = this.$_invoices.find((item) => item.id == id)
          if (! item.approved) {
            if(this.listType === 'cards') {
              return this.$notify({type: 'danger', message: 'O avulso deve ser aprovado para ser faturado'})
            } else {
              return this.handleApprove(item);
            }
          }
          this.single_selecteds = this.$helper.Array.selecteds(this.single_selecteds, id)
          break;
      }
      let total_value = roundWithDecimal(Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle), 2)
      if (this.reidi_percentage && this.contract_proposal.reidi) {
        this.reidi = roundWithDecimal(total_value / 100 * this.reidi_percentage, 2)
      }
    },
    format(value) {
      if (!isNaN(value)) {
        return this.$helper.secondsToTime(value * 60, true)
      }
      return value
    },
    close() {
      this.params.deduction.deduct = this.defaultPercDeduction;
      this.user_defined_deduction_amount = false;
      this.modal.create = false
    },
    showModalCreateSingle() {
      this.$refs.createSingle.openModal()
      this.$refs.createSingle.single.date_at = this.params.filter.range.end
    },
    init(loading = true) {
      this.loadingSkeleton = loading
      this.$store.dispatch('billingInvoice/getBills',
        {
          filter: this.params.filter,
          contract_proposal_id: this.contract_proposal.id
        }).then(response => {
        this.loadingSkeleton = false
      }).catch(error => {
        this.loadingSkeleton = false
      })
    },
    save() {
      if (this.params.filter && this.params.filter.agrouped) {
        return this.$emit('agrouped', {
          concreteds: this.concrete_selecteds,
          services: this.services_selecteds,
          additionals: this.additionals_selecteds,
          single: this.single_selecteds,
        })
      }
      this.$Swal.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          // this.loading = true
          const selecteds = [
            {
              id: this.params.id,
              company_plant_id: this.params.company_plant_id,
              iss_retain: this.params.iss_retain,
              cofins_rate: strToNum(this.params.cofins_rate),
              csll_rate: strToNum(this.params.csll_rate),
              inss_rate: strToNum(this.params.inss_rate),
              ir_rate: strToNum(this.params.ir_rate),
              pis_rate: strToNum(this.params.pis_rate),
              payment_term_id: this.params.payment_term_id,
              payment_method_id: this.params.payment_method_id,
              payment_intermediary_id: this.params.payment_intermediary_id,
              bank_account_id: this.params.bank_account_id,
              company_plant_issuer_id: this.params.plant_issuer_id,
              considere_prepayment: this.params.considere_prepayment,
              use_anticipation_balance: this.params.use_anticipation_balance,
              concreteds: this.concrete_selecteds,
              services: this.services_selecteds,
              additionals: this.additionals_selecteds,
              single: this.single_selecteds,
            }
          ]
          const date = {
            start: parseDate(this.params.filter.range.start, 'YYYY-MM-DD'),
            end: parseDate(this.params.filter.range.end, 'yyyy-MM-DD'),
          }

          let previousIssRetain = this.issChangingVerifierClone.find(invoice => invoice.id === this.params.id)?.iss_retain;
          this.$store.dispatch('billingInvoice/generateInvoices', {
            items: selecteds,
            range: date,
            user_defined_deduction_amount: this.user_defined_deduction_amount ? this.deduct : null,
            user_defined_iss_retain: Boolean(previousIssRetain) !== Boolean(this.params.iss_retain),
          }).then(response => {
            this.$notify({type: response.error_type, message: response.message});
            this.close()
            this.$emit('closeAndUpdateInvoiced', this.params.key)
            this.loading = false
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
            this.loading = false
          })
        }
      });
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    async loadDropdowns() {
      await this.$store.dispatch("paymentTerm/fetchItems");
      await this.$store.dispatch("paymentMethod/fetchItems");
      await this.$store.dispatch("bankAccount/fetchItems");

      this.getPaymentTerms();
    },
    getPaymentTerms() {
      if(!this.params) {
        return [];
      }

      const payment_terms_splited = this.params.payment_terms.split(',').map(function (item) {
        return Number(item)
      });
      this.params.payment_term_id = null
      this.params.payment_intermediary_id = null
      let paymentMethod = this.payment_methods.find((self) => self.id === this.params.payment_method_id)
      this.payment_terms_by_method[this.params.id] = this.payment_terms.filter(function (selected) {
        let has_method = selected.payment_methods.find((method) => method.uuid === paymentMethod.uuid)
        if (has_method && payment_terms_splited.includes(selected.id)) {
          return selected
        }
      })

      // BUSCA MEDIADOR DE PAGAMENTO
      this.payment_intemerdiaries_selected[this.params.id] = paymentMethod.payment_intermediaries
      this.params.payment_intermediaries_exists = paymentMethod.payment_intermediaries.length > 0
      if (this.payment_terms_by_method[this.params.id].length === 1) {
        this.params.payment_term_id = this.payment_terms_by_method[this.params.id][0].id
      }
    },
    async handleCreate(params, invoice = true, index = null) {
      this.listType = getPreferredViewMode() ?? 'cards';
      this.defaultPercDeduction = params.deduction.deduct;
      this.index = index;
      const loader = progress.showLoader()
      await this.$store.dispatch("contractProposal/show", params.uuid);

      this.invoice = invoice
      this.prepareFederalTaxes(params);
      let param_taxes = {
        issuerRegime: params.tax_regime,
        commercialTax: 'REIDI'
      };
      this.concrete_selecteds = []
      this.services_selecteds = []
      this.additionals_selecteds = []
      this.single_selecteds = []
      this.params = params;

      await this.loadDropdowns();
      await this.fetchFinancialLimits(this.$user.id)
      this.modal.create = true
      this.loadingSkeleton = true
      await Promise.all([
        this.$store.dispatch('companyPlantIssuer/fetchItems'),
        this.$store.dispatch('billingInvoice/getBills',
          {
            filter: this.params.filter,
            contract_proposal_id: params.id
          }),
      ]).then(() => {
        this.$_invoices.forEach(function (item) {
          switch (item.type) {
            case 1:
              this.concrete_selecteds = this.$helper.Array.selecteds(this.concrete_selecteds, item.id)
              break;
            case 2:
              this.services_selecteds = this.$helper.Array.selecteds(this.services_selecteds, item.id)
              break;
            case 3:
              this.additionals_selecteds = this.$helper.Array.selecteds(this.additionals_selecteds, item.id)
              break;
            case 4:
              if (item.approved == 1) {
                this.single_selecteds = this.$helper.Array.selecteds(this.single_selecteds, item.id)
              }
              break;
          }
        }, this)
        this.loadingSkeleton = false
      }).catch(error => {
        this.loadingSkeleton = false
      })
      await this.$store
        .dispatch("deductionRules/getCurrentTaxes", param_taxes).then(response => {
          let commercial_tax = response.data
          let total_value = roundWithDecimal(Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle), 2)
          if (commercial_tax?.total_rate && this.contract_proposal.reidi) {
            this.reidi = roundWithDecimal(total_value / 100 * commercial_tax.total_rate, 2)
            this.reidi_percentage = commercial_tax.total_rate
          }
          else {
            this.reidi = 0
            this.reidi_percentage = null
          }
        });

      this.defaultValDeduction = this.deduct;
      loader.hide();
    },
    prepareFederalTaxes(params) {
      params.cofins_rate = params.cofins_retained_flag && strToNum(params.cofins_rate) > 0
        ? strToNum(params.cofins_rate)
        : 0;
      params.csll_rate = params.csll_retained_flag && strToNum(params.csll_rate) > 0
        ? strToNum(params.csll_rate)
        : 0;
      params.inss_rate = params.inss_retained_flag && strToNum(params.inss_rate) > 0
        ? strToNum(params.inss_rate)
        : 0;
      params.ir_rate = params.ir_retained_flag && strToNum(params.ir_rate) > 0
        ? strToNum(params.ir_rate)
        : 0;
      params.pis_rate = params.pis_retained_flag && strToNum(params.pis_rate) > 0
        ? strToNum(params.pis_rate)
        : 0;
    },
    /**
     * @param {string} schedule_travel_uuid
     */
    downloadProof(schedule_travel_uuid) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "proof"
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    /**
     * @param {string} service_order_uuid
     */
    getReport(service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", service_order_uuid)
        .then(response => {
          let blob = new Blob([response], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },

    /**
     * Abre modal de alteração de valor de serviço
     *
     * @param {object} item
     * @returns void
     */
    handleModalChangeServiceValue(item) {
      if (!this.$user.financial_limit?.invoice_can_change_service_value) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar o preço do serviço ',
        })
      }
      this.$refs.modalChangeServiceValue.openModal(item.id)
    },
    /**
     * Abre modal de alteração de valor de adicionais
     *
     * @param {object} item
     * @returns void
     */
    handleModalChangeAdditionalValue(item) {
      if (!this.$user.financial_limit?.invoice_can_change_additional_value) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar o preço do aditional ',
        })
      }
      this.$refs.modalChangeAdditionalValue.openModal(item)
    },
  },
}
</script>

<style scoped>
.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border: 1px solid #eeeeee;
  border-radius: 8px !important;
  padding: 12px;
  margin-bottom: 12px;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
}

.new-cardwithbox-shadow div {
  display: inline-flex;
  align-items: center;
  /*  padding-top: 4px;*/
}

.listView-container {
  border-radius: 16px;
  border: 1px solid #E8E8E8;
  padding: 15px 20px;
}

.header-individual-infos {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-individual-infos > div {
  flex: 1 1 25%; /* Garante que todos os divs tenham o mesmo tamanho */
  display: flex;
  flex-direction: column; /* Permite que o conteúdo interno seja empilhado */
  justify-content: space-between; /* Distribui o conteúdo verticalmente */
  align-items: center; /* Centraliza os itens horizontalmente */
  box-sizing: border-box; /* Inclui padding e border no tamanho do elemento */
}

/* Estilos para visualização mobile (até 798px) */
@media (max-width: 991px) {
  .header-individual-infos {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .header-individual-infos > div {
    flex: 1 1 40%;
    width: 50%;
  }
}

.individual-info-primary {
  background: #F2F4F9;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: left;
}

.individual-info-gray {
  background: #E8E8E8;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: left;
}

.individual-info-warning {
  background: #FEF9F2;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: left;
}

.individual-info-success {
  background: #F2F7F3;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  text-align: left;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border: 1px solid #F2F7F3;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red {
  background-color: #FCF3F3;
  border: 1px solid #FCF3F3;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border: 1px solid #FEF9F2;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border: 1px solid #F2F4F9;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border: 1px solid #eeeeee2e;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-green-outline {
  background-color: #FFFFFF;
  border: 1px solid #149e57;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red-outline {
  background-color: #FFFFFF;
  border: 1px solid #db4539;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow-outline {
  border: 1px solid #f2b532;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue-outline {
  border: 1px solid #1b6eba;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray-outline {
  background-color: #FFFFFF;
  border: 1px solid#646e7d2e;
  border-radius: 15px;
  padding: 2px 12px;
  /*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-green h4, .new-minicard-green-outline h4 {
  color: #149e57;
  margin: 0;
  font-weight: normal !important;
  font-size: 13px;
  margin-left: 10px;
}

.new-minicard-red h4, .new-minicard-red-outline h4 {
  color: #db4539;
  margin: 0;
  font-weight: normal !important;
  font-size: 13px;
}

.new-minicard-yellow h4, .new-minicard-yellow-outline h4{
  color: #f2b532;
  margin: 0;
  font-weight: normal !important;
  font-size: 13px;
}

.new-minicard-blue h4, .new-minicard-blue-outline h4 {
  color: #1b6eba;
  margin: 0;
  font-weight: normal !important;
  font-size: 13px;
}

.new-minicard-gray h4, .new-minicard-gray-outline h4 {
  color: #66696c;
  margin: 0;
  font-weight: normal !important;
  font-size: 13px;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray,
.new-minicard-red-outline, .new-minicard-green-outline, .new-minicard-yellow-outline, .new-minicard-blue-outline, .new-minicard-gray-outline {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child,
.new-minicard-red-outline img:first-child, .new-minicard-green-outline img:first-child, .new-minicard-yellow-outline img:first-child, .new-minicard-blue-outline img:first-child, .new-minicard-gray-outline img:first-child {
  width: 16px;
}

.new-nanocard-green, .new-nanocard-red {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  margin-left: 10px
}

.new-nanocard-green img:first-child, .new-nanocard-red img:first-child {
  width: 14px;
}

.new-nanocard-green {
  background-color: #F2F7F3;
  border: 1px solid #F2F7F3;
  border-radius: 15px;
  padding: 1px 6px;
}

.new-nanocard-green h4 {
  color: #149e57;
  font-weight: normal !important;
  font-size: 10px;
  margin: 0 0 0 5px;
}

.new-nanocard-red {
  background-color: #FCF3F3;
  border: 1px solid #FCF3F3;
  border-radius: 15px;
  padding: 1px 6px;
}

.new-nanocard-red h4 {
  color: #db4539;
  font-weight: normal !important;
  font-size: 10px;
  margin: 0 0 0 5px;
}

.new-minicard-table {
  display: block;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.listView-container-fluid {
  border-radius: 16px;
  border: 1px solid #E8E8E8;
  padding: 60px 0 15px;
}

.listView-scroll {
  max-height: 650px;
  overflow-y: auto;
}

.listView-container-header {
  position: absolute;
  top: 1px;
  left: 31px;
  right: 31px;
  height: 50px;
  z-index: 2;
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-left: 17px;
  padding-top: 14px;
}

.listView-container-header .listView-container-header-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.version-identifier {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #1A70B7;
  margin-left: 10px;
  width: min-content;
}

.version-identifier img {
  transform: rotate(270deg);
}

.version-identifier h4 {
  font-size: 12px;
}

.use-balance {
  background: #F2F4F9;
  border-radius: 4px;
  padding: 3px;
}

.use-balance h4 {
  color: #1A70B7;
}

.dont-use-balance {
  background: #E8E8E8;
  border-radius: 8px;
  padding: 3px;
}

.dont-use-balance h4 {
  color: #606062;
}
</style>
<style lang="scss">
@import "@/style/PuzlCustom/App";

// MODAL
.modal.individual-billing {

  @media(max-width: 768px) {
    div.modal-xxl {
      max-width: 100% !important;
      margin-left: 5px;
      margin-right: 5px;
    }
  }



  @media(min-width: 767px) {
    .text-right-md {
      text-align: right;
    }
  }

  @media(max-width: 1440px){
    .billed-period h4 {
      font-size: 11px;
    }
  }

  label {
    @include rhythm(font-size, 16);
    margin-bottom: 0;
    font-weight: 400;
  }

  //BADGE
  .badge {
    border-radius: 100px;
    min-height: 28px !important;
    min-width: 135px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $muted-light !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  // PUZL-SELECT RESET
  .vs--single > div.vs__dropdown-toggle {
    height: 32px !important;
  }

  // MODAL-BODY
  .modal-body {
    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      img {
        width: 24px;
        height: 24px;
      }

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }

      .hr {
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        transform-origin: top;
        background-color: $muted-light;
      }
    }
    .app-form-group {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;

      @media (max-width: 991.98px) {
        &:not(.badge-reset) {
          flex-wrap: wrap;
        }
      }

      label {
        @include rhythm(font-size, 16);
        margin-bottom: 0;
        font-weight: 400;

        @media (max-width: 991.98px) {
          padding-bottom: 4px;
          width: 100%;
        }
      }

      .wrapper > span {
        display: flex;
        flex-wrap: inherit;
        width: 100%;

        & .v-select {
          width: 100%;

          .vs__search {
            box-shadow: none !important;
          }
        }
      }

      .input-append {
        text-wrap: nowrap;
        padding: 4px 8px;
        line-height: 1;
        border: 1px solid $muted-light;
        background-color: white;
        @include rhythm(font-size, 14);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .input {
        display: block;
        border: none;
        @include rhythm(font-size, 14);
        box-shadow: 0 0 0 0.5px #e8e8e8, 0px 5px 10px 0px #0000000d !important;
        border-radius: 4px;
        padding: 4px 8px;
        box-sizing: border-box;
        height: 32px;
        width: 100%;

        &:disabled {
          background-color: $muted-light;
        }

        &.is-invalid {
          box-shadow: 0 0 0 1px $danger, 0px 5px 10px 0px #0000000d !important;
          animation: shake 0.3s ease-in-out;

          &:focus {
            outline: none;
          }
        }

        &.is-valid {
          box-shadow: 0 0 0 1px $success, 0px 5px 10px 0px #0000000d !important;

          &:focus {
            outline: none;
          }
        }

        &.radius-right-0 {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        /* Shake animation */
        @keyframes shake {
          0% {
            transform: translateX(0);
          }
          25% {
            transform: translateX(-5px);
          }
          50% {
            transform: translateX(5px);
          }
          75% {
            transform: translateX(-5px);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
    }
    //BADGE-ROW
    .badge-row {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 24px;
      padding: 0;

      @media (max-width: 991.98px) {
        flex-wrap: wrap;
      }

      .badge-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 4px;

        @media (max-width: 991.98px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: start;
        }

        .label {
          @media (max-width: 991.98px) {
            max-width: 50%;
          }
        }

        a {
          width: 100%;
          @media (max-width: 991.98px) {
            padding-left: 15px !important;
            max-width: 50%;
          }
        }
        .badge {
          width: 100%;

          @media (max-width: 991.98px) {
            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }

  // MODAL-FOOTER
  .modal-footer {
    gap: 10px;
    padding-top: 1rem !important;

    .button {
      all: unset;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      padding: 8px 20px;
      display: flex;
      gap: 10px;
      background-color: white;
      justify-content: center;
      align-items: center;
      min-width: 140px;
      @include rhythm(font-size, 14);

      &:hover {
        .app-icon {
          filter: brightness(0) invert(1);
        }
        img {
          filter: brightness(0) invert(1);
        }
      }

      &.danger {
        border: 1px solid $danger;
        color: $danger;

        &:hover {
          background-color: $danger;
          color: white;
        }
      }

      &.success {
        border: 1px solid $success;
        color: $success;

        &:disabled {
          opacity: 0.5;

          &:hover {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        &:hover {
          background-color: $success;
          color: white;
        }
      }
    }
  }
}
</style>
