<template>
  <div>
    <div class="row card-wrapper" v-if="bill_receives.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(bill_receive, index) in bill_receives" :key="index">
        <div class="card main-card mb-3">
          <div class="p-3 px-4">
            <div class="row align-items-center mt-2">
              <div class="col-auto">
                <BillReceivePossibleActions :bill_receive="bill_receive"
                  :listType="listType"
                  :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
                  :changeInstallmentStatus="changeInstallmentStatus"
                  :index="index" />
              </div>
              <div class="col px-0">
                <a outline size="sm" type="secundary"
                  :title="`Saldos do cliente - Antecipação: R$: ${formatCurrency(bill_receive.anticipation_balance)}`"
                  @click.prevent="$emit('handleModalEntityHistory',
                    bill_receive.entity_id,
                    bill_receive.entity_uuid,
                    bill_receive.document,
                    bill_receive.entity_name
                  )"
                  class="p-0 m-0 mr-2 shadow-none text-indigo pointer">
                    <img src="/img/icons/wallet.png" width="25">
                </a>
                <base-button @click.prevent="$emit('handleListContacts', bill_receive.customer_construction_uuid)"
                  outline size="sm" type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/phone-contact_warning.png" width="25">
                </base-button>
                <el-popover v-if="bill_receive.compensation_user_name && bill_receive.status === 1" trigger="click" placement="left">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/us-dollar-circled--v1.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Compensação de Crédito</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{ bill_receive.compensation_user_name }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal">{{ bill_receive.compensation_created_at | parseDate("DD/MM/YYYY") }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/us-dollar-circled--v1.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover v-else trigger="click" placement="left">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/merchant-account.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Conta bancária</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{bill_receive.installment_bank_account_name || bill_receive.bank_account_name }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/merchant-account.png" width="25">
                  </base-button>
                </el-popover>
                <img
                  class="pointer"
                  v-show="Boolean(bill_receive.exists_attachments)"
                  src="/img/icons/attach-green.png"
                  @click.prevent="$emit('handleShowAttachments', bill_receive, index)"
                  width="25"
                />
              </div>
              <img
                :src="send_emails.includes(bill_receive.id)
                  ? '/img/icons/icons8/ios/checked-checkbox.png'
                  : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
                class="btn-send-emails"
                width="32"
                v-if="send_to_email && bill_receive.type === 1 && parseInt(bill_receive.status) !== 3"
                @click.prevent="$emit('addSendToEmails', bill_receive.id)"
              >
              <SettingOptions v-else
                  :bill_receive="bill_receive"
                  :listType="listType"
                  :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
                  @handleEditBillReceive="$emit('handleEditBillReceive', bill_receive.contract_proposal_payment_uuid, bill_receive.contract_proposal_uuid)"
                  @handleShowHistory="$emit('handleShowHistory', bill_receive)"
                  @handleOpenModalInfo="$emit('handleOpenModalInfo', bill_receive)"
                  @handleIdentifyEntity="$emit('handleIdentifyEntity', bill_receive)"
                  @handleModalEntityRegistry="$emit('handleModalEntityRegistry', bill_receive.entity_id, bill_receive.entity_uuid, bill_receive.entity_name,bill_receive.document,)"
                  @openModalChangeDueDate="$emit('openModalChangeDueDate', bill_receive.installment_id, bill_receive.original_due_date)"
                  @showModalEditGenerateBilletHasGenerated="$emit('showModalEditGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type, bill_receive.index, bill_receive.index)"
                  @showModalGenerateBillet="$emit('showModalGenerateBillet', bill_receive.id, bill_receive.total_index, bill_receive.type)"
                  @handleDeleteBillReceive="$emit('handleDeleteBillReceive', bill_receive.id)"
                  @showModalGenerateBilletHasGenerated="$emit('showModalGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type)"
                  @postBatchUpdate="$emit('postBatchUpdate', bill_receive.bank_billet_batch_id)" />
            </div>
            <div class="row mt-3 align-items-end">
              <div class="col-7">
                <h5 class="font-weight-normal new-default-black-font m-0 mt-1">
                  {{ bill_receive.payment_method_name }}
                </h5>
                <div style="display: flex;align-items: baseline;">
                  <p style="font-size: 16px;" class="m-0 new-default-black-font font-weight-bold p-0 mr-1">R$</p>
                  <h1 class="m-0">{{ bill_receive.value  | currency('') }}</h1>
                </div>
                <h5 class="new-default-gray-font font-weight-normal m-0" style="display: flex;align-items: center;">
                  {{ bill_receive.status != 1 ? bill_receive.due_date : bill_receive.paid_at | parseDate('DD MMM YYYY') }}
                  <div class="ml-3 no-shadow-card-blue"
                    style="display: inline-flex;">
                    <h4 class="m-0 p-0">
                      {{ bill_receive.index + "/" + bill_receive.total_index }}
                    </h4>
                  </div>
                </h5>
              </div>
              <div v-show="showCompensationButtons(bill_receive)" class="col-5">
                <div v-if="$hasPermission('additional_permissions.financial_approvation') &&
                  $helper.strToNum(bill_receive.anticipation_balance) > 0"
                  class="col py-1 px-0">
                  <div @click.prevent="$emit('showModalEntityPayment', bill_receive.contract_proposal_payment_uuid, bill_receive.installment_id, bill_receive.contract_proposal_uuid)"
                    class="colorize-on-hover new-cardwithbox-shadow pointer text-left pl-2"
                    style="font-size: 12px;">
                    <div>
                      <img width="20" src="/img/icons/icons8/ios/refund-2_primary.png"
                        class="invert-on-hover mr-2"
                        style="display: inline-flex;" />
                      <small style="font-size: 12px;display: inline-flex;"
                        class="font-weight-400 new-default-black-font">
                          Compensação Parcial
                      </small>
                    </div>
                  </div>
                </div>
                <div v-if="bill_receive.type === 1 &&
                  compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
                  $hasPermission('bill_receive.receive_installment')"
                  class="col pt-1 pb-0 px-0">
                  <div @click.prevent="$emit('handlePayOffPayment', bill_receive)"
                    class="colorize-on-hover new-cardwithbox-shadow pointer text-left pl-2"
                    style="font-size: 12px;">
                    <div>
                      <img width="20" src="/img/icons/icons8/ios/us-dollar-circled--v1.png"
                        class="invert-on-hover mr-2"
                        style="display: inline-flex;" />
                      <small style="font-size: 12px;display: inline-flex;"
                        class="font-weight-400 new-default-black-font">
                          Compensação total
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="gray-divider mt-3 mb-2">
            <div class="row align-items-center">
              <div class="col-12">
                <h3 class="m-0 mb-1 new-default-black-font">
                  {{ bill_receive.contract_proposal_code }}
                </h3>
                <el-popover
                  trigger="click"
                  placement="top"
                  v-if="bill_receive.entity_id || bill_receive.type === 1"
                >
                  <span
                    style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row"
                  >
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;"
                    >
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font"
                      >
                        CPF/CNPJ
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0"
                    >
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12"
                    >
                      <h3
                        class="m-0 mb-1 font-weight-400 new-default-black-font"
                      >
                        {{ bill_receive.document }}
                      </h3>
                    </div>
                  </span>
                  <h3
                    slot="reference"
                    class="m-0 font-weight-400 new-default-black-font pointer"
                    style="width: fit-content;"
                  >
                    {{ bill_receive.entity_name }}
                  </h3>
                </el-popover>
                <h4 v-if="!bill_receive.entity_id || bill_receive.type === 0"
                  class="m-0 mb-1 font-weight-400 new-default-black-font">
                  <small class=" text-muted">
                    <img width="22" src="/img/icons/not_identified.png"/>
                    DEPÓSITO NÃO IDENTIFICADO
                  </small>
                </h4>
              </div>
            </div>
            <div class="row px-2 mt-2 pt-1 mb-1 align-items-center">
              <div v-if="bill_receive.invoices.length && bill_receive.type === 1"
                class="col-4 p-1 px-2">
                <a @click.prevent="$emit('downloadInvoice', bill_receive.invoices[0].id)"
                  class="new-cardwithbox-shadow" href="#" style="display: inherit;height: 30px;">
                  <div class="new-default-black-font" style="font-size: 12px;display: block;">
                    <img src="/img/icons/pdf-v2.png" class="mr-1" width="18">
                    {{ bill_receive.invoices[0].id }}
                  </div>
                </a>
              </div>
              <div v-if="bill_receive.invoices.length && bill_receive.type === 1"
                class="col-4 p-1 px-2">
                <a v-if="bill_receive.invoices[0].number !== 'null' && bill_receive.invoices[0].number !== null"
                  @click.prevent="$emit('nfseButtonClick', bill_receive.invoices[0].link_pdf)"
                  class="new-cardwithbox-shadow" href="#" style="display: inherit;height: 30px;">
                  <div class="new-default-black-font" style="font-size: 12px;display: block;">
                    <img class="mr-1" width="18" src="/img/icons/nfe_blue_cropped.png"/>
                    {{ bill_receive.invoices[0].number.toString().slice(-6) }}
                  </div>
                </a>
                <div v-else class="new-cardwithbox-shadow" style="display: inherit;height: 30px;">
                  <div class="new-default-black-font" style="font-size: 12px;display: block;">
                    <img class="mr-1" width="16" src="/img/icons/nfe_blue_cropped.png"/>
                    Não emitida
                  </div>
                </div>
              </div>
              <div v-if="bill_receive.last_billet !== false" class="col-4 p-1 px-2">
                <a @click.prevent="$emit('downloadBilletBatch', bill_receive.bank_billet_batch_id, bill_receive.last_billet)"
                  class="new-cardwithbox-shadow" href="#" style="display: inherit;height: 30px;">
                  <div class="new-default-black-font" style="font-size: 12px; display: inline-flex;align-items: center;">
                    <i class="mr-2 fa-solid fa-barcode" style="font-size: 16px;"
                       :class="backgroundBillet(bill_receive.last_billet)"></i>
                    {{ textBillet(bill_receive.last_billet) }}
                  </div>
                </a>
              </div>
              <div v-if="bill_receive.last_billet === false
                && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
                && parseInt(bill_receive.status) !== 3
                && parseInt(bill_receive.status) !== 1"
                class="col-4 p-1 px-2">
                <a @click.prevent="$emit('showModalGenerateBillet', bill_receive.id, bill_receive.total_index, bill_receive.type)"
                  class="new-cardwithbox-shadow" href="#" style="display: inherit;height: 30px;">
                  <div class="new-default-black-font" style="font-size: 12px; display: inline-flex;align-items: center;">
                    <i class="mr-2 fa-solid fa-barcode text-dark" style="font-size: 16px;"></i>
                    Emitir
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SettingOptions from "../Common/_SettingOptions.vue";
import BillReceivePossibleActions from "../Common/_BillReceivePossibleActions.vue";

export default {
  name: "CardsBillReceive",
  props: {
    bill_receives: Array,
    loadingSkeleton: Boolean,
    paymentInstallmentStatusEnum: Object,
    send_to_email: Boolean,
    send_emails: Array,
    showCompensationButtons: { type: Function },
    changeInstallmentStatus: { type: Function },
    formatCurrency: { type: Function },
    compareBalance: { type: Function },
    listType: String,
  },
  components: {
    SkeletonPuzlGrid,
    SettingOptions,
    BillReceivePossibleActions
  },
  methods: {
    backgroundBillet(bank_billet) {
      switch (bank_billet.status) {
        case 0:
          return 'text-primary'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
        case 3:
          return 'text-success'
        case 4:
          return 'text-darkred'
        case 5:
        case 6:
          return 'text-dark'
      }
    },
    textBillet(bank_billet) {
      switch (bank_billet.status) {
        case 0:
          return 'Gerando'
        case 1:
          return 'Emitido'
        case 2:
          return 'Cancelado'
        case 3:
          return 'Pago'
        case 4:
          return 'Vencido'
        case 5:
          return 'Falha'
        case 6:
          return 'Inválido'
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid #E8E8E8;
}

.colorize-on-hover:hover {
  background-color: #1b6eba;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
  filter: brightness(0) invert(1);
}

.btn-send-emails {
  position: absolute;
  cursor: pointer;
  right: 32px;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-cardwithbox-shadow div {
  display: inline-flex;
  align-items: center;
/*  padding-top: 4px;*/
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #5394c9;
  padding: 0px 5px;
  box-shadow: 0 0 2px 0 #0b7bfd52;
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}
</style>


