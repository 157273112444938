<template>
  <div class="row card-wrapper" v-if="requests.length">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in requests" :key="index">
      <div class="card main-card">
        <div class="pt-3 px-3 pb-0 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <base-dropdown menuOnRight class="d-flex justify-content-center dropdown-status">
                <div slot="title-container" class="dropdown-toggle rounded">
                  <div class="align-self-center card-status mr-3 mb-1 pointer d-flex align-items-center"
                    :class="getStatusClass(item.order_status)">
                    <img class="mr-1" ref="icon" height="17" :src="getStatusIcon(item.order_status)" />
                    <span style="font-size: 13px; margin: 3px 3px 0 0;">{{ getStatusText(item.order_status) }}</span>
                    <img class="mr-1" ref="icon" height="15" :src="getArrowIcon(item.order_status)" />
                  </div>
                </div>
                <div>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.OPEN"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.COMPLETED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/double-tick-primary.png" width="18" alt="">
                    Concluir
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.COMPLETED || item.order_status == PurchaseOrderStatusEnum.CANCELED"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.OPEN)" class="dropdown-item d-flex"
                    style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/circle-left_success.png" width="18" alt="">
                    Retomar
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.OPEN, true)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/thumb-up_success.png" width="18" alt="">
                    Aprovar
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.DENIED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/thumbs-down_danger.png" width="18" alt="">
                    Negar
                  </a>
                  <div v-if="item.order_status == PurchaseOrderStatusEnum.OPEN"
                    style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.OPEN || item.order_status == PurchaseOrderStatusEnum.PENDING"
                    @click.prevent="handleShowModal({ ref: 'modalOrderDeniedJustification', data: item })"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="/img/icons/icons8/ios/unavailable_danger.png">
                    Cancelar
                  </a>
                </div>
              </base-dropdown>
              <div style="margin-top: 3px;">
                <!-- ICONES -->
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div class="mr-2"
                    @click="showButtonsModal = false; handleShowModal({ ref: 'modalListItemsApproval', data: item })">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                        </div>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2 d-none">
                    <el-popover trigger="hover" placement="bottom" class="p-0">
                      <div style="font-size: 12px; font-weight: 500">DOCUMENTOS VINCULADOS:</div>
                      <div class="dropdown-divider p-0 mx-0" />
                      <div style="font-size: 11px">2532 - 12312 - 2312 - 88768 - 8768 - 8774</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/link_primary.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1" tabindex="0" data-bs-toggle="tooltip" :title="item.code">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32; cursor: pointer;">
                {{ item.order_code || item.code }}
              </span>
            </div>
            <div class="d-flex">
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ $helper.parseDate(item.deadline) }}
              </span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ item.purchase_category_description }}
              </span>
            </div>
            <div>
              <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">
                {{ item.entity_name }}
              </span>
            </div>
          </div>
          <!-- Menu engrenagem -->
          <div>
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a v-if="item.order_status == PurchaseOrderStatusEnum.COMPLETED || item.order_status == PurchaseOrderStatusEnum.OPEN"
                  @click="downloadOrderResumePdf(item.id)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img src="/img/icons/icons8/ios/print-primary.png" width="22" alt="">
                  Imprimir
                </a>
                <div
                  v-if="item.order_status == PurchaseOrderStatusEnum.COMPLETED || item.order_status == PurchaseOrderStatusEnum.OPEN"
                  style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                <a @click="handleShowModal({ ref: 'modalHistory', data: item })" class="dropdown-item d-flex"
                  style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                  Histórico
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div class="dropdown-divider p-0 mx-3" />
        <div class="mx-3">
          <el-popover trigger="click" placement="top" class="p-0">
            <div style="font-size: 13.5px !important; font-weight: 500; letter-spacing: 1px;">Saldos</div>
            <div class="dropdown-divider p-0 mx-0" />
            <table style="width: 100%; font-size: 12px; margin-top: 8px;">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Realizado</th>
                  <th>Saldo</th>
                  <th>Barra</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subItem in item.items" :key="subItem.id">
                  <el-popover trigger="click" placement="top" class="p-0">
                    {{ subItem.description }}
                    <span slot="reference">
                      <td class="truncate-text">{{ subItem.description }}</td>
                    </span>
                  </el-popover>
                  <td>{{ $helper.toMoney(subItem.total_amount - subItem.balance) }}</td>
                  <td>{{ $helper.toMoney(subItem.balance) }}</td>
                  <td>
                    <div class="progress-bar-container mt-0">
                      <div class="progress-bar"
                        :style="{ width: progressPercentage(subItem.total_amount, subItem.balance) + '%' }"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <span slot="reference">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="mx-1">
                    <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                      Valor total
                    </span>
                  </div>
                  <div class="mx-1">
                    <span style="font-size: 15px ;font-weight: 500; color: #2B2D32;">
                      {{ $helper.toMoney(item.total_value) }}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                      Saldo
                    </span>
                  </div>
                  <span style="font-size: 15px ;font-weight: 500; color: #2B2D32;">
                    {{ $helper.toMoney(item.balance) }}
                  </span>
                </div>
              </div>
            </span>
          </el-popover>
        </div>


        <div class="progress-bar-container mb-3 mx-3">
          <div class="progress-bar" :style="{ width: progressPercentage(item.total_value, item.balance) + '%' }"></div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval @fetch="fetchEntityBudgets" @chageStatus="updateStatusOrder" ref="modalListItemsApproval" />
    <ModalOrderDeniedJustification @fetch="fetchEntityBudgets" ref="modalOrderDeniedJustification" />
    <ModalPurchaseOrderHistory ref="modalHistory" />
  </div>
</template>
<script>

import ModalListItemsApproval from "./Shared/_ModalListItemsApproval";
import ModalOrderDeniedJustification from "./Shared/_ModalOrderDeniedJustification";
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";
import ModalPurchaseOrderHistory from "./Shared/_ModalPurchaseOrderHistory.vue";

export default {
  name: "PurchasesOrderCards",
  components: {
    ModalListItemsApproval,
    ModalOrderDeniedJustification,
    ModalPurchaseOrderHistory
  },
  props: {
    requests: Array,
  },
  data() {
    return {
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    updateStatusOrder(purchaseOrder, status, isApproval = false) {
      const permissions = this.checkPermission(purchaseOrder, status);

      if (!permissions.hasPermission) {
        this.$notify({ type: 'warning', message: permissions.text });
        return;
      }
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: `Tem certeza que deseja ${permissions.action} essa requisição?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            const purchaseData = {
              id: purchaseOrder.id,
              purchase_order_id: purchaseOrder.id,
              status,
              code: purchaseOrder.order_code
            };
            const route = isApproval ? 'approval' : 'update';
            this.$store.dispatch(`purchaseOrder/${route}`, purchaseData)
              .then(() => {
                this.$emit('fetch');
                loader.hide();
                this.$notify({ type: 'success', message: 'Status atualizado' });
              })
              .catch(() => {
                loader.hide();
                this.$notify({ type: 'error', message: 'Erro ao atualizar o status!' });
              });
          }
        });
    },
    checkPermission(purchaseOrder, status) {
      if (purchaseOrder.limit_order_approval_value && purchaseOrder.total_value > purchaseOrder.max_approval_value) {
        this.$notify({ type: 'warning', message: 'Usuário não possui permissão para aprovar esse valor' });
        return
      }
      switch (status) {
        case this.PurchaseOrderStatusEnum.COMPLETED:
          return {
            hasPermission: purchaseOrder.purchase_order_complete && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para concluir!",
            action: "Concluir"
          };
        case this.PurchaseOrderStatusEnum.OPEN:
          if (purchaseOrder.order_status === PurchaseOrderStatusEnum.COMPLETED ||
            purchaseOrder.order_status === PurchaseOrderStatusEnum.CANCELED) {
            return {
              hasPermission: purchaseOrder.purchase_order_resume && purchaseOrder.can_approve,
              text: "Usuário não tem permissão para retomar!",
              action: "Retomar"
            };
          }
          return {
            hasPermission: purchaseOrder.purchase_order_approve && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para aprovar!",
            action: "Aprovar"
          };
        case this.PurchaseOrderStatusEnum.CANCELED:
          return {
            hasPermission: purchaseOrder.purchase_order_cancel && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para cancelar!",
            action: "Cancelar"
          };
        case this.PurchaseOrderStatusEnum.DENIED:
          return {
            hasPermission: purchaseOrder.purchase_order_denied && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para negar!",
            action: "Negar"
          };
        default:
          return {
            hasPermission: false,
            text: "Ação inválida!",
            action: ""
          };
      }
    },
    getStatusText(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "Pendente";
        case this.PurchaseOrderStatusEnum.OPEN: return "Aberta";
        case this.PurchaseOrderStatusEnum.DENIED: return "Negado";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "Concluída";
        default: return "Cancelado";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "pending-status";
        case this.PurchaseOrderStatusEnum.OPEN: return "approved-status";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "completed-status";
        default: return "denied-status";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
        case this.PurchaseOrderStatusEnum.OPEN: return "/img/icons/icons8/ios/shopping-cart.png";
        case this.PurchaseOrderStatusEnum.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "/img/icons/icons8/ios/double-tick-primary.png";
        default: return "/img/icons/icons8/ios/cancel_danger.png";
      }
    },
    getArrowIcon(status) {
      switch (status) {
        case this.PurchaseOrderStatusEnum.PENDING: return "/img/icons/icons8/ios/arrow_down_warning.png";
        case this.PurchaseOrderStatusEnum.OPEN: return "/img/icons/icons8/ios/arrow_down_success.png";
        case this.PurchaseOrderStatusEnum.DENIED: return "/img/icons/icons8/ios/arrow_down_danger.png";
        case this.PurchaseOrderStatusEnum.COMPLETED: return "/img/icons/icons8/ios/arrow_down_primary.png";
        default: return "/img/icons/icons8/ios/arrow_down_danger.png";
      }
    },
    downloadOrderResumePdf(orderId) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("purchaseOrder/getReportPdf", orderId)
        .then((res) => window.open(res.data, '_blank'))
        .finally(() => loader.hide());
    },
    fetchEntityBudgets() {
      this.$emit('fetch');
    },
    progressPercentage(totalValue, balance) {
      return ((totalValue - balance) / totalValue) * 100;
    }
  }
};
</script>

<style scoped>
table th {
  font-size: 13px !important;
  padding-right: 2rem !important;
}

table td {
  font-size: 12px !important;
  padding-right: 2rem !important;
  padding-top: 0.3rem !important;
  font-weight: 600 !important;
}

table td:first-child {
  font-weight: 500 !important;
}

table td:last-child {
  min-width: 9rem;
  padding-right: 1rem !important;
}

.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.3rem 0.8rem;
  margin-top: 0.1rem;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.completed-status {
  background-color: #f2f4f9;
  color: #1A70B7;
}

.dropdown-status .dropdown-toggle::after {
  display: none;
}

.progress-bar-container {
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
}

.progress-bar {
  height: 100%;
  background-color: #a3b7d7;
  transition: width 0.3s ease;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

@media screen and (max-width: 600px) {
  table td:last-child {
    min-width: 2rem;
    padding-right: 1rem !important;
  }

  .truncate-text {
    max-width: 125px;
  }

  table th {
    padding-right: 1rem !important;
  }

  table td {
    padding-right: 1rem !important;
  }
}
</style>
